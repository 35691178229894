import "./styles/globals.scss";
import "react-phone-input-2/lib/style.css";
import GlobalContext from "./modules/GlobalContext";
import { useState, useEffect } from "react";
import { get } from "./utils/storage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import UserAuthWrapper from "./modules/authmodule/components/UserAuthWrapper";
import HomePage from "./modules/index";
import AuthComponent from "./modules/authmodule/pages/action";
import { Box } from "@chakra-ui/react";
import ImportantLinks from "./modules/commonmodule/pages/ImportantLinks";
import HighCourtsList from "./modules/commonmodule/pages/HighCourtsList";
import {
  GlobalSearch,
  GlobalSearchResults,
  BuySubscription,
  AllCourtsPage,
  SingleCourt,
  MembersOnlinePage,
  SingleMember,
  LegalSoftware,
  SingleBook,
  AllEBooks,
  SingleResource,
  Resources,
  Profile,
  SingleCaCPage,
  ForgotPassword,
  ResetPassword,
  SingleCasePage,
  SignOut,
  SingleLawandRegPage,
  Gallery,
  DraftingNotes,
  CheckList,
  AboutUs,
  MemberRegister,
  PrivacyPolicy,
  MemberPolicy,
  Help,
  TermsandConditions,
  Cookies,
  FAQ,
  ServicesPage,
  ContractHome,
  NewContract,
  LatestNotice,
  LatestJudgement,
  LatestUpdates,
  CourtsbyCountry,
  CourtsbyCountryInfoPage,
} from "./modules/pages";
import {
  ClauseTag,
  ContractTag,
  LawTag,
  RegulationTag,
  JudgementTag,
  Gazzets,
  CaseTag,
} from "./modules/homemodule/pages/extras";
import NotFound from "./modules/404";
import SubscriptionPlanForms from "./modules/commonmodule/pages/SubscriptionPlanForms";

function App({ Component, pageProps }) {
  const [loggedIn, setLoggedIn] = useState();
  const [user, setUser] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    setLoggedIn(get("loggedIn"));
    setUser(get("user"));
    setRole(get("role"));
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        role,
        setRole,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/auth/user/signin" element={<AuthComponent />} />
          <Route exact path="/auth/user/signup" element={<AuthComponent />} />
          <Route exact path="/auth/user/signout" element={<SignOut />} />
          <Route
            exact
            path="/search"
            element={<UserAuthWrapper Child={GlobalSearch} />}
          />
          <Route
            exact
            path="/subscriptions/buy"
            element={<UserAuthWrapper Child={BuySubscription} />}
          />
          <Route
            exact
            path="/subsPlan/:name"
            element={<UserAuthWrapper Child={SubscriptionPlanForms} />}
          />
          <Route exact path="/importantLinks" element={<ImportantLinks />} />
          <Route exact path="/highCourts" element={<HighCourtsList />} />
          <Route
            exact
            path={"/search/results/:id/:type/:word"}
            element={<UserAuthWrapper Child={GlobalSearchResults} />}
          />
          {/* Tags */}
          <Route
            exact
            path={"/tags/clause"}
            element={<UserAuthWrapper Child={ClauseTag} />}
          />

          <Route
            exact
            path={"/tags/contract"}
            element={<UserAuthWrapper Child={ContractTag} />}
          />

          <Route
            exact
            path={"/tags/laws"}
            element={<UserAuthWrapper Child={LawTag} />}
          />

          <Route
            exact
            path={"/tags/regulation"}
            element={<UserAuthWrapper Child={RegulationTag} />}
          />

          <Route
            exact
            path={"/tags/judgement"}
            element={<UserAuthWrapper Child={JudgementTag} />}
          />

          <Route
            exact
            path={"/tags/case"}
            element={<UserAuthWrapper Child={CaseTag} />}
          />

          <Route
            exact
            path={"/tags/gazzets"}
            element={<UserAuthWrapper Child={Gazzets} />}
          />

          <Route
            exact
            path={"/members/:member"}
            element={<MembersOnlinePage />}
          />

          <Route
            exact
            path={"/members/register/new"}
            element={<MemberRegister />}
          />

          <Route exact path={"/aboutus"} element={<AboutUs />} />

          <Route exact path={"/gallery"} element={<Gallery />} />

          <Route exact path={"/draftingnotes"} element={<DraftingNotes />} />

          <Route exact path={"/checklist"} element={<CheckList />} />

          {/* single route */}
          <Route exact path={"/cac/:type/:id"} element={<SingleCaCPage />} />

          <Route exact path={"/lawsandreg/:type/:id"} element={<SingleLawandRegPage />} />

          <Route exact path={"/case/:id"} element={<SingleCasePage />} />
          <Route exact path={"/member/:id"} element={<SingleMember />} />
          <Route
            exact
            path={"/tags/:type/:id"}
            element={<SingleLawandRegPage />}
          />

          <Route exact path={"/resource/:id"} element={<SingleResource />} />
          <Route exact path={"/court/:id"} element={<SingleCourt />} />
          <Route exact path={"/ebook/:id"} element={<SingleBook />} />

          <Route exact path={"/allcourts"} element={<AllCourtsPage />} />

          <Route exact path={"/legalsoftware"} element={<LegalSoftware />} />

          <Route exact path={"/ebooks"} element={<AllEBooks />} />

          <Route exact path={"/resources"} element={<Resources />} />

          <Route exact path={"/privacypolicy"} element={<PrivacyPolicy />} />

          <Route exact path={"/memberpolicy"} element={<MemberPolicy />} />

          <Route exact path={"/help"} element={<Help />} />

          <Route exact path={"/cookies"} element={<Cookies />} />

          <Route exact path={"/faq"} element={<FAQ />} />

          <Route exact path={"/forgotpassword"} element={<ForgotPassword />} />

          <Route
            exact
            path={"/resetpassword/:token"}
            element={<ResetPassword />}
          />

          <Route
            exact
            path={"/profile"}
            element={<UserAuthWrapper Child={Profile} />}
          />

          <Route exact path={"/services"} element={<ServicesPage />} />
          <Route
            exact
            path={"/contract-management"}
            element={<ContractHome />}
          />

          <Route exact path={"/newcontract"} element={<NewContract />} />

          <Route exact path={"/terms"} element={<TermsandConditions />} />

          <Route
            exact
            path={"/supreme-court/latest-notices"}
            element={<LatestNotice />}
          />
          <Route
            exact
            path={"/supreme-court/latest-judgements"}
            element={<LatestJudgement />}
          />
          <Route
            exact
            path={"/supreme-court/latest-updates"}
            element={<LatestUpdates />}
          />

          <Route
            exact
            path={"/courts-by-country/court/:country"}
            element={<CourtsbyCountry />}
          />

          <Route
            exact
            path={"/courts-by-country/info/:country/:court"}
            element={<CourtsbyCountryInfoPage />}
          />

          <Route
            exact
            path={"/highCourts/courts-by-country/info/:country/:court"}
            element={<CourtsbyCountryInfoPage />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <Box id="loading" className="loader hidden">
        <div className="progress-bar stripes animated reverse slower">
          <span className="progress-bar-inner"></span>
        </div>
      </Box>
    </GlobalContext.Provider>
  );
}

export default App;
