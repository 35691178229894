import React, { useState, useEffect } from "react";
import { axiosInstance as axios } from "../../../lib/axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { Paginate } from "react-paginate-chakra-ui";

function ImportantLinks() {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);

  const pageSize = 4;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchLinks = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/importantlinks/paginate?page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setLinks(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    setPage(page); // Update the page state directly

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    if (data.length === 0) return;
    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/importantlinks/search/paginated/${search}?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setLinks(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/importantlinks/paginate?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setLinks(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else if (total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    fetchLinks(1);
  }, []);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setLinks(data.data);
      setTotal(currentTotal);
      return;
    }

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const res = await axios.get(
      `/importantlinks/search/paginate/${e.target.value}?page=${page}&limit=${pageSize}`
    );

    setLinks(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  return (
    <div>
      <Heading as="h1" size="xl" mb={4} mt={6}>
        List of Links
      </Heading>
      <Box width={"80%"} margin={"auto"} padding={"10px"} gap={4}>
        <Box width={"27%"}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<FiSearch />} />
            <Input placeholder="Search" onChange={handleSearch} />
          </InputGroup>
        </Box>
      </Box>
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>SEO Description</Th>
            <Th>SEO Name</Th>
            <Th>Url</Th>
          </Tr>
        </Thead>
        <Tbody>
          {links.map((link, index) => (
            <Tr key={index}>
              <Td>{link.name}</Td>
              <Td>{link.seoDescription}</Td>
              <Td>{link.seoName}</Td>
              <Td>{link.url}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box
        width={"80%"}
        margin={"auto"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Paginate
          page={page - 1}
          count={total}
          pageSize={pageSize}
          colorScheme="blue"
          onPageChange={(_page) => {
            if (page === _page + 1) {
              return;
            }
            setPage(_page + 1);
            handlePageChange(pageSize, _page + 1);
          }}
          shadow="lg"
          fontWeight="blue"
          variant="outline"
          border="2px solid"
        />
      </Box>
    </div>
  );
}

export default ImportantLinks;
