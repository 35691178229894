import React from "react";
import { Box, Button, SimpleGrid, Text, Flex } from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import { GiPlainCircle } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { MdOutlineSafetyDivider } from "react-icons/md";

import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";

const ContractAndClauseComponent = () => {
  const QuickLinks = [
    {
      title: "Drafting Notes",
      link: "/draftingnotes",
    },
    {
      title: "Check List",
      link: "/checklist",
    },
    {
      title: "OpenSource Contracts",
    },
    {
      title: "Webinars and Videos",
    },
  ];

  const { data, loading } = useFetch("/CaC/get/getforhome");

  const navigate = useNavigate();
  const RightArrowIcon = () => <IoIosArrowForward size={12} />;

  const redirect = (link) => {
    navigate(link);
  };

  return (
    <Box width={"70%"} margin={"auto"}>
      {/* Section 1: Contracts and Clauses */}
      <Flex
        flexDir={"column"}
        alignItems={"center"}
        ml={{ base: "20px", lg: "80px" }}
      >
        <Text
          fontSize={{
            base: "3xl",
            md: "5xl",
          }}
          color={"mainBlue"}
          fontFamily={"heading"}
        >
          Contracts and Clauses
        </Text>
        <Box borderBottom="4px solid rgba(30, 58, 138)" width="80px" mb={1} />
        <Text
          as="p"
          fontSize={{
            base: "md",
            md: "md",
          }}
          m={6}
          color={"mainBlue"}
          textAlign="center"
        >
          Find essential legal agreements and clauses for your business needs.
          Our library includes a wide range of contracts and clauses, from
          employment agreements to non-disclosure agreements. Browse through our
          collection to find the right document for your needs. Need help
          customizing a document? Our team of experts is here to assist you.
        </Text>
      </Flex>

      {/* Section 2: Quick links*/}

      <Flex
        width="80%"
        mt={{ base: "20px", lg: "-80px" }}
        ml={{ base: "20px", lg: "80px" }}
        justifyContent="center"
        alignItems="center"
      >
        <SimpleGrid columns={[1, 4]} justifyContent="center">
          {QuickLinks.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
              borderRadius="md"
              borderWidth="1px"
              borderColor="mainBlue"
              color="mainBlue"
              cursor="pointer"
              height={"65px"}
              width="180px"
              m={{ base: "20px", lg: "75px" }}
            >
              <Text
                fontSize="lg"
                onClick={() => redirect(item.link ? item.link : "#")}
              >
                {item.title}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>

      {/* Section 3: Contracts and Clauses list */}
      <SimpleGrid
        columns={[1, 4]}
        spacing={4}
        ml={{ base: "0", lg: "20px" }}
        width={{ base: "100%", md: "100%" }}
        mt={{ base: "0px", lg: "-10px" }}
      >
        <Card>
          {/* Contract section */}
          <CardHeader>
            <FaHandshake size={32} color="mainBlue" />
            <Text fontSize="xl">Contracts</Text>
          </CardHeader>
          <Divider />
          <CardBody>
            {!loading && data && data.data ? (
              [...data?.data?.contract?.slice(0, 5)].map((item, index) => (
                <Box display="flex" alignItems="center" key={index}>
                  <GiPlainCircle size={12} />
                  <Text
                    fontSize="sm"
                    fontWeight="light"
                    ml={2}
                    _hover={{
                      color: "mainBlue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(`/cac/contract/${item._id}`)}
                  >
                    {item.name}
                  </Text>
                </Box>
              ))
            ) : (
              <></>
            )}
          </CardBody>
          <CardFooter>
            <Button
              color="mainBlue"
              fontSize="sm"
              variant="link"
              rightIcon={<RightArrowIcon />}
              onClick={() => redirect("/tags/contract")}
              ml={2.5}
            >
              View More
            </Button>
          </CardFooter>
        </Card>

        {/* Contract section2 */}
        <Card>
          <CardHeader>
            <FaHandshake size={32} color="mainBlue" />
            <Text fontSize="xl">Contracts</Text>
          </CardHeader>
          <Divider />
          <CardBody>
            {!loading && data && data.data ? (
              [...data?.data?.contract?.slice(0, 5)].map((item, index) => (
                <Box display="flex" alignItems="center" key={index}>
                  <GiPlainCircle size={12} />
                  <Text
                    fontSize="sm"
                    fontWeight="light"
                    ml={2}
                    _hover={{
                      color: "mainBlue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(`/cac/contract/${item._id}`)}
                  >
                    {item.name}
                  </Text>
                </Box>
              ))
            ) : (
              <></>
            )}
          </CardBody>
          <CardFooter>
            <Button
              color="mainBlue"
              fontSize="sm"
              variant="link"
              rightIcon={<RightArrowIcon />}
              onClick={() => redirect("/tags/contract")}
              ml={2.5}
            >
              View More
            </Button>
          </CardFooter>
        </Card>

        {/* Clause section */}
        <Card>
          <CardHeader>
            <MdOutlineSafetyDivider size={32} color="mainBlue" />
            <Text fontSize="xl">Clauses</Text>
          </CardHeader>
          <Divider />
          <CardBody>
            {!loading && data && data.data ? (
              [...data?.data?.clause?.slice(0, 5)].map((item, index) => (
                <Box display="flex" alignItems="center" key={index}>
                  <GiPlainCircle size={12} />
                  <Text
                    fontSize="sm"
                    fontWeight="light"
                    ml={2}
                    _hover={{
                      color: "mainBlue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(`/cac/clause/${item._id}`)}
                  >
                    {item.name}
                  </Text>
                </Box>
              ))
            ) : (
              <></>
            )}
          </CardBody>
          <CardFooter>
            <Button
              color="mainBlue"
              fontSize="sm"
              variant="link"
              rightIcon={<RightArrowIcon />}
              onClick={() => redirect("/tags/clause")}
              ml={2.5}
            >
              View More
            </Button>
          </CardFooter>
        </Card>

        {/* Clause section2 */}
        <Card>
          <CardHeader>
            <MdOutlineSafetyDivider size={32} color="mainBlue" />
            <Text fontSize="xl">Clauses</Text>
          </CardHeader>
          <Divider />
          <CardBody>
            {!loading && data && data.data ? (
              [...data?.data?.clause?.slice(0, 5)].map((item, index) => (
                <Box display="flex" alignItems="center" key={index}>
                  <GiPlainCircle size={12} />
                  <Text
                    fontSize="sm"
                    fontWeight="light"
                    ml={2}
                    _hover={{
                      color: "mainBlue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => redirect(`/cac/clause/${item._id}`)}
                  >
                    {item.name}
                  </Text>
                </Box>
              ))
            ) : (
              <></>
            )}
          </CardBody>
          <CardFooter>
            <Button
              color="mainBlue"
              fontSize="sm"
              variant="link"
              rightIcon={<RightArrowIcon />}
              onClick={() => redirect("/tags/clause")}
              ml={2.5}
            >
              View More
            </Button>
          </CardFooter>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default ContractAndClauseComponent;

const Card = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={2}
    boxShadow="lg"
    padding="2%"
    textAlign="justify"
    borderColor="mainBlue"
    height="auto"
    backgroundColor="lightIvory"
    mb={10}
  >
    {children}
  </Box>
);

const CardHeader = ({ children }) => (
  <Box
    display="flex"
    alignItems="center"
    gap={4}
    color="mainBlue 
    "
    padding="2%"
    rounded="lg"
  >
    {children}
  </Box>
);

const Divider = () => (
  <Box
    borderBottom="1px solid rgba(0, 113, 188, 0.5)"
    width="100%"
    my={2}
    borderBottomColor="mainBlue"
  />
);

const CardBody = ({ children }) => (
  <Box display="grid" gap={2} ml={2} color="mainBlue">
    {children}
  </Box>
);

const CardFooter = ({ children }) => <Box>{children}</Box>;
