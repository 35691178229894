import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Tags from "../../../commonmodule/components/Tags";
import Main from "../../../commonmodule/layouts/home";
import useFetch from "../../../../hooks/useFetch";

const pageSize = 10;

export default function RegulationsTag() {
  const { data, loading, error } = useFetch(
    "/lawsandreg/getbytype/Regulation",
    {}
  );

  const [search, setSearch] = useState("");
  const [regulation, setRegulation] = useState("all");
  const [total, setTotal] = useState(5);

  useEffect(() => {
    setRegulation(data);
    setTotal(data?.data?.length);
  }, [data]);

  const searchThrough = (keyword) => {
    if (keyword === "") {
      setRegulation({
        ...data,
        data: data.data,
      });
    } else {
      const filtered = data.data.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setRegulation({
        ...data,
        data: filtered,
      });
    }
  };

  const highlight = (text, highlight) => {
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <Box as={"span"}>
        {" "}
        {parts.map((part, i) => (
          <Box
            as={"span"}
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    color: "blue",
                    fontWeight: "bold",
                    backgroundColor: "yellow",
                  }
                : {}
            }
          >
            {part}
          </Box>
        ))}{" "}
      </Box>
    );
  };

  const handleFilter = (e) => {
    if (e === "" || e === "all") {
      setRegulation({
        ...data,
        data: data.data,
      });
    } else {
      const filtered = data.data.filter((item) => {
        return item.name.toLowerCase()[0] === e.toLowerCase();
      });
      setRegulation({
        ...data,
        data: filtered,
      });
    }
  };

  return (
    <Main>
      <Tags
        data={regulation}
        title={"Regulation"}
        search={search}
        setSearch={setSearch}
        searchThrough={searchThrough}
        highlight={highlight}
        handleFilter={handleFilter}
        pageSize={pageSize}
        total={total}
        loading={loading}
        error={error}
        url={"/tags/lawsandreg/"}
      />
    </Main>
  );
}
