import React, { useEffect, useState } from "react";
import Layout from "../../../../commonmodule/layouts/home";
import useFetch from "../../../../../hooks/useFetch";

export default function LatestNotices() {
  const url = "https://main.sci.gov.in/";

  const {
    data: { data: latestdata },
    loading: letestloading,
    error: latesterror,
  } = useFetch("/scrape/supreme-court");

  return (
    <Layout>
      <div className="flex flex-col justify-center w-[90%] m-auto gap-4 p-[2%] px-[2%] mb-6">
        <div>
          <h2 className="text-sm font-bold">Latest Notices</h2>
          <p className="text-xs text-gray-500">SOURCE : {`"Supreme Court"`}</p>
        </div>
        <div className="flex flex-col justify-center gap-2  w-[90%]">
          {latestdata &&
            latestdata.supremeCourtData &&
            latestdata.supremeCourtData.latestUpdats &&
            latestdata.supremeCourtData.latestUpdats.map((item, index) => {
              return (
                item &&
                item.title && (
                  <div
                    key={index}
                    className="p-2 rounded-md cursor-pointer border-2 hover:bg-gray-200 transition-colors"
                    onClick={() => window.open(url + item.link, "_blank")}
                  >
                    <div className="flex justify-between items-center">
                      <div className="w-1/5">
                        <p className="font-bold text-red-600 text-sm md:text-lg">
                          {item.title}
                        </p>
                      </div>

                      <div className="w-4/5 flex justify-between">
                        <div className="w-3/4 pr-4">
                          <p className="text-md">{item?.desc}</p>
                        </div>

                        <div className="w-1/4 text-xs text-gray-600 break-words">
                          <p>{item?.date}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </Layout>
  );
}
