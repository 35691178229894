import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { axiosInstance as axios } from '../../../lib/axios';

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password Required')
    .min(8, 'Password must be at least 8 characters'),
  email: Yup.string()
    .email('Invalid email! Please Check Your Email Id')
    .required('Email Required'),
});

export default function Signup({ role }) {
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <>
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              cursor={'pointer'}
              onClick={() => {
                window.location.href = '/';
              }}
            >
              <Image src="/assets/homepage/logo.png" alt={'logo'} />
            </Box>
            <Heading fontSize={'4xl'} textAlign={'center'}>
              Sign up
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              to enjoy all of our cool features ✌️
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Formik
              initialValues={{
                email: '',
                password: '',
                rememberme: false,
              }}
              onSubmit={async (values) => {
                const url = '/admin/create';
                const res = await axios.post(url, {
                  ...values,
                  role: role,
                });

                try {
                  if (res.data.ok) {
                    navigate('/auth/user/signin');
                    toast({
                      description: 'Account Created Successfully',
                      status: 'success',
                      position: 'bottom-right',
                    });

                    toast({
                      description: 'Login to Continue',
                      status: 'success',
                      position: 'bottom-right',
                    });
                  } else {
                    toast({
                      description: res.data.message,
                      status: 'error',
                      position: 'bottom-right',
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
              validationSchema={SignupSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="email"
                      isInvalid={errors.email && touched.email}
                    >
                      <FormLabel>Email address</FormLabel>
                      <Field as={Input} type="email" name="email" />
                      {errors.email && touched.email && (
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="password"
                      isInvalid={errors.password && touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <Field as={Input} type="password" name="password" />
                      {errors.password && touched.password && (
                        <FormErrorMessage>
                          Password is required.
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <Stack spacing={10}>
                      <Button
                        bg={'blue.400'}
                        type={'submit'}
                        color={'white'}
                        _hover={{
                          bg: 'blue.500',
                        }}
                      >
                        Create Account
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>

            <Stack pt={2}>
              <Text align={'center'}>
                Already a Member ?{' '}
                <Link color={'blue.400'} href={'/auth/user/signin'}>
                  Sign In
                </Link>
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
