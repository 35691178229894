import Layout from "../../commonmodule/layouts/home";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import useFetch from "../../../hooks/useFetch";
import { config } from "../../../utils/config";
import { useParams } from "react-router-dom";

const SingleLawyer = () => {
  const { id } = useParams();
  let { data, loading } = useFetch("/memberlisting/single/" + id);

  const whatsapp = (phone) => {
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&amptext=Hi`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  function regex(phone) {
    if (phone) {
      return "XXXXXXX" + phone.substring(phone.length - 3);
    }

    return "No Phone Number";
  }

  return (
    <Layout>
      {!loading && data.data ? (
        <Box
          display={"flex"}
          flexDir={"column"}
          mt={8}
          gap={4}
          borderRadius={"lg"}
          padding={"4%"}
          textAlign={"justify"}
        >
          <Box as={"span"} display={"flex"} alignItems={"center"} gap={4}>
            <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
              {data.data[0].professionalname}
            </Text>
            <Text color={"gray.500"} fontSize={"md"}>
              (
              {data.data[0].type.charAt(0).toUpperCase() +
                data.data[0].type.slice(1)}
              )
            </Text>
          </Box>
          <Box>
            <Image
              src={config.baseURL + `/CaC/download/${data.data[0].image}`}
              alt="court"
              width={{
                base: "300px",
                md: "200px",
              }}
              rounded={"lg"}
              boxShadow={"lg"}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            {data.data[0].country && (
              <Text>
                <b>County:</b> {data.data[0].country.name}
              </Text>
            )}
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={4}>
            {data.data[0].state && (
              <Text>
                <b>State:</b> {data.data[0].state.name}
              </Text>
            )}
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={4}>
            <Text>
              <b>Email:</b>
              {data.data[0].email}
            </Text>
            <Text>
              <b>Phone:</b> {regex(data.data[0].mobile)}
            </Text>
            <Text>
              <b>Address:</b>
              <a
                href={`${data.data[0].address}`}
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "5px" }}
              >
                {data.data[0].address}
              </a>
            </Text>
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={4} width={"70%"}>
            <Text>{data.data[0].description}</Text>
          </Box>
          <Box display={"flex"} gap={4}>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Image
                src={"/assets/icons/mail.svg"}
                width={"2rem"}
                cursor={"pointer"}
                onClick={() => {
                  window.open(`mailto:${data.data[0].email}`);
                }}
              />
              {data.data[0].mobile && (
                <Image
                  src={"/assets/icons/whatsapp.svg"}
                  width={"2rem"}
                  cursor={"pointer"}
                  onClick={() => whatsapp(data.data[0].mobile)}
                />
              )}
            </div>

            <Button
              onClick={() => window.history.back()}
              colorScheme={"red"}
              color={"white"}
              boxShadow={"dark-sm"}
              rounded={"lg"}
              _hover={{
                bg: "lightBlue",
                color: "mainBlue",
                boxShadow: "dark-md",
                borderColor: "mainBlue",
                borderWidth: "2px",
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      ) : (
        <Text>No data</Text>
      )}
    </Layout>
  );
};

export default SingleLawyer;
