import {
  Text,
  Box,
  SimpleGrid,
  Flex,
  InputGroup,
  InputLeftAddon,
  Input,
  Button,
  Tag,
  Divider,
} from "@chakra-ui/react";
import Main from "../layouts/base";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import { Paginate } from "react-paginate-chakra-ui";
import { MultiSelect } from "primereact/multiselect";

export default function Tags({
  data,
  loading,
  error,
  search,
  setSearch,
  highlight,
  searchThrough,
  title,
  url,
  agreementTypes,
  industrytypes,
  handleAgreementTypeChange,
  handleindustrytypeChange,
  setFilters,
  filters,
  pageSize,
  handlePageChange,
  total,
}) {
  const [page, setPage] = useState(1);
  const handleClick = (id) => {
    window.location.href = url + id;
  };
  const id = window.location.pathname.split("/")[3];

  return (
    <Main>
      <Box className="tag" width="100%" margin="auto">
        <Box
          bg={"blue.100"}
          p="3"
          width="100%"
          margin="auto"
          display="flex"
          justifyContent="space-between"
          mb="7"
        >
          <Box
            display="flex"
            width="80%"
            margin="auto"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                handleClick(url + "/" + id);
              }}
            >
              <Text as="h1" fontFamily="heading" fontSize="xl" color="mainBlue">
                {title}{" "}
                <Text as="span" color="gray.500" fontSize="sm">
                  ({data?.data?.length}{" "}
                  {data?.data?.length > 1 ? title + "s" : title})
                </Text>
              </Text>
            </Flex>
          </Box>
        </Box>

        <Flex
          margin={4}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-start" }}
          gap={{ base: 4 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={10}
            alignItems="center"
            bg={"blue.100"}
            p={4}
            rounded="md"
            boxShadow="sm"
            w={{ base: "100%", md: "30%" }}
          >
            <Box alignSelf={"flex-start"}>
              <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
                Filters
              </Text>
            </Box>
            <InputGroup>
              <InputLeftAddon
                bg={"gray.200"}
                borderColor={"gray.500"}
                children={<FaSearch />}
                borderRight={"none"}
              />
              <Input
                type="text"
                placeholder="Search..."
                borderColor={"gray.500"}
                borderLeft={"none"}
                rounded={"md"}
                _focusVisible={{
                  outline: "none",
                }}
                _hover={{
                  borderColor: "gray.500",
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  searchThrough(e.target.value);
                }}
              />
            </InputGroup>
            {agreementTypes && agreementTypes.length > 0 && (
              <MultiSelect
                style={{
                  fontSize: "14px",
                  fontFamily: "PT Sans",
                  color: "#2D3748",
                  borderColor: "##2D3748",
                }}
                showSelectAll={false}
                value={
                  filters.agreementType.length > 0 ? filters.agreementType : []
                }
                onChange={(e) => {
                  if (e.value.length === 0) {
                    setFilters({
                      ...filters,
                      agreementType: [],
                    });
                    handleAgreementTypeChange();
                    return;
                  }

                  setFilters({
                    ...filters,
                    agreementType: e.value,
                  });

                  handleAgreementTypeChange(e.value.map((item) => item._id));
                }}
                virtualScrollerOptions={{ itemSize: 43 }}
                options={agreementTypes ? agreementTypes : []}
                optionLabel="name"
                filter
                placeholder="Select Agreement Type"
                maxSelectedLabels={3}
              />
            )}

            {industrytypes && industrytypes.length > 0 && (
              <MultiSelect
                style={{
                  fontSize: "14px",
                  fontFamily: "PT Sans",
                  color: "#2D3748",
                  borderColor: "##2D3748",
                }}
                showSelectAll={false}
                onChange={(e) => {
                  if (filters.industrytype === e.value) return;

                  setFilters({
                    ...filters,
                    industrytype: e.target.value,
                  });
                  handleindustrytypeChange(e.target.value);
                }}
                virtualScrollerOptions={{ itemSize: 43 }}
                options={industrytypes ? industrytypes : []}
                optionLabel="name"
                filter
                placeholder="Select Industry Type"
                maxSelectedLabels={3}
              />
            )}
          </Box>
          {!loading && !error && data && data.data && data.data.length > 0 ? (
            <SimpleGrid
              columns="1"
              gap={4}
              width={{ base: "100%", md: "80%" }}
              margin="auto"
              mt={4}
            >
              {data.data.map((item, i) => (
                <Box
                  key={i}
                  bg="white"
                  rounded="md"
                  p={4}
                  shadow="sm"
                  transition="transform 0.2s"
                  _hover={{ transform: "scale(1.02)" }}
                  maxH="150px" // Adjust the maximum height as needed
                >
                  <Text
                    as="h2"
                    fontSize="xl"
                    fontWeight="semibold"
                    color="mainBlue"
                  >
                    {highlight(item.name, search)}
                  </Text>
                  <Text color="gray.500" mt={2}>
                    {item.description.substring(0, 100)}...
                  </Text>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                  >
                    <Tag size="sm" colorScheme="linkedin">
                      {item.searchCount}
                    </Tag>
                    <Button
                      size="sm"
                      color="white"
                      bg="mainBlue"
                      onClick={() => {
                        handleClick(item._id);
                      }}
                    >
                      View Details
                    </Button>
                  </Flex>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <Box display="flex" justify="center" items="center" ml="25%">
              <h2 m={10}>No Data to Show</h2>
            </Box>
          )}
        </Flex>
        {pageSize && (
          <Box
            width="80%"
            margin="auto"
            display="flex"
            justify="center"
            items="center"
          >
            <Paginate
              page={page - 1}
              count={total ? total : 0}
              pageSize={pageSize}
              colorScheme="blue"
              onPageChange={(_page) => {
                if (page === _page + 1) {
                  return;
                }
                setPage(_page + 1);
                handlePageChange(pageSize, _page + 1);
              }}
              shadow="lg"
              fontWeight="blue"
              variant="outline"
              border="2px solid"
            />
          </Box>
        )}
      </Box>
    </Main>
  );
}
