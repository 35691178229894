import {
  Box,
  Flex,
  Text,
  Stack,
  Icon,
  Link,
  Popover,
  Button,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  Wrap,
  WrapItem,
  Avatar,
  PopoverContent,
  useColorModeValue,
  Image,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  IconButton,
} from "@chakra-ui/react";
import { ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { get } from "../../../utils/storage";
import { config } from "../../../utils/config";
import { FiArrowRight } from "react-icons/fi";

import { BsFillAwardFill } from "react-icons/bs";
import { GoLaw } from "react-icons/go";
import { TiNews } from "react-icons/ti";
import { FaHandshake } from "react-icons/fa";
import { MdOutlineSafetyDivider } from "react-icons/md";
import { BiUserVoice } from "react-icons/bi";
import useFetch from "../../../hooks/useFetch";
import { HamburgerIcon } from "@chakra-ui/icons";

const NAV_ITEMS = [
  {
    label: "Laws",
    href: "/tags/laws",
    icon: <GoLaw />,
  },

  {
    label: "Regulatory",
    href: "/tags/regulation",
    icon: <BsFillAwardFill />,
  },

  {
    label: "Gazettes",
    href: "/tags/gazzets",
    icon: <TiNews />,
  },
  {
    label: "Case Laws",
    href: "/tags/case",
    icon: <BiUserVoice />,
  },
  {
    label: "Contracts",
    href: "/tags/contract",
    icon: <FaHandshake />,
  },
  {
    label: "Clauses",
    href: "/tags/clause",
    icon: <MdOutlineSafetyDivider />,
  },
  {
    label: "Services",
    href: "/services",
    icon: <BiUserVoice />,
  },
];

export default function Navbar({ scroll }) {
  const navRef = useRef();
  const [user, setUser] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const {
      data: { firstName, lastName, profileImage },
    } = get("user") ? get("user") : { data: {} };
    setUser({ firstName, lastName, profileImage });
  }, []);

  const profilePage = window.location.pathname === "/profile";
  const loggedin = get("loggedIn");

  const {
    data: { data: footerData },
  } = useFetch("/adminoptions/getcontactsection");

  const ifLoggedin = [
    {
      label: "Profile",
      href: "/profile",
    },
    {
      label: "Dashboard",
      href: "/dashboard",
    },
    {
      label: "Sign Out",
      href: "/auth/user/signout",
    },
  ];

  let quicklinks = [
    {
      label: "Sign In",
      href: "/auth/user/signin",
    },
  ];

  if (loggedin) {
    quicklinks = ifLoggedin;
  }

  const handleClicked = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box
      id="navbar"
      width={"100%"}
      zIndex={"10"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      backdropBlur={"lg"}
      backgroundColor={"white"}
      borderColor={"gray.200"}
      gap={2}
      pt={2}
      ref={navRef}
      position={"sticky"}
      top={"0"}
      as={motion.div}
      initial={{ y: "-100%" }}
      dropShadow={"lg"}
      animate={{ y: scroll ? "-100%" : "0%" }}
      transition={{
        duration: 0.5,
        repeat: 1,
        repeatType: "reverse",
      }}
    >
      <Flex
        width={"95%"}
        height={{
          base: "auto",
          md: 12,
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={{ base: "column", md: "row" }}
        gap={{
          base: "4",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          cursor={"pointer"}
          width={{
            base: "90%",
            md: "100%",
            lg: "20%",
          }}
          gap={8}
        >
          <Image
            src="/assets/homepage/logo.png"
            alt={"logo"}
            maxWidth={150}
            onClick={() => {
              window.location.href = "/";
            }}
          />
          <HamburgerIcon
            w={8}
            h={8}
            color="midBlue"
            onClick={onOpen}
            display={{ base: "block", lg: "none" }}
          />
          <DesktopNav scroll={scroll} />
        </Box>

        <Flex>
          <Flex gap={4} ml={12}>
            {/* <Image
              src={"/assets/icons/mail.svg"}
              width={"2rem"}
              cursor={"pointer"}
              onClick={() => handleClicked(`mailto:${footerData?.email}`)}
              display={{ base: "none", md: "block" }}
            />

            <Image
              src={"/assets/icons/whatsapp.svg"}
              width={"2rem"}
              cursor={"pointer"}
              onClick={() =>
                handleClicked(
                  `https://api.whatsapp.com/send?phone=${footerData?.phone}`
                )
              }
              display={{ base: "none", md: "block" }}
            /> */}

            {!loggedin ? (
              <Button
                colorScheme={"white"}
                mr={"10%"}
                color={"white"}
                backgroundColor={"mainBlue"}
                // variant={"outline"}
                width={"7rem"}
                position={"relative"}
                display={{ base: "none", md: "flex" }}
                onClick={() => {
                  window.location.href = "/auth/user/signin";
                }}
                _hover={{
                  shadow: "sm",
                  transform: "scale(1.02)",
                }}
              >
                Sign In
                <FiArrowRight />
              </Button>
            ) : (
              <Popover trigger={"click"} placement={"right"}>
                <Box
                  width={"50%"}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <PopoverTrigger>
                    <Wrap display={{ base: "none", lg: "block" }}>
                      <WrapItem>
                        <Avatar
                          id="profileImage"
                          size={"sm"}
                          cursor={"pointer"}
                          name={user.firstName ? `${user.firstName}` : ""}
                          src={
                            user.profileImage
                              ? config.baseURL +
                                `/CaC/download/${user.profileImage}`
                              : ""
                          }
                        />
                      </WrapItem>
                    </Wrap>
                  </PopoverTrigger>
                </Box>
                <PopoverContent
                  width={"150px"}
                  border={"2px solid black"}
                  boxShadow={"none"}
                  backgroundColor={"white"}
                  color={"black"}
                >
                  <PopoverArrow />
                  <PopoverBody>
                    {quicklinks.map((link, i) => (
                      <Box
                        key={i}
                        rounded={"md"}
                        padding={1}
                        _hover={{
                          backgroundColor: "gray.300",
                        }}
                        role={"group"}
                      >
                        <Link
                          href={link.href}
                          color={"mainBlue"}
                          _groupHover={{ textDecoration: "none" }}
                        >
                          <Text fontSize={"sm"} fontWeight={"bold"}>
                            {link.label}
                          </Text>
                        </Link>
                      </Box>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Flex>

          <Drawer
            placement={"right"}
            onClose={onClose}
            isOpen={isOpen}
            size={"full"}
          >
            <DrawerOverlay />
            <DrawerContent backgroundColor={"mainBlue"}>
              <DrawerHeader>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  {!loggedin ? (
                    <>
                      <Button
                        colorScheme={"white"}
                        mr={"10%"}
                        color={"white"}
                        variant={"outline"}
                        width={"40"}
                        position={"relative"}
                        onClick={() => {
                          window.location.href = "/auth/user/signin";
                        }}
                        _hover={{
                          shadow: "sm",
                          transform: "scale(1.02)",
                        }}
                      >
                        Sign In
                        <FiArrowRight />
                      </Button>
                    </>
                  ) : (
                    <Popover trigger={"click"} placement={"right"}>
                      <Box
                        width={"50%"}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <PopoverTrigger>
                          <Wrap>
                            <WrapItem>
                              <Avatar
                                id="profileImage"
                                size={"sm"}
                                cursor={"pointer"}
                                name={user.firstName ? `${user.firstName}` : ""}
                                src={
                                  user.profileImage
                                    ? config.baseURL +
                                      `/CaC/download/${user.profileImage}`
                                    : ""
                                }
                              />
                            </WrapItem>
                          </Wrap>
                        </PopoverTrigger>
                      </Box>
                      <PopoverContent
                        width={"150px"}
                        border={"2px solid black"}
                        boxShadow={"none"}
                        color={"black"}
                      >
                        <PopoverArrow />
                        <PopoverBody>
                          {quicklinks.map((link, i) => (
                            <Box
                              key={i}
                              rounded={"md"}
                              padding={1}
                              _hover={{
                                backgroundColor: "gray.800",
                              }}
                              role={"group"}
                            >
                              <Link
                                href={link.href}
                                color={"mainBlue"}
                                _groupHover={{ textDecoration: "none" }}
                              >
                                <Text fontSize={"sm"} fontWeight={"bold"}>
                                  {link.label}
                                </Text>
                              </Link>
                            </Box>
                          ))}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}

                  <Image
                    src={"/assets/icons/mail.svg"}
                    width={"2rem"}
                    cursor={"pointer"}
                    onClick={() => handleClicked(`mailto:${footerData?.email}`)}
                  />
                  <Image
                    src={"/assets/icons/whatsapp.svg"}
                    width={"2rem"}
                    cursor={"pointer"}
                    onClick={() =>
                      handleClicked(
                        `https://api.whatsapp.com/send?phone=${footerData?.phone}`
                      )
                    }
                  />

                  <IconButton
                    aria-label="Close menu"
                    icon={<CloseIcon />}
                    onClick={onClose}
                    backgroundColor={"mainBlue"}
                    color={"white"}
                  />
                </Flex>

                <Flex
                  justifyContent={"flex-end"}
                  gap={"24px"}
                  mt={"10px"}
                ></Flex>
              </DrawerHeader>
              <DrawerBody>
                {!profilePage && (
                  <Box
                    width={"100%"}
                    margin={"auto"}
                    as={motion.div}
                    fontWeight={"bold"}
                    transition={"display .3s ease"}
                  >
                    <MobileNav scroll={scroll} isShow={true} />
                  </Box>
                )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Flex>
    </Box>
  );
}

const DesktopNav = ({ scroll, show }) => {
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Flex
      display={{ base: "none", lg: "flex" }}
      direction={"row"}
      transition={"all .3s ease"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={12}
    >
      {NAV_ITEMS.map((navItem) => (
        <Box
          key={navItem.label}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          color={"mainBlue"}
          role={"group"}
          rounded={"md"}
          whiteSpace={"nowrap"}
        >
          <Popover trigger={"hover"} placement={"bottom"}>
            <PopoverTrigger>
              <Link
                href={navItem.href ?? "#"}
                display={"flex"}
                gap={"10px"}
                alignItems={"center"}
                fontFamily={"base"}
                color={"mainBlue"}
                opacity={0.9}
                _hover={{
                  textDecoration: "none",
                  color: "orange",
                }}
                _groupHover={{
                  color: "orange",
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                marginTop={2}
                boxShadow={"sm"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"xs"}
                display={"flex"}
                justifyContent={"center"}
                borderRadius={"xl"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Flex>
  );
};

const MobileNav = ({ scroll, show }) => {
  return (
    <Flex
      display={"flex"}
      flexDirection={"column"}
      transition={"all .3s ease"}
      gap={"3rem"}
      justifyContent={"center"}
      alignItems={"center"}
      mt={"4rem"}
      mb={"4rem"}
    >
      {NAV_ITEMS.map((navItem) => (
        <>
          <Box
            key={navItem.label}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            role={"group"}
            width={"100%"}
            rounded={"md"}
            whiteSpace={"nowrap"}
            borderBottom={"2px solid"}
            borderColor={"white"}
            p={2}
          >
            <Popover trigger={"hover"} placement={"bottom"}>
              <PopoverTrigger>
                <Link
                  href={navItem.href ?? "#"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  fontSize={"2xl"}
                  fontFamily={"base"}
                  color={"white"}
                  _hover={{
                    textDecoration: "none",
                    color: "orange",
                  }}
                  _groupHover={{
                    color: "orange",
                  }}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  marginTop={2}
                  boxShadow={"sm"}
                  bg={"lightBlue"}
                  p={4}
                  rounded={"xl"}
                  minW={"xs"}
                  display={"flex"}
                  justifyContent={"center"}
                  borderRadius={"xl"}
                >
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        </>
      ))}

      {/* <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        role={"group"}
        width={"100%"}
        rounded={"md"}
        whiteSpace={"nowrap"}
      >
        <Link
          href={"#"}
          display={"flex"}
          alignItems={"flex-start"}
          fontSize={"xl"}
          fontFamily={"base"}
          color={"red"}
          borderBottom={"2px solid"}
          borderColor={"red"}
          padding={"10px"}
          borderRadius={"md"}
          _hover={{
            textDecoration: "none",
            color: "orange",
          }}
          _groupHover={{
            color: "orange",
          }}
        >
          Logout
        </Link>
      </Box> */}
    </Flex>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={"group"}
      key={label}
      display={"block"}
      color={"gray.600"}
      _hover={{ bg: "gray.200", color: "gray.800", textDecoration: "none" }}
      p={2}
      rounded={"md"}
    >
      <Stack direction={"row"} align={"center"} textAlign={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            fontWeight={500}
            fontFamily={"heading"}
            color={"mainBlue"}
          >
            {label}
          </Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"mainBlue"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};
