import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Tags from "../../../commonmodule/components/Tags";
import Main from "../../../commonmodule/layouts/home";
import useFetch from "../../../../hooks/useFetch";

const pageSize = 10;

export default function Gazzets() {
  const { data, loading, error } = useFetch("/lawsandreg/getbytype/Law", {});

  const [search, setSearch] = useState("");
  const [gazette, setGazette] = useState("all");
  const [total, setTotal] = useState(5);

  useEffect(() => {
    setGazette(data);
    setTotal(data?.data?.length);
  }, [data]);

  const searchThrough = (keyword) => {
    if (keyword === "") {
      setGazette({
        ...data,
        data: data.data,
      });
    } else {
      const filtered = data.data.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setGazette({
        ...data,
        data: filtered,
      });
    }
  };

  const highlight = (text, highlight) => {
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <Box as={"span"}>
        {" "}
        {parts.map((part, i) => (
          <Box
            as={"span"}
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    color: "blue",
                    fontWeight: "bold",
                    backgroundColor: "yellow",
                  }
                : {}
            }
          >
            {part}
          </Box>
        ))}{" "}
      </Box>
    );
  };

  const handleFilter = (e) => {
    if (e === "" || e === "all") {
      setGazette({
        ...data,
        data: data.data,
      });
    } else {
      const filtered = data.data.filter((item) => {
        return item.name.toLowerCase()[0] === e.toLowerCase();
      });
      setGazette({
        ...data,
        data: filtered,
      });
    }
  };

  return (
    <Main>
      {!loading && data && data.data && data.data.length > 0 && (
        <Tags
          data={gazette}
          title="Gazette"
          search={search}
          setSearch={setSearch}
          searchThrough={searchThrough}
          highlight={highlight}
          handleFilter={handleFilter}
          pageSize={pageSize}
          total={total}
          loading={loading}
          error={error}
          url={"/tags/gazzets/"}
        />
      )}
    </Main>
  );
}
