import { Box } from "@chakra-ui/layout";

const Main = ({ children }) => {
  return (
    <Box as={"main"} fontFamily={"base"} position={"relative"}>
      <Box display={"flex"} backgroundColor={"whiteAlpha.200"}>
        {children}
      </Box>
    </Box>
  );
};

export default Main;
