import {
  Box,
  Text,
  Image,
  Flex,
  Link,
  Stack,
  Center,
  SimpleGrid,
  Divider,
  Button,
} from "@chakra-ui/react";

import useFetch from "../../../hooks/useFetch";
import React, { useState, useEffect } from "react";
import { axiosInstance as axios } from "../../../lib/axios";

export default function FooterComponent() {
  const {
    data: { data: footerData },
  } = useFetch("/adminoptions/getcontactsection");

  const { data, loading } = useFetch("/adminoptions/getabout");

  const splitParagraph = (text) => {
    if (!text) return text;

    let html = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    return html;
  };

  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get("/importantlinks");
        // console.log("API's response:", response.data);

        if (response.data.data) {
          setLinks(response.data.data);
        }
      } catch (error) {
        console.log("Error fetching links:", error);
      }
    };

    fetchLinks();
  }, []);

  const handleClicked = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <Box bg="mainBlue" color={"white"}>
        <Text textAlign={"center"} pt={8} fontSize={"xl"}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
          harum.
        </Text>
        {/* Start Trial Button */}
        <Center mt={4}>
          <Button>Start Trial</Button>
        </Center>

        {/* Divider */}
        <Center mt={6}>
          <Divider
            borderColor="hsl(207, 100%, 25%)"
            borderWidth="1px"
            w="70%"
          />
        </Center>
      </Box>
      <Box>
        <SimpleGrid
          columns={[1, 2, 5]}
          spacing={4}
          width="100%"
          bg="mainBlue"
          px={{ base: "16px", lg: "260px" }}
          pt={5}
        >
          {/* First Section */}
          <Box>
            <Flex
              flexDirection="column"
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              <Image src="/assets/homepage/logo-w.png" width="200px" alt="d" />
            </Flex>
          </Box>

          {/* Second Section */}
          <Box>
            <Flex
              justifyContent="center"
              textAlign={{ base: "center", lg: "left" }}
              flexDirection="column"
            >
              <Text
                fontSize="xl"
                fontWeight="bold"
                mb={2}
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/aboutus")}
              >
                About Us
              </Text>
            </Flex>

            <Flex justifyContent={{ base: "center", lg: "left" }}>
              <Text fontSize="sm">
                {/* inject html */}
                <Text
                  maxW="300"
                  color={"white"}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  dangerouslySetInnerHTML={{
                    __html: splitParagraph(data?.data?.description),
                  }}
                />
                <Text as="span">
                  <Link
                    textDecoration="none"
                    _hover={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "/aboutus")}
                  >
                    Read More....
                  </Link>
                </Text>
              </Text>
            </Flex>
          </Box>

          {/* Third Section */}
          <Box>
            <Flex
              justifyContent="center"
              alignItems={{ base: "center", lg: "flex-start" }}
              flexDirection="column"
            >
              <Text
                fontSize="xl"
                fontWeight="bold"
                mb={2}
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/importantLinks")}
              >
                Important Links
              </Text>

              <Box styleType="none" color="white">
                {links.map((link) => (
                  // <div key={link.id} mb={1}>
                  <Flex>
                    <Link
                      href={link.url}
                      color={"white"}
                      fontSize="md"
                      fontWeight="bold"
                    >
                      {link.name}
                    </Link>
                  </Flex>
                  // </div>
                ))}
              </Box>
            </Flex>
          </Box>

          <Box
            justifyContent="center"
            textAlign={{ base: "center", lg: "left" }}
          >
            <Text
              fontSize="xl"
              fontWeight="bold"
              textUnderlineOffset={6}
              mb={2}
              color="white"
              _hover={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "/importantLinks")}
            >
              Helpful Links
            </Text>

            <Box
              py={4}
              color={"white"}
              mt={-4}
              styleType="none"
              display="flex"
              fontSize="md"
              fontWeight="bold"
              flexDirection="column"
            >
              <Link href="/privacypolicy" color={"white"}>
                Privacy Policy
              </Link>
              <Link href="/terms" color={"white"}>
                Terms and Conditions
              </Link>
              <Link href="/faq" color={"white"}>
                FAQ
              </Link>
              <Link href="/help" color={"white"}>
                Help
              </Link>
              <Link href="/cookies" color={"white"}>
                Cookies
              </Link>
              <Link href="/careers" color={"white"}>
                Careers
              </Link>
            </Box>
          </Box>

          {/* Gallery section */}
          <Box>
            {/* <Flex justifyContent="flex-start" alignItems="center">
              <Text
                fontSize="xl"
                fontWeight="bold"
                textUnderlineOffset={6}
                mb={2}
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/gallery")}
              >
                Gallery
              </Text>
            </Flex>
            <Flex justifyContent="center" mt={4} flexWrap="wrap">
              {Array.from({ length: 4 }).map((_, i) => (
                <Box key={i} width="50%" mb={4}>
                  <Image
                    src={`/assets/homepage/footer${i + 1}.jpg`}
                    width="70%"
                    height="70px"
                    alt="footer"
                    cursor="pointer"
                  />
                </Box>
              ))}
            </Flex> */}

            <Flex
              justifyContent="center"
              alignItems={{ base: "center", lg: "flex-start" }}
              flexDirection="column"
            >
              <Text
                fontSize="xl"
                fontWeight="bold"
                textUnderlineOffset={6}
                mt={2}
                mb={2}
                color="white"
                _hover={{ cursor: "pointer" }}
                // onClick={() => (window.location.href = "/aboutus")}
              >
                Socials
              </Text>
            </Flex>
            <Flex
              gap={4}
              justifyContent="center"
              alignItems={{ base: "center", lg: "flex-start" }}
              flexDirection="row"
            >
              <Image
                src="/assets/icons/facebook.svg"
                width="2.5rem"
                cursor="pointer"
                onClick={() => handleClicked(footerData?.facebooklink)}
              />
              <Image
                src="/assets/icons/linkedin.svg"
                width="2.5rem"
                cursor="pointer"
                onClick={() => handleClicked(footerData?.linkedinlink)}
              />
              <Image
                src="/assets/icons/instagram.svg"
                width="2.5rem"
                cursor="pointer"
                onClick={() => handleClicked(footerData?.instagramlink)}
              />
              <Image
                src="/assets/icons/twitter.svg"
                width="2.5rem"
                cursor="pointer"
                onClick={() => handleClicked(footerData?.twitterlink)}
              />
            </Flex>
          </Box>
        </SimpleGrid>
      </Box>

      {/* Last Section */}
      <Box bg="#001d47" mt={0}>
        <Center color="white" h={"60px"}>
          <Stack direction="row">
            <Text fontWeight={"bold"} fontSize={"sm"}>
              All content of the linkinlegal excluding publicly sourced
              documents are copyright &copy; Linkinlegal 2023. &nbsp;All rights
              reserved.
            </Text>
          </Stack>
        </Center>
      </Box>
    </Box>
  );
}
