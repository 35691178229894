import Layout from "../../commonmodule/layouts/home";
import {
  Button,
  SimpleGrid,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Card, CardBody } from "@chakra-ui/card";
import { axiosInstance as axios } from "../../../lib/axios";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Paginate } from "react-paginate-chakra-ui";
import { useGlobalContext } from "../../../modules/GlobalContext";

const LegalSoftwareList = () => {
  const [softwares, setSoftwares] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);
  const value = useGlobalContext();
  const navigate = useNavigate();
  const toast = useToast();

  const pageSize = 4;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const downloadSoftware = async (id, url) => {
    if (!value.user) {
      toast({
        title: "Please Sign In",
        description: "You need to sign in to download the software",
        status: "error",
        duration: 1500,
        isClosable: true,
      });

      setTimeout(() => {
        navigate("/auth/user/signin", {
          state: { from: "/legalsoftware" },
        });
      }, 500);
      return;
    }
    let res = await axios.put(`/legalsoftware/updateSearchCount/${id}`, {
      userId: value.user.data._id,
    });
    if (res.data.ok) {
      window.open(url, "_blank");
    }
  };

  const fetchSoftwares = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/legalsoftware/paginate?page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setSoftwares(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/legalsoftware/search/paginated/${search}?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setSoftwares(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/legalsoftware/paginate?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setSoftwares(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
    if (total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    }

    fetchSoftwares(1);
  }, []);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setSoftwares(data.data);
      setTotal(currentTotal);
      return;
    }

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const res = await axios.get(
      `/legalsoftware/search/paginated/${e.target.value}?page=${page}&limit=${pageSize}`
    );

    setSoftwares(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  return (
    <Layout>
      <Box width={"100%"} mt={10}>
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"80%"}
          margin={"auto"}
          padding={"10px"}
          gap={4}
        >
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch />} />
              <Input placeholder="Search" onChange={handleSearch} />
            </InputGroup>
          </Box>
        </Box>
        <SimpleGrid columns={[1, 2]} spacing={6} width={"80%"} margin={"auto"}>
          {!loading && softwares && softwares.length > 0
            ? softwares.map((item, index) => (
                <Card
                  key={index}
                  display={"flex"}
                  as={motion.div}
                  whileHover={{ scale: 1.001 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 }}
                  flexDir={"column"}
                  gap={4}
                  borderRadius={"lg"}
                  boxShadow={"lg"}
                  textAlign={"justify"}
                  padding={"14px"}
                  border={"2px solid"}
                  borderColor={"mainBlue"}
                  cursor={"pointer"}
                >
                  <CardBody>
                    <Text fontSize={"xl"} fontWeight={"bold"} mb={4}>
                      {item.softwareName}
                    </Text>
                    <Text mb={2}>{item.shortDescription}</Text>
                    <Button
                      bgColor={"mainBlue"}
                      color={"white"}
                      boxShadow={"dark-sm"}
                      rounded={"lg"}
                      onClick={() => {
                        downloadSoftware(item._id, item.linkToDownload);
                      }}
                      _hover={{
                        bg: "lightBlue",
                        color: "mainBlue",
                        boxShadow: "dark-md",
                        border: "2px solid",
                        borderColor: "mainBlue",
                      }}
                    >
                      <Text>Download</Text>
                    </Button>
                  </CardBody>
                </Card>
              ))
            : null}
        </SimpleGrid>
        <Box
          width={"80%"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paginate
            page={page - 1}
            count={total}
            pageSize={pageSize}
            colorScheme="blue"
            onPageChange={(_page) => {
              if (page === _page + 1) {
                return;
              }
              setPage(_page + 1);
              handlePageChange(pageSize, _page + 1);
            }}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default LegalSoftwareList;
