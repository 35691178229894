import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../../../utils/storage";

function UserAuthWrapper({ Child }) {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();
  const toast = useToast();

  const navigate = (url) => navigation(url);

  useEffect(() => {
    if (
      ["/search"].includes(location.pathname) ||
      location.pathname.includes("/tags") ||
      location.pathname.includes("/search/results") ||
      location.pathname.includes("/allcourts") ||
      location.pathname.includes("/court")
    ) {
      setLoaded(true);
      return;
    }

    if (!get("usertoken") && !get("role")) {
      navigate("/auth/user/signin");
      toast({
        description: "You need to login first",
        status: "error",
        position: "bottom-right",
      });
      return;
    }

    if (get("role") === "user" && location.pathname.includes("admin")) {
      navigate("/");
      toast({
        description: "You need admin rights to access admin page",
        status: "error",
        position: "bottom-right",
      });
    }

    const _user = get("user");
    _user.data.haveAccessTo.push("buy");
    _user.data.haveAccessTo.push("member");
    _user.data.haveAccessTo.push("profile");

    if (
      get("role") === "user" &&
      !_user.data.haveAccessTo.includes(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      )
    ) {
      toast({
        description: "You dont have enough permission to access the page",
        status: "error",
        position: "bottom-right",
      });

      navigate("/");
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded && <Child />;
}

export default UserAuthWrapper;
