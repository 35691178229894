import {
  Text,
  Box,
  SimpleGrid,
  Flex,
  InputGroup,
  InputLeftAddon,
  Input,
  Tag,
  Icon,
  Select,
} from "@chakra-ui/react";
import Main from "../layouts/base";
import { BiSearch } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

export default function TagsWithFilter({
  data,
  loading,
  search,
  setSearch,
  highlight,
  searchThrough,
  title,
  handleFilter,
  courts,
  countries,
  setFilters,
  url,
}) {
  const navigate = useNavigate();
  const params = useParams();

  const handleClick = (id) => {
    window.location.href = url + "/" + id;
  };

  return (
    <Flex direction="row" mt={4}>
      <Box width="25%" p={4} bg={"blue.100"}>
        <Box mb={4} ml={1}>
          <Text fontFamily="heading" fontSize="2xl">
            Filters
          </Text>
        </Box>
        <InputGroup>
          <InputLeftAddon
            borderColor="gray.500"
            bg="gray.200"
            color="gray.500"
            borderRight="none"
            children={<Icon as={BiSearch} fontSize="xl" marginRight={-30} />}
            ml={0}
          />
          <Input
            _focusVisible={{
              boxShadow: "none",
              outline: "none",
            }}
            fontFamily="heading"
            padding="4"
            borderColor="gray.500"
            borderLeft="none"
            type="text"
            placeholder="Search..."
            rounded="md"
            _hover={{
              borderColor: "gray.500",
            }}
            onChange={(e) => {
              setSearch(e.target.value);
              searchThrough(e.target.value);
            }}
          />
        </InputGroup>
        <Select
          borderColor="gray.300"
          fontFamily="heading"
          color="gray.500"
          title="Filter by year"
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              year: e.target.value,
            }));
            handleFilter("year", e.target.value);
          }}
        >
          <option value="all">Year</option>
          {Array.from(
            { length: new Date().getFullYear() + 1 - 1955 },
            (v, k) => k + 1955
          )
            .reverse()
            .map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
        </Select>
        <Select
          name="filter"
          borderColor="gray.300"
          fontFamily="heading"
          color="gray.500"
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              month: e.target.value,
            }));
            handleFilter("month", e.target.value);
          }}
        >
          <option value="all">Month</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </Select>
        <Select
          name="filter"
          borderColor="gray.300"
          fontFamily="heading"
          color="gray.500"
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              country: e.target.value,
            }));
            handleFilter("country", e.target.value);
          }}
        >
          <option value="all">Country</option>
          {countries && countries.data && countries.data.length > 0
            ? countries.data.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.name}
                </option>
              ))
            : null}
        </Select>
        <Select
          name="filter"
          borderColor="gray.300"
          fontFamily="heading"
          color="gray.500"
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              court: e.target.value,
            }));
            handleFilter("court", e.target.value);
          }}
        >
          <option value="all">Court</option>
          {courts && courts.data && courts.data.length > 0
            ? courts.data.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.name}
                </option>
              ))
            : null}
        </Select>
      </Box>
      <Box width="75%" p={4}>
        <Box mb={4} ml={8} display="flex" alignItems="center">
          <Text fontFamily="heading" fontSize="2xl">
            {title}{" "}
            <Text as="span" color="gray.500" fontSize="sm">
              ({data?.data?.length}{" "}
              {data?.data?.length > 1 ? title + "s" : title})
            </Text>
          </Text>
        </Box>
        {!loading && data && data.data ? (
          <SimpleGrid
            columns={1}
            spacing={4}
            paddingX={8}
            rounded="md"
            boxShadow="sm"
            maxH="400px" // Adjust the maximum height here
            overflowY="auto" // Enable vertical scroll
          >
            {data.data.map((item) => (
              <Box
                key={item._id}
                pt={2} // Adjust the padding-top value as needed
                p={5}
                bg="gray.100"
                rounded="md"
                shadow="md"
                cursor="pointer"
                group="true"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => {
                  handleClick(item._id);
                }}
                _hover={{
                  bg: "whiteAlpha.200",
                }}
              >
                <Box>
                  <Text
                    as="h2"
                    _groupHover={{
                      color: "blue.500",
                    }}
                  >
                    {highlight(item.name, search)}
                  </Text>
                  <Text as="span" color="gray.500" fontSize="1rem" pt={3}>
                    {item.description.substring(0, 50)}
                  </Text>
                </Box>
                <Tag size="sm" colorScheme="linkedin">
                  {item.searchCount}
                </Tag>
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Box paddingX={8} width="100%">
            <Text>No data available</Text>
          </Box>
        )}
      </Box>
    </Flex>
  );
}
