import Layout from "../../../commonmodule/layouts/home";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetch";
import { config } from "../../../../utils/config";
import { useParams } from "react-router-dom";

const SingleCourt = () => {
  const { id } = useParams();
  const { data, loading } = useFetch("/courts/single/" + id);

  return (
    <Layout>
      {!loading && data.data && data.data ? (
        <Box
          display={"flex"}
          flexDir={"column"}
          mt={8}
          gap={4}
          borderRadius={"lg"}
          padding={"4%"}
          textAlign={"justify"}
        >
          <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
            {data.data.name}
          </Text>
          <Box display={"flex"} justifyContent={"center"}>
            <Image
              src={config.baseURL + `/CaC/download/${data.data.imageUrl}`}
              alt="court"
              width={{
                base: "100%",
                md: "50%",
              }}
              rounded={"lg"}
              boxShadow={"lg"}
            />
          </Box>
          {data.data.country && data.data.country.length > 0 && (
            <Box display={"flex"} flexDirection={"column"} gap={4}>
              <Text>
                <b>County:</b>{" "}
                {data.data.country.map((item) => item.name).join(", ")}
              </Text>
            </Box>
          )}

          {data.data.state && data.data.state.length > 0 && (
            <Box display={"flex"} flexDirection={"column"} gap={4}>
              <Text>
                <b>State:</b>{" "}
                {data.data.state.map((item) => item.name).join(", ")}
              </Text>
            </Box>
          )}

          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            width={{
              base: "100%",
            }}
          >
            <Text>{data.data.description}</Text>
          </Box>
          <Box>
            <Button
              onClick={() => window.history.back()}
              bgColor={"mainBlue"}
              color={"white"}
              boxShadow={"dark-sm"}
              rounded={"lg"}
              _hover={{
                bg: "lightBlue",
                color: "mainBlue",
                boxShadow: "dark-md",
                borderColor: "mainBlue",
                borderWidth: "2px",
              }}
            >
              See All Courts
            </Button>
          </Box>
        </Box>
      ) : (
        <Text>No data</Text>
      )}
    </Layout>
  );
};

export default SingleCourt;
