import Layout from "../../../commonmodule/layouts/home";
import { useNavigate, useParams } from "react-router-dom";
import { countriesData } from "../../../../data/courts-by-country"; // Update the import path

export default function CourtsbyCountryInfoPage() {
  const navigate = useNavigate();
  const { court: params_court, country } = useParams();

  const activecourt_data = countriesData.find((country) =>
    country.courts.some((court) => court.url === params_court)
  );

  const _currCountry = countriesData.find(
    (c) => c.key.toLowerCase() === country.toLowerCase()
  );

  const _currCourt = activecourt_data.courts.find(
    (court) => court.url === params_court
  );

  if (!_currCountry || !_currCourt) {
    navigate("/");
    return null;
  }

  return (
    <Layout>
      {activecourt_data ? (
        <div className="flex flex-col justify-center items-center w-[100%] p-[2%] px-[2%] mb-2">
          <div className="flex flex-col justify-center gap-6 w-[80%]">
            <div key={_currCourt.url}>
              {_currCourt.name ? (
                <div className="flex flex-start items-center">
                  <p className="mb-2 font-bold text-mainBlue text-3xl md:text-4xl m-4 p-2">
                    {_currCourt.name}
                  </p>
                </div>
              ) : null}
              {_currCourt.links ? (
                <section className="py-2">
                  {_currCourt.links.map((_link, idx) => (
                    <div key={idx}>
                      <div className="flex flex-col">
                        <div className="m-4">
                          {_link.name && (
                            <h4 className="font-bold bg-gray-200 rounded-md p-3">
                              {_link.name}
                            </h4>
                          )}

                          {!_link.name && (
                            <h4 className="font-bold bg-gray-200 rounded-md p-3">
                              "No Content Found."
                            </h4>
                          )}
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mt-4">
                            {_link.links.map((link, idx) => (
                              <a
                                key={idx}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-700 underline decoration-2 cursor-pointer underline-offset-4 decoration-blue-700"
                              >
                                <h3 className="text-base px-2 font-semibold mb-2">
                                  {link.name}
                                </h3>
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </section>
              ) : (
                <h4 className="font-bold bg-gray-200 rounded-md p-3">
                  "No Content Found."
                </h4>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  );
}
