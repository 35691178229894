import useFetch from "../../../hooks/useFetch";
import { useState } from "react";
import {
  Box,
  Flex,
  Link,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Select,
  FormErrorMessage,
  Text,
  InputGroup,
  Checkbox,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import { axiosInstance as axios } from "../../../lib/axios.js";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import useTitle from "../../../hooks/useTitle";

const AddCaCSchema = Yup.object().shape(
  {
    professionalname: Yup.string(),
    realname: Yup.string().required("Real Name is Required"),
    shortDescription: Yup.string(),
    description: Yup.string(),
    type: Yup.string().required("Type is Required"),
    email: Yup.string()
      .email("Invalid email")
      .when("mobile", {
        is: (mobile) => !mobile,
        then: Yup.string().required("Email is Required"),
      }),
    mobile: Yup.string().when("email", {
      is: (email) => !email,
      then: Yup.string().required("Mobile Number is Required"),
    }),
    address: Yup.string(),
    seoName: Yup.string(),
    seoDescription: Yup.string(),
    pinCode: Yup.string()
      .min(6, "Pin Code must be 6 digit")
      .max(6, "Pin Code must be 6 digit")
      .required("Pin Code is Required"),
    country: Yup.string(),
    state: Yup.string(),
    district: Yup.string(),
    website: Yup.string(),
    canBuyUserPlan: Yup.boolean(),
  },
  [["email", "mobile"]]
);

const MemberRegister = () => {
  const [phoneFields, setPhoneFields] = useState(1);
  const [phoneFieldValues, setPhoneFieldValues] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [refetch, setrefetch] = useState(false);

  const {
    data: _countrydata,
    loading: _countryloading,
    error: _countryerror,
  } = useFetch("countries/", {}, refetch);
  const [selectedFile, changeFile] = useState(null);

  // hooks
  const toast = useToast();
  useTitle("Service Provider Register");

  // functions to get data
  const fetchState = async (country) => {
    const res = await axios.get(`/states/findbycountryid?country=${country}`);
    setStateData(res.data.data);
  };

  const fetchDistrict = async (state) => {
    const res = await axios.get(`/district/findbystate?state=${state}`);
    setDistrictData(res.data.data);
  };

  const handleSubmit = async (values, { resetForm }) => {
    delete values.mobile;
    let mblStr = "";
    new Array(phoneFields).fill(0).map((e, i) => {
      if (values[`mobile${i}`]) {
        mblStr += values[`mobile${i}`] + ",";
      }
    });

    values.mobile = mblStr.slice(0, -1);
    const _values = {
      ...values,
      document: selectedFile,
    };

    let formData = new FormData();
    for (let key in _values) {
      if (
        _values[key] === null ||
        _values[key] === undefined ||
        _values[key] === ""
      ) {
        continue;
      }
      formData.append(key, _values[key]);
    }

    const url = "/memberlisting/create";

    const _res = await axios.post(url, formData);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      resetForm();
      changeFile(null);
      fetch();
      setPhoneFields(1);

      toast({
        description: "Account Created! Redirecting...",
        status: "success",
        position: "bottom-right",
      });
      // redirect to login pae
      setTimeout(() => {
        window.location.href = "/auth/user/signin";
      }, 2000);
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Box bg={"gray.100"} p={"10px"}>
      <Box
        w={"40%"}
        margin={"auto"}
        boxShadow={"xl"}
        // bg={"gray.100"}
        padding={"40px"}
        rounded={"lg"}
      >
        <Flex mb={"3rem"} gap={"10px"} flexDir={"column"}>
          <Text as={"h1"} fontWeight={"bold"}>
            Tell us a little bit about Yourself.
          </Text>
          <Text as={"p"} ml={"3px"} fontWeight={"normal"}>
            "This is the initial information of yourself. you can modify it in
            your member dashboard."
          </Text>
        </Flex>
        <Formik
          initialValues={{
            professionalname: "",
            realname: "",
            type: "",
            description: "",
            shortDescription: "",
            email: "",
            mobile: "",
            country: "",
            state: "",
            district: "",
            pinCode: "",
            address: "",
            seoName: "",
            seoDescription: "",
            website: "",
            canBuyUserPlan: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={AddCaCSchema}
        >
          {({ errors, touched, values, setValues, setFieldValue }) => (
            <Form>
              <Stack spacing={4}>
                <FormControl
                  id="professionalname"
                  isInvalid={
                    touched.professionalname && errors.professionalname
                  }
                  onChange={(e) => {
                    setFieldValue("seoName", e.target.value);
                  }}
                >
                  <FormLabel>
                    Professional Name of the Service Provider
                  </FormLabel>
                  <Field as={Input} type="text" name="professionalname" />
                  {errors.professionalname && (
                    <FormErrorMessage>
                      {errors.professionalname}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="realname"
                  isInvalid={touched.realname && errors.realname}
                >
                  <FormLabel>Real Name of the Service Provider</FormLabel>
                  <Field as={Input} type="text" name="realname" />
                  {errors.realname && (
                    <FormErrorMessage>{errors.realname}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl id="type" isInvalid={touched.type && errors.type}>
                  <FormLabel>Type of the Service Provider</FormLabel>
                  <Field as={Select} placeholder={"Select Type"} name="type">
                    <option value="lawyer">Lawyer</option>
                    <option value="lawfirm">Law Firm</option>
                    <option value="auditor">Auditors &amp; Accountants</option>
                    <option value="buisnessconsultent">
                      Buisness Consultent
                    </option>{" "}
                  </Field>
                  {errors.type && (
                    <FormErrorMessage>{errors.type}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="email"
                  isInvalid={touched.email && errors.email}
                >
                  <FormLabel>Email</FormLabel>
                  <Field
                    as={Input}
                    type="email"
                    name="email"
                    display={"block"}
                  />
                  {errors.email && (
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="country"
                  isInvalid={touched.country && errors.country}
                >
                  <FormLabel>Country</FormLabel>
                  <Field
                    as={Select}
                    placeholder={"Select Country"}
                    name="country"
                    onChange={(e) => {
                      setFieldValue("country", e.target.value);
                      setFieldValue("state", "");
                      setFieldValue("city", "");
                      fetchState(e.target.value);
                    }}
                  >
                    {!_countryloading &&
                      !_countryerror &&
                      _countrydata &&
                      _countrydata.data &&
                      _countrydata.data.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </Field>
                  {errors.country && (
                    <FormErrorMessage>{errors.country}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="state"
                  isInvalid={touched.state && errors.state}
                >
                  <FormLabel>State</FormLabel>
                  <Field
                    as={Select}
                    placeholder={"Select state"}
                    name="state"
                    onChange={(e) => {
                      setFieldValue("state", e.target.value);
                      setFieldValue("district", "");
                      fetchDistrict(e.target.value);
                    }}
                  >
                    {stateData.length > 0 &&
                      stateData.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </Field>
                  {errors.state && (
                    <FormErrorMessage>{errors.state}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="district"
                  isInvalid={touched.district && errors.district}
                >
                  <FormLabel>District</FormLabel>
                  <Field
                    as={Select}
                    placeholder={"Select district"}
                    name="district"
                  >
                    {districtData.length > 0 &&
                      districtData.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </Field>
                  {errors.district && (
                    <FormErrorMessage>{errors.district}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="pinCode"
                  isInvalid={touched.pinCode && errors.pinCode}
                >
                  <FormLabel>Pin Code</FormLabel>
                  <Field as={Input} type="pinCode" name="pinCode" />
                  {errors.pinCode && (
                    <FormErrorMessage>{errors.pinCode}</FormErrorMessage>
                  )}
                </FormControl>

                <InputGroup display={"flex"} flexDir={"column"} gap={4}>
                  {new Array(phoneFields).fill(0).map((e, i) => {
                    return (
                      <FormControl
                        key={i}
                        name={"mobile" + i}
                        id={"mobile" + i}
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          {i === 0 ? (
                            <>
                              <FormLabel>Phone</FormLabel>

                              <Flex gap={2}>
                                <AiOutlinePlusSquare
                                  size={25}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setPhoneFields(phoneFields + 1);
                                  }}
                                />

                                <AiOutlineMinusSquare
                                  size={25}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    if (phoneFields > 1) {
                                      setPhoneFields(phoneFields - 1);
                                    }
                                  }}
                                />
                              </Flex>
                            </>
                          ) : (
                            <></>
                          )}
                        </Flex>
                        <Field name={`mobile${i}`}>
                          {({ field, form }) => (
                            <PhoneInput
                              {...field}
                              country={"us"}
                              inputStyle={{
                                width: "100%",
                                height: "40px",
                              }}
                              name={`mobile${i}`}
                              id={`mobile${i}`}
                              onChange={(value, ___, _, formatted) => {
                                form.setFieldValue("mobile" + i, value);
                                setPhoneFieldValues([
                                  ...phoneFieldValues,
                                  value,
                                ]);
                              }}
                            />
                          )}
                        </Field>
                        {errors.mobile && (
                          <FormErrorMessage>{errors.mobile}</FormErrorMessage>
                        )}
                      </FormControl>
                    );
                  })}
                </InputGroup>

                <FormControl
                  id="website"
                  isInvalid={touched.website && errors.website}
                >
                  <FormLabel>Website</FormLabel>
                  <Field as={Input} type="text" name="website" />
                  {errors.website && (
                    <FormErrorMessage>{errors.website}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl id="image">
                  <FormLabel>Profile of the member</FormLabel>
                  <Input
                    type={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      changeFile(e.target.files[0]);
                    }}
                  />
                </FormControl>

                <FormControl
                  id="description"
                  name="description"
                  isInvalid={touched.description && errors.description}
                >
                  <FormLabel>Description</FormLabel>
                  <Field
                    as={Textarea}
                    type="text"
                    name="description"
                    value={values.description}
                  />
                  {errors.description && (
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="shortDescription"
                  name="shortDescription"
                  isInvalid={
                    touched.shortDescription && errors.shortDescription
                  }
                >
                  <FormLabel>Short Description</FormLabel>
                  <Field
                    as={Textarea}
                    type="text"
                    name="shortDescription"
                    value={values.shortDescription}
                  />
                  {errors.shortDescription && (
                    <FormErrorMessage>
                      {errors.shortDescription}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="address"
                  name="address"
                  isInvalid={touched.address && errors.address}
                >
                  <FormLabel>Address</FormLabel>
                  <Field
                    as={Textarea}
                    type="text"
                    name="address"
                    value={values.address}
                  />
                  {errors.address && (
                    <FormErrorMessage>{errors.address}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="seoName"
                  isInvalid={touched.seoName && errors.seoName}
                >
                  <FormLabel>Seo Title</FormLabel>
                  <Field as={Input} type="text" name="seoName" />
                  {errors.seoName && (
                    <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="seoDescription"
                  name="seoDescription"
                  isInvalid={touched.seoDescription && errors.seoDescription}
                >
                  <FormLabel>Seo Description</FormLabel>
                  <Field
                    as={Textarea}
                    type="text"
                    name="seoDescription"
                    value={values.seoDescription}
                  />
                  {errors.seoDescription && (
                    <FormErrorMessage>{errors.seoDescription}</FormErrorMessage>
                  )}
                </FormControl>

                {/* terms and consitions */}
                <Box>
                  <Flex gap={4} alignItems={"center"}>
                    <Checkbox size={"sm"} required={"true"} />{" "}
                    <FormLabel>I agree to the terms and conditions</FormLabel>
                  </Flex>
                  <Flex gap={4} alignItems={"center"}>
                    <Checkbox size={"sm"} required={"true"} />{" "}
                    <FormLabel>
                      I agree to the{" "}
                      <Link href="/memberpolicy" target="_blank">
                        Privacy Policy
                      </Link>
                    </FormLabel>
                  </Flex>
                </Box>

                <Stack spacing={6} direction={"row"} width={"40%"}>
                  <Button
                    colorScheme={"whiteAlpha"}
                    variant={"ghost"}
                    border={"2px solid gray"}
                    type={"button"}
                    color={"whatsapp.400"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    mb={"8"}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    {"< Goback"}
                  </Button>
                  <Button
                    colorScheme={"linkedin"}
                    type={"submit"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    mb={"8"}
                  >
                    {"Register"}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default MemberRegister;
