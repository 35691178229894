import React from "react";
import { Container, Box, Text } from "@chakra-ui/react";
import useFetch from "../../../hooks/useFetch";
import Layout from "../../commonmodule/layouts/home";

const TermsandConditions = () => {
  const { data, loading } = useFetch("/termsandcond/getall");

  return (
    <Layout>
      <Box mt={10}>
        {!loading && data.data && (
          <Container maxW={"80%"}>
            {/* dad */}
            <Text as="h1" fontSize="2xl" fontWeight="bold" mb={4}>
              {data.data.title}
            </Text>
            <Text as="p" fontSize="md" mb={4}>
              {data.data.description}
            </Text>
          </Container>
        )}
      </Box>
    </Layout>
  );
};

export default TermsandConditions;
