import { Box, useDisclosure } from "@chakra-ui/react";
import ContractAndClauseComponent from "../modules/homemodule/components/ContractsAndClauseSection";
import NewsandLegalUpdates from "../modules/homemodule/components/NewsandLegalUpdates";
import HeroSection from "../modules/homemodule/components/HeroSection";
import GazzetsandLaws from "../modules/homemodule/components/GazzetsandLaws";
import FooterComponent from "../modules/commonmodule/components/footer";
import { useGlobalContext } from "../modules/GlobalContext";
import WhoAreYou from "../modules/commonmodule/components/WhoAreYou";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import Layout from "../modules/commonmodule/layouts/home";
import { axiosInstance as axios } from "../lib/axios";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function HomePage() {
  const { user } = useGlobalContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currIp, setCurrIp] = useState();
  const location = useLocation();

  useEffect(() => {
    const isUser = sessionStorage.getItem("user");
    const country = sessionStorage.getItem("country");

    if (!country) {
      fetch("https://api64.ipify.org?format=json").then(async (response) => {
        const userIP = await response.json();
        setCurrIp(userIP.ip);

        sessionStorage.setItem("ip", userIP.ip);

        let res = await axios.get(
          "/user/getuserinfo/locationbased/" + userIP.ip
        );

        if (res.data.ok) {
          const { country_name } = res.data.data;

          if (country_name) {
            sessionStorage.setItem("country", country_name);
          }
        }
      });

      return;
    }

    const load = async () => {
      await delay(2000);
      if (!isUser) {
        onOpen();
      }
    };
    if (location.pathname === "/") {
      load();
    }

    return () => {
      onClose();
    };
  }, [location.pathname, onClose, onOpen, user]);

  return (
    <Layout>
      <Box height={"100%"} width={"100%"}>
        <HeroSection />
        <NewsandLegalUpdates user={user} />
        <GazzetsandLaws />
        <ContractAndClauseComponent />
        <FooterComponent />
        <WhoAreYou isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      </Box>
    </Layout>
  );
}
