import React from "react";
import Layout from "../../../commonmodule/layouts/home";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  Container,
  Wrap,
  WrapItem,
  Avatar,
  Heading,
  Badge,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { axiosInstance as axios } from "../../../../lib/axios";
import { get, save } from "../../../../utils/storage";
import { useEffect, useState, useRef } from "react";
import { config } from "../../../../utils/config";
import PhoneInput from "react-phone-input-2";

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname Required"),
  lastName: Yup.string().required("LastName Required"),
  email: Yup.string()
    .email("Invalid email! Please Check Your Email Id")
    .required("Email Required"),
  country: Yup.string().required("Country is Required"),
  mobile: Yup.string().required("Mobile is Required"),
});

const resetPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string(),
  newPassword: Yup.string().min(6, "Too Short!").max(50),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});

const UserProfile = () => {
  const toast = useToast();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const profileRef = useRef(null);

  const {
    data: { _id },
  } = get("user");

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await axios.get(`/user/profile`);
      const { ok, data } = res.data;
      if (ok) {
        setUser(data);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <Layout>
      <Container
        maxW="70%"
        bg={useColorModeValue("gray.50", "gray.800")}
        padding={"80px"}
        borderRadius={"lg"}
      >
        <Flex flexDirection={"column"} gap={2} pb={8}>
          <Heading fontFamily={"base"} as={"h4"}>
            Profile
          </Heading>
          <p>Update your profile and account settings.</p>
        </Flex>
        {!loading && user && (
          <Formik
            initialValues={{
              firstName: user.firstName,
              lastName: user?.lastName,
              email: user?.email,
              country: user?.country,
              mobile: user?.mobile,
            }}
            onSubmit={async (values) => {
              try {
                const formData = new FormData();
                formData.append("profileImage", profileImage);
                formData.append("firstName", values.firstName);
                formData.append("lastName", values.lastName);
                formData.append("email", values.email);
                formData.append("country", values.country);
                formData.append("mobile", values.mobile);

                const res = await axios.post("/client/user/" + _id, formData);

                const { ok, data } = res.data;

                if (ok) {
                  toast({
                    description: "Profile Updated",
                    status: "success",
                    position: "bottom",
                  });

                  const _pre = get("user");

                  _pre.data = {
                    ...data,
                  };

                  save("user", _pre);
                }
              } catch (e) {
                console.log(e);
              }
            }}
            validationSchema={UserProfileSchema}
          >
            {({ errors, touched, setValues }) => (
              <Form>
                <Flex gap={4} pb={5} alignItems={"center"}>
                  <Wrap>
                    <WrapItem>
                      <Avatar
                        id="profileImage"
                        size={"2xl"}
                        src={
                          profileImage
                            ? URL.createObjectURL(profileImage)
                            : config.baseURL +
                              `/CaC/download/${user?.profileImage}`
                        }
                      />
                    </WrapItem>
                  </Wrap>
                  <Wrap>
                    <WrapItem display={"flex"} gap={4}>
                      <Button
                        colorScheme={"linkedin"}
                        onClick={() => profileRef.current.click()}
                      >
                        Upload
                      </Button>
                    </WrapItem>
                  </Wrap>
                </Flex>
                <Stack spacing={4}>
                  <Flex gap={"16"}>
                    <FormControl
                      id="firstName"
                      isInvalid={touched.firstName && errors.firstName}
                    >
                      <FormLabel>First Name</FormLabel>
                      <Field as={Input} type="text" name="firstName" />
                      {errors.firstName && (
                        <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="lastName"
                      isInvalid={touched.lastName && errors.lastName}
                    >
                      <FormLabel>Last Name</FormLabel>
                      <Field as={Input} type="text" name="lastName" />
                      {errors.lastName && (
                        <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>

                  <FormControl
                    id="email"
                    isInvalid={touched.email && errors.email}
                    isDisabled={true}
                  >
                    <FormLabel>Email</FormLabel>
                    <Field as={Input} type="email" name="email" />
                    {errors.email && (
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    id="country"
                    isInvalid={touched.country && errors.country}
                  >
                    <FormLabel>Country</FormLabel>
                    <Field as={Input} type="text" name="country" />
                    {errors.country && (
                      <FormErrorMessage>{errors.country}</FormErrorMessage>
                    )}
                  </FormControl>

                  <input
                    type="file"
                    name="profile"
                    id="profile"
                    onChange={(e) => setProfileImage(e.target.files[0])}
                    ref={profileRef}
                    style={{ display: "none" }}
                  />

                  <FormControl
                    id="mobile"
                    isInvalid={touched.mobile && errors.mobile}
                  >
                    <FormLabel>Mobile</FormLabel>
                    {/* <Field as={Input} type="text" name="mobile" /> */}
                    <Field name="mobile">
                      {({ field, form }) => (
                        <PhoneInput
                          {...field}
                          country={"us"}
                          inputStyle={{
                            width: "100%",
                            height: "40px",
                          }}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue("mobile", value);
                          }}
                        />
                      )}
                    </Field>
                    {errors.mobile && (
                      <FormErrorMessage>{errors.mobile}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Flex
                    direction={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      width={"10%"}
                      bg={"blue.400"}
                      type={"submit"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      mb={"8"}
                    >
                      Save
                    </Button>

                    <Button
                      width={"10%"}
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      mb={"8"}
                      onClick={() => {
                        setProfileImage(null);
                        setValues({
                          firstName: user.firstName,
                          lastName: user?.lastName,
                          email: user?.email,
                          country: user?.country,
                          mobile: user?.mobile,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Stack>
              </Form>
            )}
          </Formik>
        )}

        <Flex flexDirection={"column"} gap={2} pb={8}>
          <Heading fontFamily={"base"} as={"h4"}>
            Reset Password
          </Heading>
          <p>Reset Your Password. </p>
        </Flex>

        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={async (values) => {
            try {
              const formData = new FormData();
              formData.append("currentPassword", values.currentPassword);
              formData.append("newPassword", values.newPassword);
              formData.append("confirmPassword", values.confirmPassword);
              formData.append("email", user?.email);

              const res = await axios.post(
                "/client/user/resetpassword/" + _id,
                formData
              );

              const { ok, message } = res.data;

              if (ok) {
                toast({
                  description: "Password Reset Successfully",
                  status: "success",
                  position: "bottom",
                });
              } else {
                toast({
                  description: message ? message : "Something went wrong",
                  status: "error",
                  position: "bottom",
                });
              }
            } catch (e) {
              console.log(e);
            }
          }}
          validationSchema={resetPasswordSchema}
        >
          {({ errors, touched, setValues }) => (
            <Form>
              <Stack spacing={4}>
                <FormControl
                  id="currentpassword"
                  isInvalid={touched.currentPassword && errors.currentPassword}
                >
                  <FormLabel>Current Password</FormLabel>
                  <Field as={Input} type="text" name="currentPassword" />
                  {errors.currentPassword && (
                    <FormErrorMessage>
                      {errors.currentPassword}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  id="newPassword"
                  isInvalid={touched.newPassword && errors.newPassword}
                >
                  <FormLabel>New Password</FormLabel>
                  <Field as={Input} type="text" name="newPassword" />
                  {errors.newPassword && (
                    <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  id="confirmPassword"
                  isInvalid={touched.confirmPassword && errors.confirmPassword}
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <Field as={Input} type="text" name="confirmPassword" />
                  {errors.confirmPassword && (
                    <FormErrorMessage>
                      {errors.confirmPassword}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Flex
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    width={"10%"}
                    color={"white"}
                    colorScheme={"linkedin"}
                    type={"submit"}
                    justifySelf={"flex-end"}
                    isDisabled={
                      !(
                        touched.currentPassword &&
                        touched.newPassword &&
                        touched.confirmPassword
                      )
                    }
                  >
                    Update
                  </Button>
                </Flex>
              </Stack>
            </Form>
          )}
        </Formik>

        <Flex flexDirection={"column"} gap={2} pb={8}>
          <Heading fontFamily={"base"} as={"h4"}>
            Subscription Details
          </Heading>
          {user?.subscription && (
            <p
              style={{
                fontWeight: "Normal",
              }}
            >
              Subscription Plan:{" "}
              <span style={{ fontWeight: "Bold" }}>
                {user?.subscription.membership}
              </span>
              <br />
            </p>
          )}
        </Flex>
      </Container>
    </Layout>
  );
};

export default UserProfile;
