import { Box } from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetch";
import Main from "../../../commonmodule/layouts/home";
import { useState, useEffect } from "react";
import TagswithFilter from "../../../commonmodule/components/TagsWithFilter";
import { axiosInstance as axios } from "../../../../lib/axios";

export default function JudgementTag() {
  let { data, loading, error } = useFetch(
    "/judgementandcase/getbytype/Judgement",
    {}
  );
  let { data: countries } = useFetch("/countries/", {});
  let { data: courts } = useFetch("/courts/", {});

  const [search, setSearch] = useState("");
  const [judgement, setJudgement] = useState("all");
  const [recentResult, setRecentResult] = useState("all");
  const [filter, setFilters] = useState({
    year: "",
    country: "",
    court: "",
    month: "",
  });

  useEffect(() => {
    setJudgement(data);
  }, [data]);

  const searchThrough = (keyword) => {
    if (keyword === "") {
      setJudgement({
        ...data,
        data: recentResult.data,
      });
    } else {
      const filtered = judgement.data.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setJudgement({
        ...data,
        data: filtered,
      });
    }
  };

  const highlight = (text, highlight) => {
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <Box as={"span"}>
        {" "}
        {parts.map((part, i) => (
          <Box
            as={"span"}
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    color: "blue",
                    fontWeight: "bold",
                    backgroundColor: "yellow",
                  }
                : {}
            }
          >
            {part}
          </Box>
        ))}{" "}
      </Box>
    );
  };

  const handleFilter = async (_filter, value) => {
    if (value === "all") value = "";

    let activeFilters = { ...filter, [_filter]: value };
    activeFilters = Object.keys(activeFilters).reduce((acc, key) => {
      if (activeFilters[key]) {
        acc[key] = activeFilters[key];
      }
      return acc;
    }, {});

    const res = await axios.get(
      `/judgementandcase/searchbyfilters/Judgement/${JSON.stringify(
        activeFilters
      )}`,
      {}
    );

    setJudgement({
      ...data,
      data: res.data.data,
    });

    setRecentResult({
      ...data,
      data: res.data.data,
    });

    setFilters(activeFilters);
  };

  return (
    <Main>
      <TagswithFilter
        data={judgement}
        search={search}
        title="Judgement"
        setSearch={setSearch}
        searchThrough={searchThrough}
        highlight={highlight}
        handleFilter={handleFilter}
        loading={loading}
        error={error}
        countries={countries}
        courts={courts}
        setFilters={setFilters}
      />
    </Main>
  );
}
