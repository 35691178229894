import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Image,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { axiosInstance as axios } from '../../../lib/axios';
import { deletevalue, get, save } from '../../../utils/storage';
import { useToast } from '@chakra-ui/react';
import { useGlobalContext } from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';

const SigninSchema = Yup.object().shape({
  type: Yup.string().required('User Type Required'),
  password: Yup.string().required('Password Required'),
  email: Yup.string()
    .email('Invalid email! Please Check Your Email Id')
    .required('Email Required'),
});

export default function SignIn({ role }) {
  const toast = useToast();

  const navigate = useNavigate();

  const { setUser, setLoggedIn, setRole } = useGlobalContext();

  return (
    <>
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          {role === 'user' ? (
            <Stack align={'center'}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                cursor={'pointer'}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                <Image src="/assets/homepage/logo.png" alt={'logo'} />
              </Box>
              <Heading fontSize={'4xl'}>Sign in to your account</Heading>
              <Text fontSize={'lg'} color={'gray.600'}>
                to enjoy all of our cool{' '}
                <Link color={'blue.400'}>features</Link> ✌️
              </Text>
            </Stack>
          ) : (
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>Admin Panel</Heading>
            </Stack>
          )}
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Formik
              initialValues={{
                email: '',
                password: '',
                rememberme: false,
              }}
              onSubmit={async (values) => {
                const url = role === 'admin' ? '/admin/login' : '/user/login';
                const res = await axios.post(url, {
                  ...values,
                });

                try {
                  if (res.data.ok) {
                    let { token, message } = res.data;
                    if (get('admintoken')) deletevalue('admintoken');
                    save('usertoken', token);
                    save('role', 'user');

                    setRole('user');

                    navigate('/');
                    toast({
                      description: message,
                      status: 'success',
                      position: 'bottom-right',
                    });

                    setLoggedIn(true);
                    setUser(res.data);
                    save('loggedIn', true);
                    save('user', res.data);
                  } else {
                    toast({
                      description: res.data.message,
                      status: 'error',
                      position: 'bottom-right',
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
              validationSchema={SigninSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="type"
                      isInvalid={errors.type && touched.type}
                    >
                      <FormLabel>User Type</FormLabel>
                      <Field
                        as={Select}
                        placeholder="Select user type"
                        name="type"
                      >
                        <option value="user">User</option>
                        <option value="member">Member</option>
                      </Field>
                      {errors.type && touched.type && (
                        <FormErrorMessage>{errors.type}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="email"
                      isInvalid={errors.email && touched.email}
                    >
                      <FormLabel>Email address</FormLabel>
                      <Field as={Input} type="email" name="email" />
                      {errors.email && touched.email && (
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="password"
                      isInvalid={errors.password && touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <Field as={Input} type="password" name="password" />
                      {errors.password && touched.password && (
                        <FormErrorMessage>
                          Password is required.
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <Stack spacing={10}>
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}
                      >
                        <Field as={Checkbox} name={'rememberme'}>
                          Remember me
                        </Field>
                      </Stack>
                      <Button
                        bg={'blue.400'}
                        type={'submit'}
                        color={'white'}
                        _hover={{
                          bg: 'blue.500',
                        }}
                      >
                        Sign in
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>

            <Text color={'gray.500'} mt={2} textAlign={'center'}>
              Forgot Password?{' '}
              <Link color={'blue.400'} href={'/forgotpassword'}>
                Send Reset Link
              </Link>
            </Text>

            <Stack pt={2}>
              <Text align={'center'}>
                New to us?{' '}
                <Link color={'blue.400'} href={'/auth/user/signup'}>
                  Create an account
                </Link>
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
