import { createContext, useContext } from "react";

const GlobalContext = createContext({
  loggedIn: false,
  setLoggedIn: () => {},
  user: null,
  setUser: () => {},
  role: "",
  setRole: () => {},
});

export default GlobalContext;

export const useGlobalContext = () => useContext(GlobalContext);
