import React, { useEffect, useState } from "react";
import { Box, Flex, Input, InputGroup, Tag, Text } from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import styled from "@emotion/styled";
import { useBreakpointValue } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

import useFetch from "../../../hooks/useFetch";
import { axiosInstance as axios } from "../../../lib/axios";

const options = [
  { label: "Contract", value: "contract" },
  { label: "Clause", value: "clause" },
  { label: "Law", value: "law" },
  { label: "Regulatory", value: "regulation" },
  { label: "Case", value: "Case" },
  { label: "Judgement", value: "judgement" },
].map((e) => ({
  label: e.label,
  value: e.value,
}));

const GlobalSearch = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [recent, setRecent] = useState([]);
  const [searchresultEnabled, setSearchResultEnabled] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    countries: [],
    states: [],
    courts: [],
  });
  const [filters, setFilters] = useState({
    type: "all",
    country: "all",
    state: "all",
    court: "all",
  });
  const [filtersVisible, setFiltersVisible] = useState(true);

  const { data: countries, loading } = useFetch("/countries");
  const { data: states } = useFetch("/states");
  let { data: courtsData } = useFetch("/courts");

  const doGlobalSearch = async (searchTerm) => {
    if (searchTerm === "") {
      setSearchResult([]);
      return;
    }

    // only search if the search term is more than 3 characters
    // Debounce the search
    if (searchTerm.length < 3) return;
    setSearchValue(searchTerm);

    const country =
      filters.country !== "all"
        ? filters.country.map((e) => e.value).join(",")
        : "all";
    const state =
      filters.state !== "all"
        ? filters.state.map((e) => e.value).join(",")
        : "all";
    const court =
      filters.court !== "all"
        ? filters.court.map((e) => e.value).join(",")
        : "all";
    const type =
      filters.type !== "all"
        ? filters.type.map((e) => e.value).join(",")
        : "all";

    const _res = await axios.get(
      `/search?searchWord=${searchTerm.trim()}&type=${type}&country=${country}&state=${state}&court=${court}`
    );

    if (_res.data.ok) {
      // first 5 results
      setSearchResult(_res.data.data.slice(0, 5));
    } else setSearchResult([]);
  };

  const recentSearches = async () => {
    const _res = await axios.get(`/search/recent-searches`);
    if (_res.data.ok) {
      setRecent(_res.data.data.slice(0, 10));
    }
    setSearchResultEnabled(true);
  };

  useEffect(() => {
    if (searchresultEnabled) {
      document.addEventListener("click", () => {
        setSearchResultEnabled(false);
      });
    }
  }, [searchresultEnabled]);

  const handleArrowClick = () => {
    setFiltersVisible(!filtersVisible);
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const MultiSelectStyled = styled.div`
    div.rmsc.multi-select {
      border-radius: 0;
    }

    .dropdown-container {
      height: 2.5rem !important;
      border: none;
      color: "orange";
      width: 200px;
      text-color: "mainBlue";
    }
  `;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={{ base: "column", md: "row", lg: "column" }}
      h={{ base: 12, md: 10 }}
      mb={{ base: 120, md: 0, lg: 10 }}
    >
      <Flex
        mt={{ base: "100.5px", lg: 0 }}
        mb={{ base: 1, md: 0, lg: 4 }}
        alignItems="center"
        w={{ base: "70%", md: "5%", lg: "62.5%" }}
        fontWeight="bold"
        backgroundColor="white"
        position="relative"
      >
        <Input
          border={"none"}
          value={searchValue}
          _focusVisible={{
            outline: "none",
          }}
          placeholder="Search for a clause, law, case, judgement, regulation, etc."
          color="black"
          fontWeight="bold"
          onChange={(e) => {
            doGlobalSearch(e.target.value);
            setSearchValue(e.target.value);
          }}
          onClick={recentSearches}
        />
        <Box
          position="absolute"
          right={2}
          top={0}
          bottom={0}
          display="flex"
          alignItems="center"
        >
          <SearchIcon color="mainBlue" />
        </Box>
      </Flex>

      <Box
        w={"50%"}
        backgroundColor={"white"}
        borderColor={"gray.300"}
        borderWidth={2}
        borderRadius={10}
        display={
          searchResult && searchResult.length > 0 && searchValue
            ? "block"
            : "none"
        }
        position="absolute"
        top={{ base: "calc(97% + 2px)", lg: "calc(90% + 2px)" }}
        left={"25%"}
        zIndex={100}
        _dark={{
          bg: "gray.600",
        }}
        p={2}
        justifyContent="center"
        margin={"auto"}
        rounded={"md"}
        gap={4}
      >
        {searchResult &&
          searchResult.map((item, index) => {
            return (
              <Box
                key={index}
                _hover={{
                  bg: "gray.200",
                  cursor: "pointer",
                }}
                w={"100%"}
                p={4}
                display={"flex"}
                rounded={"md"}
                alignItems={"center"}
                justifyContent={"space-between"}
                role="group"
                cursor={"pointer"}
                onClick={() => {
                  window.location.href = `/search/results/${item._id}/${item.type}/${searchValue}`;
                }}
              >
                <Box>
                  <Text
                    fontSize={"md"}
                    color={"gray.600"}
                    _groupHover={{
                      color: "linkedin.900",
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text
                    fontSize={"xs"}
                    color={"gray.300"}
                    _groupHover={{
                      color: "red.600",
                    }}
                    className="!text-left"
                  >
                    {item.type}
                  </Text>
                </Box>
                <Tag colorScheme={"linkedin"} size={"sm"}>
                  <Text size={"sm"}>{item.searchCount} Searches</Text>
                </Tag>
              </Box>
            );
          })}

        {recentSearches &&
          recentSearches.length > 0 &&
          recent.map((item, index) => {
            return (
              <Box
                key={index}
                _hover={{
                  bg: "gray.200",
                  cursor: "pointer",
                }}
                w={"100%"}
                p={4}
                display={"flex"}
                rounded={"md"}
                alignItems={"center"}
                justifyContent={"space-between"}
                role="group"
                cursor={"pointer"}
                onClick={() => {
                  window.location.href = `/search/results/${item._id}/${item.type}/${searchValue}`;
                }}
              >
                <Box>
                  <Text
                    fontSize={"md"}
                    color={"gray.600"}
                    _groupHover={{
                      color: "linkedin.900",
                    }}
                  >
                    {item.keyWord}
                  </Text>
                  <Text
                    fontSize={"xs"}
                    color={"gray.300"}
                    _groupHover={{
                      color: "red.600",
                    }}
                    className="!text-left"
                  >
                    {item.searchType}
                  </Text>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box
        top={{ base: "calc(97% + 2px)", lg: "calc(90% + 2px)" }}
        w={"50%"}
        h={"50vh"}
        overflowY={"scroll"}
        backgroundColor={"white"}
        borderColor={"gray.300"}
        borderWidth={2}
        borderRadius={10}
        display={
          recent &&
          searchresultEnabled &&
          recent.length > 0 &&
          searchResult.length === 0
            ? "block"
            : "none"
        }
        position="absolute"
        left={"25%"}
        zIndex={100}
        _dark={{
          bg: "gray.600",
        }}
        p={2}
        justifyContent="center"
        margin={"auto"}
        rounded={"md"}
        gap={4}
      >
        {
          // show recent searches
          recent &&
            recent.length > 0 &&
            // searchResult.length === 0 &&
            recent.map((item, index) => {
              return (
                <Box
                  key={index}
                  _hover={{
                    bg: "gray.200",
                    cursor: "pointer",
                  }}
                  w={"100%"}
                  p={4}
                  display={"flex"}
                  rounded={"md"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  role="group"
                  cursor={"pointer"}
                  onClick={() => {
                    doGlobalSearch(item.keyWord);
                    setSearchValue(item.keyWord);
                  }}
                >
                  <Box>
                    <Text
                      fontSize={"md"}
                      color={"gray.600"}
                      _groupHover={{
                        color: "linkedin.900",
                      }}
                    >
                      {item.keyWord}
                    </Text>
                    <Text
                      fontSize={"xs"}
                      color={"gray.300"}
                      _groupHover={{
                        color: "red.600",
                      }}
                    >
                      {item.searchType}
                    </Text>
                  </Box>
                </Box>
              );
            })
        }
      </Box>

      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection={isSmallScreen ? "column" : "row"}
        fontWeight="bold"
        width={{ base: "83%", md: "auto" }}
        zIndex={9}
      >
        {filtersVisible && (
          <Flex
            gap={1}
            justifyContent={{ base: "flex-start", md: "center" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              {!loading && countries && countries.data && (
                <>
                  <MultiSelectStyled
                    flexDirection={{ base: "column", md: "row", lg: "row" }}
                  >
                    <MultiSelect
                      id="multiselect"
                      className="custom-multi-select"
                      searchable
                      labelledBy="Select"
                      value={filters.country === "all" ? [] : filters.country}
                      displayValue="label"
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          country: e,
                          state: "all",
                          court: "all",
                        });
                        let _states = [];
                        for (let i of e) {
                          _states = _states.concat(
                            states.data.filter((e) => e.country._id === i.value)
                          );
                        }
                        setActiveFilters({
                          ...activeFilters,
                          states: _states,
                        });
                      }}
                      options={countries.data.map((e) => ({
                        label: e.name,
                        value: e._id,
                      }))}
                    ></MultiSelect>
                  </MultiSelectStyled>
                </>
              )}
            </div>

            <div
              style={{
                flex: 1,
              }}
            >
              <InputGroup>
                <MultiSelectStyled>
                  <MultiSelect
                    className="custom-multi-select"
                    placeholder="Type"
                    id={"multiselect"}
                    labelledBy={"Select Type"}
                    value={filters.type === "all" ? [] : filters.type}
                    displayValue="label"
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        type: e,
                      });
                    }}
                    options={options}
                  ></MultiSelect>
                </MultiSelectStyled>
              </InputGroup>
            </div>

            <>
              {filters &&
              filters.type &&
              typeof filters.type != "string" &&
              filters.type.some(
                (e) => e.value === "contract" || e.value === "clause"
              ) ? null : (
                <>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <InputGroup>
                      <MultiSelectStyled>
                        <MultiSelect
                          className="custom-multi-select"
                          disabled={filters.country === "all"}
                          placeholder="Select State"
                          id={"multiselect"}
                          labelledBy={"Select State"}
                          value={filters.state === "all" ? [] : filters.state}
                          displayValue="label"
                          onChange={(e) => {
                            setFilters({
                              ...filters,
                              state: e,
                              court: "all",
                            });

                            const country_ids = filters.country.map(
                              (e) => e.value
                            );
                            const state_ids = e.map((e) => e.value);

                            const _courts = [];
                            for (let i of courtsData.data) {
                              i.country.map((e) => {
                                if (country_ids.includes(e._id)) {
                                  return i.state.map((e) => {
                                    if (state_ids.includes(e._id)) {
                                      _courts.push(i);
                                    }
                                    return null;
                                  });
                                }
                                return null;
                              });
                            }

                            setActiveFilters({
                              ...activeFilters,
                              courts: _courts,
                            });
                          }}
                          options={
                            filters.country === "all"
                              ? []
                              : activeFilters.countries &&
                                activeFilters.states.map((e) => ({
                                  label: e.name,
                                  value: e._id,
                                }))
                          }
                        ></MultiSelect>
                      </MultiSelectStyled>
                    </InputGroup>
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <InputGroup>
                      <MultiSelectStyled>
                        <MultiSelect
                          className="custom-multi-select"
                          disabled={
                            filters.country === "all" || filters.state === "all"
                          }
                          placeholder="Court"
                          id={"multiselect"}
                          labelledBy={"Select court"}
                          value={filters.court === "all" ? [] : filters.court}
                          displayValue="label"
                          onChange={(e) => {
                            setFilters({
                              ...filters,
                              court: e,
                            });
                          }}
                          options={
                            filters.country === "all"
                              ? []
                              : activeFilters.countries &&
                                activeFilters.states &&
                                activeFilters.courts.map((e) => ({
                                  label: e.name,
                                  value: e._id,
                                }))
                          }
                        ></MultiSelect>
                      </MultiSelectStyled>
                    </InputGroup>
                  </div>
                </>
              )}
            </>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default GlobalSearch;
