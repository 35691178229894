import React from "react";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
const SignOut = () => {
  const toast = useToast();

  useEffect(() => {
    sessionStorage.clear();
    window.location.href = "/auth/user/signin";

    toast({
      title: "Signed Out",
      description: "You have been signed out successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  });

  return <div>SignOut</div>;
};

export default SignOut;
