import { Box } from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetch";
import Main from "../../../commonmodule/layouts/home";
import Tags from "../../../commonmodule/components/Tags";
import { useState, useEffect } from "react";
import { axiosInstance as axios } from "../../../../lib/axios";

const fetchFilteredData = async (url, options) => {
  try {
    const { data } = await axios.get(url, options);
    return data;
  } catch (error) {
    console.log(error);
  }
};
const pageSize = 10;

export default function ContractTag() {
  const { data, loading, error } = useFetch("/CaC/paginate?type=contract", {
    params: {
      page: 1,
      limit: pageSize,
    },
  });
  const { data: agreementType, loading: agreementTypeLoading } =
    useFetch("/aggrementtype");
  const { data: industrytype, loading: industrytypeLoading } =
    useFetch("/industrytype");
  const [filters, setFilters] = useState({
    agreementType: [],
    industrytype: "",
  });
  const [total, setTotal] = useState(5);

  const [search, setSearch] = useState("");
  const [contract, setContract] = useState("all");

  useEffect(() => {
    setContract(data);
    setTotal(data.total);
  }, [data]);

  const searchThrough = (keyword) => {
    if (keyword === "") {
      setContract({
        ...data,
        data: data.data,
      });
    } else {
      const filtered = data.data.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setContract({
        ...data,
        data: filtered,
      });
    }
  };

  const highlight = (text, highlight) => {
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <Box as={"span"}>
        {" "}
        {parts.map((part, i) => (
          <Box
            as={"span"}
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    color: "blue",
                    fontWeight: "bold",
                    backgroundColor: "yellow",
                  }
                : {}
            }
          >
            {part}
          </Box>
        ))}{" "}
      </Box>
    );
  };

  const handleFilter = (e) => {
    if (e === "" || e === "all") {
      setContract({
        ...data,
        data: data.data,
      });
    } else {
      const filtered = data.data.filter((item) => {
        return item.name.toLowerCase()[0] === e.toLowerCase();
      });
      setContract({
        ...data,
        data: filtered,
      });
    }
  };

  const handleAgreementTypeChange = (agreement) => {
    if (agreement && agreement.length === 0) {
      setContract(data);
      return;
    }

    const fetchData = async () => {
      try {
        const data = await fetchFilteredData(`/cac/paginate`, {
          params: {
            type: "contract",
            aggrementType: agreement.join(","),
            industrytype: filters.industrytype,
          },
        });
        setContract({
          ...data,
          data: data.data,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  };

  const handleindustrytypeChange = (industry) => {
    if (industry === "") {
      setContract(data);
      return;
    }

    const agreementTypeIds = filters.agreementType.map((item) => item._id);

    const fetchData = async () => {
      try {
        const data = await fetchFilteredData(`/cac/paginate`, {
          params: {
            type: "contract",
            agreementType: agreementTypeIds.join(","),
            industrytype: industry,
          },
        });
        setContract({
          ...data,
          data: data.data,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    const agreementTypeIds = filters.agreementType.map((item) => item._id);

    const data = await fetchFilteredData(`/cac/paginate`, {
      params: {
        type: "contract",
        page: page,
        limit: newPageSize,
        agreementType: agreementTypeIds.join(","),
        industrytype: filters.industrytype,
      },
    });

    setContract({
      ...data,
      data: data.data,
    });
    setTotal(data.total);
  };

  return (
    <Main>
      <Tags
        data={contract}
        search={search}
        setSearch={setSearch}
        agreementTypes={
          agreementType && !agreementTypeLoading ? agreementType.data : []
        }
        industrytypes={
          industrytype && !industrytypeLoading ? industrytype.data : []
        }
        title="Contract"
        searchThrough={searchThrough}
        highlight={highlight}
        handleFilter={handleFilter}
        handleAgreementTypeChange={handleAgreementTypeChange}
        handleindustrytypeChange={handleindustrytypeChange}
        loading={loading}
        filters={filters}
        pageSize={pageSize}
        total={total}
        setFilters={setFilters}
        handlePageChange={handlePageChange}
        error={error}
        url={"/cac/contract/"}
      />
    </Main>
  );
}
