import { Box, Flex, Button, Text, Icon } from "@chakra-ui/react";
import Layout from "../../modules/commonmodule/layouts/home";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function ContractHome() {
  const navigate = useNavigate();

  function handleClick(url) {
    navigate(url);
  }

  return (
    <Layout>
      <Flex
        w="100%"
        bg={"mainBlue"}
        justifyContent={"space-around"}
        alignItems={"center"}
        height={"16"}
      >
        <Text fontSize={"xl"} color={"white"}>
          My Contracts
        </Text>

        <Flex alignItems={"center"} gap={4}>
          <Box
            border={"1px solid"}
            borderColor={"white"}
            padding={2}
            rounded={"md"}
            bg={"white"}
          >
            <BiSearch size={20} color="black" />
          </Box>
          <Button onClick={() => handleClick("/newcontract")}>
            New Contract
          </Button>
        </Flex>
      </Flex>
    </Layout>
  );
}

export default ContractHome;
