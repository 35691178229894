import Layout from "../../../commonmodule/layouts/home";
import {
  Button,
  Image,
  SimpleGrid,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Card, CardBody, CardFooter } from "@chakra-ui/card";
import { axiosInstance as axios } from "../../../../lib/axios";
import { FiSearch } from "react-icons/fi";
import { config } from "../../../../utils/config";
import { useEffect, useState } from "react";
import { Paginate } from "react-paginate-chakra-ui";

const Resources = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);

  const pageSize = 4;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchBooks = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/resources/paginate?page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setResources(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
    if (total <= pageSize) {
      console.log(total, pageSize);
      prevButton.disabled = true;
      nextButton.disabled = true;
    }

    if (data.length === 0) return;
    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/resources/search/paginated/${search}?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setResources(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/resources/paginate?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setResources(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  const handleClick = (path) => {
    window.open(path, "_blank");
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else if (total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    fetchBooks(1);
  }, []);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setResources(data.data);
      setTotal(currentTotal);
      return;
    }

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const res = await axios.get(
      `/resources/search/paginated/${e.target.value}?page=${page}&limit=${pageSize}`
    );

    setResources(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  const downloadFile = (key) => {
    if (!key) return;
    window.open(`${config.baseURL}/CaC/download/${key}`, "_blank");
  };

  return (
    <Layout>
      <Box width={"100%"} mt={10}>
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"80%"}
          margin={"auto"}
          padding={"10px"}
          gap={4}
        >
          <Box width={"27%"}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch />} />
              <Input placeholder="Search" onChange={handleSearch} />
            </InputGroup>
          </Box>
        </Box>
        <SimpleGrid
          columns={[1, 2, 3, 4]}
          spacing={6}
          width={"80%"}
          margin={"auto"}
        >
          {!loading && resources && resources.length > 0 ? (
            resources.map((item, index) => (
              <Card
                key={index}
                display={"flex"}
                as={motion.div}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1 }}
                flexDir={"column"}
                gap={4}
                borderRadius={"lg"}
                boxShadow={"lg"}
                padding={"4%"}
                textAlign={"center"}
                border={"2px solid"}
                borderColor={"mainBlue"}
                cursor={"pointer"}
                // onClick={() => {
                //   handleClick(`/court/${item._id}`);
                // }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CardBody>
                  <Image
                    src={config.baseURL + `/CaC/download/${item.image}`}
                    alt="court"
                    width={"200px"}
                    height={"300px"}
                    rounded={"lg"}
                  />
                </CardBody>
                <CardFooter
                  display={"flex"}
                  flexDirection={"column"}
                  gap={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text fontSize={"xl"} fontWeight={"bold"}>
                    {item.name}
                  </Text>
                  <Text>{item.shortdescription}</Text>
                  <Button
                    bgColor={"mainBlue"}
                    width={"50%"}
                    color={"white"}
                    boxShadow={"dark-sm"}
                    onClick={() => handleClick(item.url)}
                    rounded={"lg"}
                    _hover={{
                      bg: "lightBlue",
                      color: "mainBlue",
                      boxShadow: "dark-md",
                      border: "2px solid",
                      borderColor: "mainBlue",
                    }}
                  >
                    <Text>Visit</Text>
                  </Button>
                  <Text color={"white"} userSelect="none">
                    <b>Posted on {item.createdAt.split("T")[0]}</b>
                  </Text>
                </CardFooter>
              </Card>
            ))
          ) : (
            <Text>No resources found</Text>
          )}
        </SimpleGrid>
        <Box
          width={"80%"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paginate
            page={page - 1}
            count={total}
            pageSize={pageSize}
            colorScheme="blue"
            onPageChange={(_page) => {
              if (page === _page + 1) {
                return;
              }
              setPage(_page + 1);
              handlePageChange(pageSize, _page + 1);
            }}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Resources;
