import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../../../lib/axios";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { get } from "../../../utils/storage";
import { useGlobalContext } from "../../../modules/GlobalContext";

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function BuySubscription() {
  const { user } = useGlobalContext();
  const [data, setData] = useState(null);
  const [memberDetails, setMemberDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buyPack = async () => {
    const user = get("user").data;
    const plan = data.find((plan) => plan._id === selectedPlan);

    console.log(plan, "pla");

    const _res = await axios.post(`/user/updateplan/`, {
      userId: user._id,
      planId: plan._id,
    });

    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      navigate("/");
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    }
  };

  const fetchPlans = async () => {
    const res = await axios.get(`/plans`);
    if (!res.data.ok) return [];
    return res.data.data;
  };

  const fetchMemberDeatils = async (id) => {
    const res = await axios.get(`/memberlisting/single/${id}`);
    if (!res.data.ok) return [];
    return res.data.data;
  };

  useEffect(() => {
    (async () => {
      const userDeatils = await fetchMemberDeatils(user.data._id);
      if (userDeatils[0]) {
        setMemberDetails(userDeatils[0]);
      }
      const plans = await fetchPlans();
      setData(plans);
    })();
  }, [user]);

  const redirect = (link) => {
    navigate(link);
  };

  return (
    <Box
      py={12}
      minHeight={"100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
      color={useColorModeValue("gray.700", "gray.200")}
      bg={useColorModeValue("gray.200", "gray.800")}
    >
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={"gray.500"}>
          Start with free trial. No credit card needed. Cancel at anytime.
        </Text>
      </VStack>
      {memberDetails && (
        <Stack
          direction={"row"}
          textAlign="center"
          spacing={{ base: 4, lg: 12 }}
          py={10}
        >
          {data &&
            data.length !== 0 &&
            data
              ?.filter((i) => i.type === "member")
              .map((plan, i) => (
                <PriceWrapper key={i}>
                  <Box
                    shadow={"2xl"}
                    rounded={"lg"}
                    _hover={{
                      shadow: "md",
                    }}
                    pos={"relative"}
                    onClick={() => {
                      setSelectedPlan(plan._id);
                    }}
                    cursor={"pointer"}
                  >
                    {plan.isFeatured && (
                      <Box
                        bg={"red.400"}
                        color={"white"}
                        px={2}
                        py={1}
                        fontWeight={"bold"}
                        fontSize={"xs"}
                        rounded={"md"}
                        pos={"absolute"}
                        top={1}
                        left={1}
                      >
                        Most Popular
                      </Box>
                    )}
                    <Box
                      py={4}
                      px={12}
                      bg={plan._id !== selectedPlan ? "gray.300" : "niceVoilet"}
                    >
                      <Text fontWeight="500" fontSize="2xl">
                        {String(plan.name).toUpperCase()}
                      </Text>
                      <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">
                          $
                        </Text>
                        <Text fontSize="5xl" fontWeight="900">
                          {plan.amount}
                        </Text>
                        <Text fontSize="3xl" color="gray.500">
                          /mo
                        </Text>
                      </HStack>
                    </Box>
                    <VStack
                      bg={"whiteAlpha.100"}
                      py={4}
                      borderBottomRadius={"xl"}
                    >
                      <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          {plan.limit} requests per month
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          Lorem, ipsum dolor.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          5TB Lorem, ipsum dolor.
                        </ListItem>
                      </List>
                      <Box w="80%" pt={7}>
                        <Button
                          w="full"
                          colorScheme="red"
                          variant={
                            plan._id === selectedPlan ? "solid" : "outline"
                          }
                          disabled={plan._id !== selectedPlan}
                          onClick={buyPack}
                        >
                          Buy Now
                        </Button>
                      </Box>
                    </VStack>
                  </Box>
                </PriceWrapper>
              ))}
        </Stack>
      )}

      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          {memberDetails && memberDetails.canBuyUserPlan
            ? "Additional Plans"
            : null}
        </Heading>
      </VStack>
      <Stack
        direction={"row"}
        textAlign="center"
        spacing={{ base: 4, lg: 12 }}
        py={10}
      >
        {memberDetails && memberDetails.canBuyUserPlan
          ? data &&
            data.lenght !== 0 &&
            data
              ?.filter((i) => i.type === "user")
              .map((plan, i) => (
                <PriceWrapper key={i}>
                  <Box
                    shadow={"2xl"}
                    rounded={"lg"}
                    _hover={{
                      shadow: "md",
                    }}
                    pos={"relative"}
                    onClick={() => {
                      setSelectedPlan(plan._id);
                    }}
                    cursor={"pointer"}
                  >
                    {plan.isFeatured && (
                      <Box
                        bg={"red.400"}
                        color={"white"}
                        px={2}
                        py={1}
                        fontWeight={"bold"}
                        fontSize={"xs"}
                        rounded={"md"}
                        pos={"absolute"}
                        top={1}
                        left={1}
                      >
                        Most Popular
                      </Box>
                    )}
                    <Box
                      py={4}
                      px={12}
                      bg={plan._id !== selectedPlan ? "gray.300" : "niceVoilet"}
                    >
                      <Text fontWeight="500" fontSize="2xl">
                        {String(plan.name).toUpperCase()}
                      </Text>
                      <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">
                          $
                        </Text>
                        <Text fontSize="5xl" fontWeight="900">
                          {plan.amount}
                        </Text>
                        <Text fontSize="3xl" color="gray.500">
                          /mo
                        </Text>
                      </HStack>
                    </Box>
                    <VStack
                      bg={"whiteAlpha.100"}
                      py={4}
                      borderBottomRadius={"xl"}
                    >
                      <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          {plan.countriesAllowed} requests per month
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          Lorem, ipsum dolor.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          5TB Lorem, ipsum dolor.
                        </ListItem>
                      </List>
                      <Box w="80%" pt={7}>
                        <Button
                          w="full"
                          colorScheme="red"
                          variant={
                            plan._id === selectedPlan ? "solid" : "outline"
                          }
                          disabled={plan._id !== selectedPlan}
                          onClick={buyPack}
                        >
                          Buy Now
                        </Button>
                      </Box>
                    </VStack>
                  </Box>
                </PriceWrapper>
              ))
          : !memberDetails &&
            data &&
            data.lenght !== 0 &&
            data
              ?.filter((i) => i.type === "user")
              .map((plan, i) => (
                <PriceWrapper key={i}>
                  <Box
                    shadow={"2xl"}
                    rounded={"lg"}
                    _hover={{
                      shadow: "md",
                    }}
                    pos={"relative"}
                    onClick={() => {
                      setSelectedPlan(plan._id);
                    }}
                    cursor={"pointer"}
                  >
                    {plan.isFeatured && (
                      <Box
                        bg={"red.400"}
                        color={"white"}
                        px={2}
                        py={1}
                        fontWeight={"bold"}
                        fontSize={"xs"}
                        rounded={"md"}
                        pos={"absolute"}
                        top={1}
                        left={1}
                      >
                        Most Popular
                      </Box>
                    )}
                    <Box
                      py={4}
                      px={12}
                      bg={plan._id !== selectedPlan ? "gray.300" : "niceVoilet"}
                    >
                      <Text fontWeight="500" fontSize="2xl">
                        {String(plan.name).toUpperCase()}
                      </Text>
                      <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">
                          $
                        </Text>
                        <Text fontSize="5xl" fontWeight="900">
                          {plan.amount}
                        </Text>
                        <Text fontSize="3xl" color="gray.500">
                          /mo
                        </Text>
                      </HStack>
                    </Box>
                    <VStack
                      bg={"whiteAlpha.100"}
                      py={4}
                      borderBottomRadius={"xl"}
                    >
                      <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          {plan.countriesAllowed} countries
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          {plan.modulesAllowed} modules
                        </ListItem>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          Lorem, ipsum dolor.
                        </ListItem>
                      </List>
                      <Box w="80%" pt={7}>
                        <Button
                          w="full"
                          colorScheme="red"
                          variant={
                            plan._id === selectedPlan ? "solid" : "outline"
                          }
                          disabled={plan._id !== selectedPlan}
                          onClick={() => redirect(`/subsPlan/${plan.name}`)}
                        >
                          Buy Now
                        </Button>
                      </Box>
                    </VStack>
                  </Box>
                </PriceWrapper>
              ))}
      </Stack>
      {/* <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="2xl" fontWeight="600">
              Buy Plan
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize="xl" fontWeight="600">
                Select Payment Method
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      {/* <Box
        position="absolute"
        right={5}
        top={2}
        p={5}
        borderRadius="50%"
        bg="red.400"
        cursor="pointer"
        onClick={onOpen}
      >
        <FaShoppingCart size={30} />
      </Box> */}
    </Box>
  );
}
