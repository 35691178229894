import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Flex,
  Text,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function WhoAreYou({ isOpen, onClose }) {
  const [email, setEmail] = useState("");

  const checkEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  let navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Hey, Who are you?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded" colorScheme="telegram">
            <TabList>
              <Tab>Service Provider</Tab>
              <Tab>Subscriber</Tab>
              <Tab>Visitor</Tab>
            </TabList>
            <TabPanels>
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={2}
              >
                <Text textAlign={"center"}>
                  A service provider refers to professionals such as lawyers and
                  business consultants who offer legal services to individuals
                  and businesses. They provide advice, guidance, and
                  representation to clients seeking their expertise in legal
                  matters.
                </Text>
                <Flex>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      navigate("/members/register/new");
                    }}
                  >
                    Become One ?
                  </Button>

                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      navigate("/auth/user/signin");
                    }}
                  >
                    Login
                  </Button>
                </Flex>
              </TabPanel>
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={2}
              >
                <Text as={"p"} textAlign={"center"}>
                  A subscriber is someone who pays a fee to regularly access
                  content, services, or products on a website. They have
                  registered, paid, and usually receive exclusive benefits or
                  updates as part of their subscription. <br />
                </Text>
                <Flex>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      navigate("/subscriptions/buy");
                    }}
                  >
                    Become One ?
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      navigate("/auth/user/signin");
                    }}
                  >
                    Login
                  </Button>
                </Flex>
              </TabPanel>

              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={2}
              >
                <p>
                  Signup for our Newsletter to Stay Updated with LinkinLegal{" "}
                  <br />
                </p>
                <Input
                  type={"email"}
                  placeholder="Enter your email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <Button
                  colorScheme={"telegram"}
                  onClick={() => {
                    if (checkEmail(email)) {
                      onClose();
                    } else {
                      alert("Invalid Email");
                    }
                  }}
                >
                  Subscribe
                </Button>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}
