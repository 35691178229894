import { Box, Button, SimpleGrid, Text, Flex, Center } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoLibrarySharp } from "react-icons/io5";
import { VscGraph, VscFileSubmodule } from "react-icons/vsc";
import { BsFillAwardFill } from "react-icons/bs";
import { GiPlainCircle } from "react-icons/gi";
import { GoLaw } from "react-icons/go";
import { TiNews } from "react-icons/ti";
import { AiOutlineSearch } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";

import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";

const GazzetsAndLaws = () => {
  const [QuickLinks] = useState([
    {
      title: "Legislative insights",
      name: "courts",
      selected: true,
      link: "/allcourts",
      icon: <VscGraph />,
    },
    {
      title: "Resources",
      name: "resources",
      selected: false,
      link: "/resources",
      icon: <VscFileSubmodule />,
    },
    {
      title: "Library and E-Books",
      name: "books",
      selected: false,
      link: "/ebooks",
      icon: <IoLibrarySharp />,
    },
    {
      title: "Global Search",
      name: "search",
      selected: false,
      link: "#",
      icon: <AiOutlineSearch />,
    },
  ]);

  const { data, loading } = useFetch("/gazettes/home");

  const navigate = useNavigate();
  const RightArrowIcon = () => <IoIosArrowForward size={12} />;

  const redirect = (link) => {
    navigate(link);
  };

  const [sectionVisible, setSectionVisible] = useState(false);

  const toggleSection = () => {
    setSectionVisible(!sectionVisible);
  };

  return (
    <Box width={"70%"} margin={"auto"}>
      {/* Section 1: Gazettes and Laws */}
      <Flex
        flexDir={"column"}
        alignItems={"center"}
        ml={{ base: "20px", lg: "80px" }}
      >
        <Text
          fontSize={{
            base: "3xl",
            md: "5xl",
          }}
          color={"mainBlue"}
          fontFamily={"heading"}
        >
          Gazettes and Laws
        </Text>
        <Box borderBottom="4px solid rgba(30, 58, 138)" width="80px" mb={1} />

        <Text
          as="p"
          fontSize={{
            base: "md",
            md: "md",
          }}
          m={4}
          color={"mainBlue"}
          textAlign="center"
        >
          Access the latest laws, regulations, and gazettes to stay informed
          about legal developments. Our team of experts regularly updates this
          section with the latest information to help you stay ahead. Browse
          through our collection to find relevant documents.
        </Text>
      </Flex>

      {/* Section 2: Quick links*/}

      <Flex
        width="80%"
        mt={{ base: "20px", lg: "-80px" }}
        ml={{ base: "20px", lg: "80px" }}
        justifyContent="center"
        alignItems="center"
      >
        <SimpleGrid columns={[1, 4]} justifyContent="center">
          {QuickLinks.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
              borderRadius="md"
              borderWidth="1px"
              borderColor="mainBlue"
              color="mainBlue"
              cursor="pointer"
              height={"200px"}
              width="200px"
              m={{ base: "20px", lg: "75px" }}
            >
              {/* Large icon */}
              <div style={{ fontSize: "100px" }}>{item.icon}</div>

              <Text
                fontSize="lg"
                mt={2}
                onClick={() => redirect(item.link ? item.link : "#")}
              >
                {item.title}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>

      {/* Section 3: Regulatory, Law, Gazettes, and Gazette */}

      <Center mt={{ base: "0px", lg: "-50px" }}>
        <Button
          variant="outline"
          borderColor="mainBlue"
          size="md"
          mb={10}
          onClick={toggleSection}
        >
          View More Details
        </Button>
      </Center>

      <AnimatePresence>
        {sectionVisible && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <SimpleGrid
              columns={[1, 4]}
              spacing={4}
              mt={2}
              ml={{ base: "0", lg: "20px" }}
              width={{ base: "100%", md: "100%" }}
            >
              <Card>
                {/* Regulatory section */}
                <CardHeader>
                  <BsFillAwardFill size={32} color="mainBlue" />
                  <Text fontSize="xl">Regulatory</Text>
                </CardHeader>
                <Divider />
                <CardBody>
                  {data?.data?.regulation?.slice(0, 5).map(
                    (item, index) => (
                      (
                        <Box display="flex" alignItems="center" key={index}>
                          <GiPlainCircle size={12} />
                          <Text
                            fontSize="sm"
                            fontWeight="light"
                            ml={2}
                            _hover={{
                              color: "mainBlue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              redirect(`/lawsandreg/regulation/${item._id}`)
                            }
                          >
                            {item.name}
                          </Text>
                        </Box>
                      )
                    )
                  )}
                </CardBody>
                <CardFooter>
                  <Button
                    color="mainBlue"
                    fontSize="sm"
                    variant="link"
                    rightIcon={<RightArrowIcon />}
                    onClick={() => redirect("/tags/regulation")}
                    ml={2.5}
                  >
                    View More
                  </Button>
                </CardFooter>
              </Card>

              {/* Law section */}
              <Card>
                <CardHeader>
                  <GoLaw size={32} color="mainBlue" />
                  <Text fontSize="xl">Law</Text>
                </CardHeader>
                <Divider />
                <CardBody>
                  {data?.data?.law.map((item, index) => (
                    <Box display="flex" alignItems="center" key={index}>
                      <GiPlainCircle size={12} />
                      <Text
                        fontSize="sm"
                        fontWeight="light"
                        ml={2}
                        _hover={{
                          color: "mainBlue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          redirect(`/lawsandreg/regulation/${item._id}`)
                        }
                      >
                        {item.name}
                      </Text>
                    </Box>
                  ))}
                </CardBody>
                <CardFooter>
                  <Button
                    color="mainBlue"
                    fontSize="sm"
                    variant="link"
                    rightIcon={<RightArrowIcon />}
                    onClick={() => redirect("/tags/laws")}
                    ml={2.5}
                  >
                    View More
                  </Button>
                </CardFooter>
              </Card>

              {/* Gazettes section */}
              <Card>
                <CardHeader>
                  <TiNews size={32} color="mainBlue" />
                  <Text fontSize="xl">Gazettes</Text>
                </CardHeader>
                <Divider />
                <CardBody>
                  {data?.data?.gazette?.slice(0, 5).map((item, index) => (
                    <Box display="flex" alignItems="center" key={index}>
                      <GiPlainCircle size={12} />
                      <Text
                        fontSize="sm"
                        fontWeight="light"
                        ml={2}
                        _hover={{
                          color: "mainBlue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => redirect(`/lawsandreg/law/${item._id}`)}
                      >
                        {item.title}
                      </Text>
                    </Box>
                  ))}
                </CardBody>
                <CardFooter>
                  <Button
                    color="mainBlue"
                    fontSize="sm"
                    variant="link"
                    rightIcon={<RightArrowIcon />}
                    onClick={() => redirect("/tags/gazzets")}
                    ml={2.5}
                  >
                    View More
                  </Button>
                </CardFooter>
              </Card>

              {/* Gazette section */}
              <Card>
                <CardHeader>
                  <TiNews size={32} color="mainBlue" />
                  <Text fontSize="xl">Gazette</Text>
                </CardHeader>
                <Divider />
                <CardBody>
                  {data?.data?.gazette?.slice(5, 11).map((item, index) => (
                    <Box display="flex" alignItems="center" key={index}>
                      <GiPlainCircle size={12} />
                      <Text
                        fontSize="sm"
                        fontWeight="light"
                        ml={2}
                        _hover={{
                          color: "mainBlue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => redirect(`/lawsandreg/law/${item._id}`)}
                      >
                        {item.title}
                      </Text>
                    </Box>
                  ))}
                </CardBody>
                <CardFooter>
                  <Button
                    color="mainBlue"
                    fontSize="sm"
                    variant="link"
                    rightIcon={<RightArrowIcon />}
                    onClick={() => redirect("/tags/gazzets")}
                    ml={2.5}
                  >
                    View More
                  </Button>
                </CardFooter>
              </Card>
            </SimpleGrid>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default GazzetsAndLaws;

const Card = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={2}
    boxShadow="lg"
    padding="2%"
    textAlign="justify"
    borderColor="mainBlue"
    height="auto"
    backgroundColor="lightIvory"
    mb={10}
  >
    {children}
  </Box>
);

const CardHeader = ({ children }) => (
  <Box
    display="flex"
    alignItems="center"
    gap={4}
    color="mainBlue 
    "
    padding="2%"
    rounded="lg"
  >
    {children}
  </Box>
);

const Divider = () => (
  <Box
    borderBottom="1px solid rgba(0, 113, 188, 0.5)"
    width="100%"
    my={2}
    borderBottomColor="mainBlue"
  />
);

const CardBody = ({ children }) => (
  <Box display="grid" gap={2} ml={2} color="mainBlue">
    {children}
  </Box>
);

const CardFooter = ({ children }) => <Box>{children}</Box>;
