import Layout from "../../../commonmodule/layouts/home";
import {
  Button,
  Image,
  SimpleGrid,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Card, CardBody, CardFooter } from "@chakra-ui/card";
import { axiosInstance as axios } from "../../../../lib/axios";
import { FiSearch } from "react-icons/fi";
import { config } from "../../../../utils/config";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Paginate } from "react-paginate-chakra-ui";

const AllCourtsPage = () => {
  const [courts, setCourts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);

  const pageSize = 4;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
  };

  const fetchCourts = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/courts/paginate?page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setCourts(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/courts/search/paginated/${search}?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setCourts(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/courts/paginate?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setCourts(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
    if (total <= pageSize) {
      console.log(total, pageSize);
      prevButton.disabled = true;
      nextButton.disabled = true;
    }
    fetchCourts(1);
  }, []);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setCourts(data.data);
      setTotal(currentTotal);
      return;
    }

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const res = await axios.get(
      `/courts/search/paginated/${e.target.value}?page=${page}&limit=${pageSize}`
    );

    setCourts(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  return (
    <Layout>
      <Box width={"100%"} mt={10}>
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"80%"}
          margin={"auto"}
          padding={"10px"}
          gap={4}
        >
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch />} />
              <Input placeholder="Search" onChange={handleSearch} />
            </InputGroup>
          </Box>
        </Box>
        <SimpleGrid
          columns={[1, 2, 3, 4]}
          spacing={6}
          width={"80%"}
          margin={"auto"}
        >
          {!loading && courts && courts.length > 0
            ? courts.map((item, index) => (
                <Card
                  key={index}
                  display={"flex"}
                  as={motion.div}
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 }}
                  flexDir={"column"}
                  gap={4}
                  borderRadius={"lg"}
                  boxShadow={"lg"}
                  padding={"4%"}
                  textAlign={"justify"}
                  border={"2px solid"}
                  borderColor={"mainBlue"}
                  cursor={"pointer"}
                  onClick={() => {
                    handleClick(`/court/${item._id}`);
                  }}
                >
                  <CardBody>
                    <Image
                      src={
                        item.imageUrl
                          ? config.baseURL + `/CaC/download/${item.imageUrl}`
                          : "https://via.placeholder.com/300.png?text=No+Image+Found"
                      }
                      alt="court"
                      width={"300%"}
                      rounded={"lg"}
                    />
                  </CardBody>
                  <CardFooter display={"flex"} flexDirection={"column"} gap={4}>
                    <Text fontSize={"xl"} fontWeight={"bold"}>
                      {item.name}
                    </Text>
                    <Text>{item.shortDescription}</Text>
                    <Button
                      bgColor={"mainBlue"}
                      width={"60%"}
                      color={"white"}
                      boxShadow={"dark-sm"}
                      rounded={"lg"}
                      _hover={{
                        bg: "lightBlue",
                        color: "mainBlue",
                        boxShadow: "dark-md",
                        border: "2px solid",
                        borderColor: "mainBlue",
                      }}
                    >
                      <Text>Read More</Text>
                    </Button>
                    <Text>
                      <b>Posted on {item.createdAt.split("T")[0]}</b>
                    </Text>
                  </CardFooter>
                </Card>
              ))
            : null}
        </SimpleGrid>
        <Box
          width={"80%"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paginate
            page={page - 1}
            count={total}
            pageSize={pageSize}
            colorScheme="blue"
            onPageChange={(_page) => {
              if (page === _page + 1) {
                return;
              }
              setPage(_page + 1);
              handlePageChange(pageSize, _page + 1);
            }}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default AllCourtsPage;
