import Layout from "../../../commonmodule/layouts/home";
import {
  Button,
  Image,
  SimpleGrid,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetch";
import { config } from "../../../../utils/config";
import { axiosInstance as axios } from "../../../../lib/axios";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Paginate } from "react-paginate-chakra-ui";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);

  const pageSize = 4;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchGallery = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/gallery/paginate?page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setGallery(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/gallery/search/paginated/${search}?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setGallery(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/gallery/paginate?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setGallery(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
    if (total <= pageSize) {
      console.log(total, pageSize);
      prevButton.disabled = true;
      nextButton.disabled = true;
    }

    fetchGallery(1);
  }, []);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setGallery(data.data);
      setTotal(currentTotal);
      return;
    }

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const res = await axios.get(
      `/gallery/search/paginated/${e.target.value}?page=${page}&limit=${pageSize}`
    );

    setGallery(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  const {
    data: { data: imageData },
  } = useFetch("/gallery/getall");

  return (
    <Layout>
      <Box
        display={"flex"}
        flexDir={"column"}
        mt={8}
        gap={4}
        borderRadius={"lg"}
        padding={"4%"}
        textAlign={"justify"}
      >
        <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
          Gallery
        </Text>

        <Box width={"15%"}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<FiSearch />} />
            <Input placeholder="Search" onChange={handleSearch} />
          </InputGroup>
        </Box>

        {/* 4 image in one line */}
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} gap={4}>
          {imageData &&
            imageData.length > 0 &&
            imageData.map((item) => (
              <Box>
                <Image
                  key={item._id}
                  src={config.baseURL + `/CaC/download/${item.imageLink}`}
                  alt={item.name}
                  width={"300px"}
                  height={"300px"}
                  rounded={"lg"}
                  objectFit={"cover"}
                  boxShadow={"lg"}
                />
                <Text fontSize={"sm"} marginY={2}>
                  {item.name}
                </Text>
              </Box>
            ))}
        </SimpleGrid>
        <Box
          width={"80%"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paginate
            page={page - 1}
            count={total}
            pageSize={pageSize}
            colorScheme="blue"
            onPageChange={(_page) => {
              if (page === _page + 1) {
                console.log(_page, "page");
                return;
              }
              setPage(_page + 1);
              handlePageChange(pageSize, _page + 1);
            }}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Gallery;
