import React from "react";
import { Container, Box, Text } from "@chakra-ui/react";
import "../../../styles/editor.scss";
import useFetch from "../../../hooks/useFetch";
import Layout from "../../commonmodule/layouts/home";

const PrivacyPolicy = () => {
  const { data, loading } = useFetch("/ppolicy/getall");

  const convertToHTML = (text) => {
    const html = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    return html;
  };

  return (
    <Layout>
      <Box mt={10}>
        {!loading && data.data && (
          <Container maxW={"80%"}>
            {/* dad */}
            <Text as="h1" fontSize="2xl" fontWeight="bold" mb={4}>
              {data.data.title}
            </Text>
            <Text as="p" fontSize="md" mb={4} fontWeight={"normal"}>
              {/* inject html */}
              <span
                dangerouslySetInnerHTML={{
                  __html: convertToHTML(data.data.description),
                }}
              />
            </Text>
          </Container>
        )}
      </Box>
    </Layout>
  );
};

export default PrivacyPolicy;
