import Layout from "../../../commonmodule/layouts/home";
import {
  Button,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { axiosInstance as axios } from "../../../../lib/axios";
import { FiSearch } from "react-icons/fi";
import { config } from "../../../../utils/config";
import { useEffect, useState } from "react";
import { Paginate } from "react-paginate-chakra-ui";

const DraftingNotes = () => {
  const [drafting, setDrafting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);

  const pageSize = 8;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchBooks = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/drafting/pagination?page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setDrafting(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
    if (total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    }

    if (data.length === 0) return;
    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/drafting/search/paginated/${search}?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setDrafting(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/drafting/pagination?page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setDrafting(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  const handleClick = (path) => {
    window.open(path, "_blank");
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else if (total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    fetchBooks(1);
  }, []);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setDrafting(data.data);
      setTotal(currentTotal);
      return;
    }

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const res = await axios.get(
      `/drafting/search/paginated/${e.target.value}?page=${page}&limit=${pageSize}`
    );

    setDrafting(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  const downloadFile = (key) => {
    if (!key) return;
    window.open(`${config.baseURL}/CaC/download/${key}`, "_blank");
  };

  return (
    <Layout>
      <Box width={"100%"} mt={10}>
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"80%"}
          margin={"auto"}
          padding={"10px"}
          gap={4}
        >
          <Box width={"27%"}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch />} />
              <Input placeholder="Search" onChange={handleSearch} />
            </InputGroup>
          </Box>
        </Box>

        <div>
          <Table
            variant="striped"
            colorScheme="gray"
            width={"80%"}
            margin={"auto"}
          >
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loading && drafting && drafting.length > 0 ? (
                drafting.map((item, index) => (
                  <Tr key={item.id}>
                    <Td>{item.name}</Td>
                    <Td>
                      <Button
                        bgColor={"mainBlue"}
                        color={"white"}
                        _hover={{
                          bg: "lightBlue",
                          color: "mainBlue",
                          boxShadow: "dark-md",
                          border: "2px solid",
                          borderColor: "mainBlue",
                        }}
                        onClick={() => handleClick(item.url)}
                      >
                        <Text>Visit</Text>
                      </Button>
                    </Td>
                    <Text color={"white"} userSelect="none">
                      <b>Posted on {item.createdAt.split("T")[0]}</b>
                    </Text>
                  </Tr>
                ))
              ) : (
                <Text>No drafting found</Text>
              )}
            </Tbody>
          </Table>
        </div>

        <Box
          pr={"40%"}
          width={"80%"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paginate
            page={page - 1}
            count={total}
            pageSize={pageSize}
            colorScheme="blue"
            onPageChange={(_page) => {
              if (page === _page + 1) {
                return;
              }
              setPage(_page + 1);
              handlePageChange(pageSize, _page + 1);
            }}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default DraftingNotes;
