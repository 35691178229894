import { Box } from "@chakra-ui/layout";
import Navbar from "../../commonmodule/components/Navebar";
import { useState } from "react";
import { useScroll, motion } from "framer-motion";

const Main = ({ children, title }) => {
  const [scroll] = useState(false);

  return (
    <Box as="main" fontFamily="base" w="100%" h="100%">
      <Box
        as={motion.div}
        position="sticky"
        top="0"
        width="100%"
        margin="auto"
        zIndex="50"
        bg="white"
        shadow="md"
      >
        <Navbar scroll={scroll} />
      </Box>
      <Box as="section" width="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};

export default Main;
