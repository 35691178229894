import React, { useState, useEffect } from "react";
import { Box, Button, VStack, Select, Text } from "@chakra-ui/react";
import { MultiSelect } from "primereact/multiselect";
import { useParams } from "react-router-dom";
function SubscriptionPlanForms() {
  const { name } = useParams();

  const countryOptions = [
    // { value: "all", label: "Countries" },
    { value: "India", label: "India" },
    { value: "UAE", label: "UAE" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Kuwait", label: "Kuwait" },
  ];

  const moduleOptions = [
    // { value: "all", label: "Modules" },
    { value: "Module1", label: "Module1" },
    { value: "Module2", label: "Module2" },
    { value: "Module3", label: "Module3" },
    { value: "Module4", label: "Module4" },
    { value: "Module5", label: "Module5" },
    { value: "Module6", label: "Module6" },
    { value: "Module7", label: "Module7" },
    { value: "Module8", label: "Module8" },
  ];

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);

  const [maxSelections, setMaxSelections] = useState(0);

  useEffect(() => {
    const lowercaseName = name.toLowerCase();

    if (lowercaseName === "free") {
      setMaxSelections(2);
    } else if (lowercaseName === "basic") {
      setMaxSelections(4);
    } else if (lowercaseName === "premium") {
      setMaxSelections(6);
    } else if (lowercaseName === "custom") {
      setMaxSelections(10);
    }
  }, [name]);

  const handleCountryChange = (event) => {
    if (selectedCountries.length < maxSelections) {
      setSelectedCountries([...selectedCountries, event.target.value]);
    }
  };

  const handleModuleChange = (event) => {
    if (selectedModules.length < maxSelections) {
      setSelectedModules([...selectedModules, event.target.value]);
    }
  };

  const handleConfirm = () => {
    console.log("clicked");
    // Check if a country is selected
    if (selectedCountries.length === 0) {
      alert("Please select at least one country.");
      return;
    } else if (name === "Basic" && selectedCountries.length > 2) {
      alert("Can't select more than 2 countries.");
      return;
    } else if (name === "Premium" && selectedCountries.length > 3) {
      alert("Can't select more than 3 countries.");
      return;
    }
    // Check if modules are selected
    if (selectedModules.length === 0) {
      alert("Please select at least one module.");
      return;
    } else if (name === "Basic" && selectedModules.length > 4) {
      alert("Can't select more than 4 modules.");
      return;
    } else if (name === "Premium" && selectedModules.length > 6) {
      alert("Can't select more than 6 modules.");
      return;
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <Box className="bg-gray-100 p-4 rounded-lg shadow-md bg-gradient-to-r from-blue-100 via-blue-200 to-blue-300">
        <Text textAlign={"center"} p={4} fontSize={"2xl"}>
          Plans especially curated for you,
          <br /> Customize your plan according to your needs:
        </Text>
        {name === "Free" && (
          <Box className="flex justify-center items-center">
            <Box p={5} borderRadius="lg" boxShadow="md" maxWidth="md">
              <VStack>
                <Text fontSize="2xl">FREE PLAN</Text>
                <Text>This is the free plan with limited features.</Text>
                <div className="bg-blue-200 text-blue-800 font-bold rounded-md p-2 my-2 text-xl">
                  Price: $1/mo
                </div>

                <Select
                  style={{
                    marginTop: "10px",
                    height: "50px",
                    backgroundColor: "white",
                  }}
                  placeholder="Select country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  isDisabled={countryOptions.length === 0}
                >
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </Select>
                <Select
                  style={{
                    marginTop: "10px",
                    height: "50px",
                    backgroundColor: "white",
                  }}
                  placeholder="Select modules"
                  value={selectedModule}
                  onChange={handleModuleChange}
                  isDisabled={moduleOptions.length === 0}
                >
                  {moduleOptions.map((module) => (
                    <option key={module.value} value={module.value}>
                      {module.label}
                    </option>
                  ))}
                </Select>
                <Button
                  colorScheme="red"
                  style={{
                    borderRadius: "1.2rem",
                    padding: "0.75rem 2rem",
                    marginTop: "10px",
                  }}
                  onClick={handleConfirm}
                >
                  Continue
                </Button>
              </VStack>
            </Box>
          </Box>
        )}

        {/* basic plan */}
        {name === "Basic" && (
          <Box className="flex justify-center items-center">
            <Box p={5} borderRadius="lg" boxShadow="md" maxWidth="xs">
              <VStack>
                <Text fontSize="2xl">BASIC PLAN</Text>
                <Text>
                  This is the basic plan with limited features.You can select
                  any 2 countries and 4 modules.
                </Text>
                <div className="bg-blue-200 text-blue-800 font-bold rounded-md p-2 my-2 text-xl">
                  Price: $5/mo
                </div>
                <MultiSelect
                  style={{
                    marginTop: "10px",
                    height: "50px",
                  }}
                  placeholder="Select countries"
                  value={selectedCountries}
                  options={countryOptions}
                  isDisabled={
                    countryOptions.length === 0 ||
                    selectedCountries.length >= maxSelections
                  }
                  onChange={(e) => setSelectedCountries(e.value)}
                />
                <MultiSelect
                  style={{
                    marginTop: "10px",
                    height: "50px",
                  }}
                  placeholder="Select modules"
                  value={selectedModules}
                  options={moduleOptions}
                  onChange={(e) => setSelectedModules(e.value)}
                />
                <Button
                  colorScheme="red"
                  style={{
                    borderRadius: "1.2rem",
                    padding: "0.75rem 2rem",
                    marginTop: "10px",
                  }}
                  onClick={handleConfirm}
                >
                  Continue
                </Button>
              </VStack>
            </Box>
          </Box>
        )}
        {/* premium plan */}
        {name === "Premium" && (
          <Box className="flex justify-center items-center">
            <Box p={5} borderRadius="lg" boxShadow="md" maxWidth="xs">
              <VStack>
                <Text fontSize="2xl">PREMIUM PLAN</Text>
                <Text>
                  This is the premium plan.You can select any 4 countries and 6
                  modules.
                </Text>
                <div className="bg-blue-200 text-blue-800 font-bold rounded-md p-2 my-2 text-xl">
                  Price: $10/mo
                </div>
                <MultiSelect
                  style={{
                    marginTop: "10px",
                    height: "50px",
                  }}
                  placeholder="Select countries"
                  value={selectedCountries}
                  options={countryOptions}
                  onChange={(e) => setSelectedCountries(e.value)}
                />
                <MultiSelect
                  style={{
                    marginTop: "10px",
                    height: "50px",
                  }}
                  placeholder="Select modules"
                  value={selectedModules}
                  options={moduleOptions}
                  onChange={(e) => setSelectedModules(e.value)}
                />
                <Button
                  colorScheme="red"
                  style={{
                    borderRadius: "1.2rem",
                    padding: "0.75rem 2rem",
                    marginTop: "10px",
                  }}
                  onClick={handleConfirm}
                >
                  Continue
                </Button>
              </VStack>
            </Box>
          </Box>
        )}

        {/* custom plan */}
        {name === "Custom" && (
          <Box className="mt-4 flex justify-center items-center">
            <Box p={5} borderRadius="lg" boxShadow="md" maxWidth="xs">
              <VStack>
                <Text fontSize="2xl">CUSTOM PLAN</Text>
                <Text>
                  This is the custom plan with unlimited options.You can select
                  any number of countries and modules.
                </Text>
                <div className="bg-blue-200 text-blue-800 font-bold rounded-md p-2 my-2 text-xl">
                  Price: $25/mo
                </div>
                <MultiSelect
                  style={{
                    marginTop: "10px",
                    height: "50px",
                  }}
                  placeholder="Select countries"
                  value={selectedCountries}
                  options={countryOptions}
                  onChange={(e) => setSelectedCountries(e.value)}
                />
                <MultiSelect
                  style={{
                    marginTop: "10px",
                    height: "50px",
                  }}
                  placeholder="Select modules"
                  value={selectedModules}
                  options={moduleOptions}
                  onChange={(e) => setSelectedModules(e.value)}
                />
                <Button
                  colorScheme="red"
                  style={{
                    borderRadius: "1.2rem",
                    padding: "0.75rem 2rem",
                    marginTop: "10px",
                  }}
                  onClick={handleConfirm}
                >
                  Continue
                </Button>
              </VStack>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default SubscriptionPlanForms;
