import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
} from "@chakra-ui/react";
import React from "react";
import useFetch from "../../../hooks/useFetch";
import Layout from "../../commonmodule/layouts/home";
const FAQ = () => {
  const { data, loading } = useFetch("/faqs/getall");

  return (
    <Layout>
      <Box mt={10}>
        {!loading && data.data && (
          <Container maxW={"70%"}>
            <Accordion allowMultiple>
              {data.data.map((faq, i) => (
                <AccordionItem key={faq._id} pb={6}>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "tomato", color: "white" }}
                    >
                      <Box flex="1" textAlign="left" fontSize={"lg"}>
                        {i + 1}. {faq.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Container>
        )}
      </Box>
    </Layout>
  );
};

export default FAQ;
