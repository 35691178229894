import Layout from "../../../commonmodule/layouts/home";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetch";
import { config } from "../../../../utils/config";
import { useParams } from "react-router-dom";

const SingleResource = () => {
  const { id } = useParams();
  const { data, loading } = useFetch("/resources/single/" + id);

  return (
    <Layout>
      {!loading && data.data && data.data ? (
        <Box
          display={"flex"}
          flexDir={"column"}
          mt={8}
          gap={4}
          borderRadius={"lg"}
          padding={"4%"}
          textAlign={"justify"}
        >
          <Box as={"span"} display={"flex"} alignItems={"center"} gap={4}>
            <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
              {data.data.name}
            </Text>
          </Box>
          <Box>
            <Image
              src={config.baseURL + `/CaC/download/${data.data.image}`}
              alt="court"
              width={"300px"}
              rounded={"lg"}
              boxShadow={"lg"}
            />
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={4} width={"70%"}>
            <Text>{data.data.shortdescription}</Text>
          </Box>
          <Box display={"flex"} gap={4}>
            <Button
              onClick={() => {
                window.open(data.data.url);
              }}
              color={"white"}
              bgColor={"mainBlue"}
              boxShadow={"dark-sm"}
              rounded={"lg"}
              _hover={{
                bg: "lightBlue",
                color: "mainBlue",
                boxShadow: "dark-md",
                borderColor: "mainBlue",
                borderWidth: "2px",
              }}
            >
              Visit
            </Button>

            <Button
              onClick={() => window.history.back()}
              colorScheme={"red"}
              color={"white"}
              boxShadow={"dark-sm"}
              rounded={"lg"}
              _hover={{
                bg: "lightBlue",
                color: "mainBlue",
                boxShadow: "dark-md",
                borderColor: "mainBlue",
                borderWidth: "2px",
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      ) : (
        <Text>No data</Text>
      )}
    </Layout>
  );
};

export default SingleResource;
