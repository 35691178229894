import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const styles = {
  global: (props) => ({
    body: {
      bg: mode("#FDFEFF", "#D3C4D1")(props),
      color: "#000000",
      fontWeight: "bold",
      textSizeAdjust: "100%",
    },
  }),
  option: {
    background: "red",
    _hover: {
      bg: "gray.100",
      shadow: "lg",
    },
  },
  optgroup: {
    _hover: {
      bg: "gray.100",
      shadow: "lg",
    },
  },
};

const components = {
  Heading: {
    baseStyle: (props) => ({
      FontFamily: "Secular One",
    }),
    variants: {
      "section-title": {
        textDecoration: "underline",
        fontSize: 20,
        textUnderlineOffset: 6,
        textDecorationColor: "#525252",
        textDecorationThickness: 4,
        marginTop: 3,
        marginBottom: 4,
      },
    },
  },
  Box: {
    baseStyle: (props) => ({
      backgroundColor: mode("#FDFEFF", "#000000")(props),
    }),
  },

  Link: {
    baseStyle: (props) => ({
      color: mode("#3d7aed", "#ff63c3")(props),
      textUnderlineOffset: 3,
    }),
  },

  Input: {
    _focusVisible: {
      boxShadow: "none",
      outline: "none",
    },
    baseStyle: (props) => ({
      color: mode("#000000", "#ffffff")(props),
    }),
  },

  Select: {
    baseStyle: (props) => ({
      color: mode("#000000", "#ffffff")(props),
      fontWeight: "bold",
    }),
  },
};

const fonts = {
  base: "'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
  heading:
    "'Secular One', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
  body: "'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
};

const colors = {
  grassTeal: "#88ccca",
  mainBlue: "#002855",
  lightBlue: "#DEECF8",
  midBlue: "#00438F",
  darkGray: "#ffffff25",
  lightWhite: "#f5f5f5",
  darkWhite: "#ececec",
  niceVoilet: "#5F30E2",
  blueMain: {
    100: "#002855",
    200: "#003c7e",
    300: "#0051a7",
    400: "#0066d0",
  },
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

const theme = extendTheme({ config, styles, components, fonts, colors });
export default theme;
