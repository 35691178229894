import Layout from "../../commonmodule/layouts/home";
import {
  Box,
  Button,
  Container,
  SimpleGrid,
  Tag,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import useFetch from "../../../hooks/useFetch";
import { config } from "../../../utils/config";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const SingleCasePage = () => {
  const params = useParams();
  const { data, loading } = useFetch("/judgementandcase/getbyid/" + params.id);

  const toast = useToast();

  const convertHtmltoText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.body;
    const text = body.textContent || "";
    return text;
  };

  useEffect(() => {
    const copyToClipboard = (e) => {
      if (e.ctrlKey && e.key === "c") {
        const selection = window.getSelection();
        const text = convertHtmltoText(selection);
        let newText = "";
        // remove all the extra new lines
        text.split("\n").forEach((line) => {
          if (line.trim() !== "") {
            newText += line.trim() + "\n";
          }
        });

        navigator.clipboard.writeText(convertHtmltoText(newText));
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    };
    document.addEventListener("keydown", copyToClipboard);
    console.log(data);
    return () => {
      document.removeEventListener("keydown", copyToClipboard);
    };
  }, [params]);

  return (
    <Layout>
      <Container maxW={"70%"}>
        {!loading && data.data && data.data ? (
          <>
            <Box
              display={"flex"}
              flexDir={"column"}
              mt={8}
              gap={4}
              borderRadius={"lg"}
              padding={"4%"}
              textAlign={"justify"}
            >
              <Box as={"span"} display={"flex"} alignItems={"center"} gap={4}>
                <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
                  {data.data.name}
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                width={"70%"}
              >
                <Text>{data.data.description}</Text>
              </Box>
              <Box color={"black.500"} bg={"gray.200"} padding={"8"}>
                <span
                  className="doc-content"
                  dangerouslySetInnerHTML={{ __html: data.data.docContent }}
                />
              </Box>{" "}
              <Box display={"flex"} gap={4}>
                <Button
                  onClick={() => {
                    window.open(
                      config.baseURL +
                        `/CaC/download/${data.data.document_name}`
                    );
                  }}
                  color={"white"}
                  bgColor={"mainBlue"}
                  boxShadow={"dark-sm"}
                  rounded={"lg"}
                  _hover={{
                    bg: "lightBlue",
                    color: "mainBlue",
                    boxShadow: "dark-md",
                    borderColor: "mainBlue",
                    borderWidth: "2px",
                  }}
                >
                  Download
                </Button>

                <Button
                  onClick={() => window.history.back()}
                  colorScheme={"red"}
                  color={"white"}
                  boxShadow={"dark-sm"}
                  rounded={"lg"}
                  _hover={{
                    bg: "lightBlue",
                    color: "mainBlue",
                    boxShadow: "dark-md",
                    borderColor: "mainBlue",
                    borderWidth: "2px",
                  }}
                >
                  Go Back
                </Button>
              </Box>
            </Box>

            {/* similar cases */}
            <Box
              display={"flex"}
              flexDir={"column"}
              gap={4}
              borderRadius={"lg"}
              textAlign={"justify"}
            >
              <Box as={"span"} display={"flex"} alignItems={"center"} gap={4}>
                <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
                  Similar Cases
                </Text>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                {data.data.similar.length > 0 ? (
                  <SimpleGrid
                    columns={2}
                    spacing={4}
                    rounded={"md"}
                    boxShadow={"sm"}
                  >
                    {data.data.similar.map((item) => (
                      <Tooltip
                        key={item._id}
                        label={item.description}
                        hasArrow={"true"}
                        placement={"right-end"}
                      >
                        <Box
                          padding={6}
                          bg={"gray.100"}
                          rounded={"md"}
                          shadow={"md"}
                          minW={"500px"}
                          height={"30px"}
                          cursor={"pointer"}
                          group={"true"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          onClick={() => {
                            window.location.href = `/case/${item._id}`;
                          }}
                          _hover={{
                            bg: "whiteAlpha.200",
                          }}
                        >
                          <Text
                            as={"h2"}
                            _groupHover={{
                              color: "blue.500",
                            }}
                          >
                            {item.name}
                          </Text>
                          <Tag size={"sm"} colorScheme={"linkedin"}>
                            {item.searchCount}
                          </Tag>
                        </Box>
                      </Tooltip>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Text>No similar cases found</Text>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Text>No data</Text>
        )}
      </Container>
    </Layout>
  );
};

export default SingleCasePage;
