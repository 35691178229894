import React, { useEffect, useState } from "react";
import Layout from "../../commonmodule/layouts/home";
import { axiosInstance as axios } from "../../../lib/axios";
import {
  Button,
  Flex,
  Text,
  Box,
  useToast,
  Tag,
  Tooltip,
  Select,
  Input,
} from "@chakra-ui/react";
import { config } from "../../../utils/config";
import { useLocation } from "react-router";
import { HashLoader } from "react-spinners";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import PDFViewer from "../../../components/preview-pdf";

const fetchCountries = async () => {
  const res = await axios.get("/countries");
  if (res.data.ok) {
    return res.data.data;
  }
  return [];
};

const fetchStatesByCountry = async (country) => {
  const res = await axios.get(`/states/${country}`);
  if (res.data.ok) {
    return res.data.data;
  }
  return [];
};

const types = [
  { label: "Contract", value: "contract" },
  { label: "Clause", value: "clause" },
  { label: "Law", value: "law" },
  { label: "Regulatory", value: "regulation" },
  { label: "Case", value: "case" },
  { label: "Judgement", value: "judgement" },
];

const GlobalSearchResults = () => {
  const [editorText, setEditorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchResult, setSearchResult] = useState({});
  const [addedtoFav, setAddedtoFav] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [filters, setFilters] = useState({
    type: "all",
    country: "all",
    state: "all",
    court: "all",
  });
  const [activeFilters, setActiveFilters] = useState({
    countries: [],
    states: [],
    courts: [],
  });
  const [searchValue, setSearchValue] = useState("");

  const toast = useToast();
  const location = useLocation();

  const highlightKeyword = (html, keyword, word) => {
    const regex = new RegExp(keyword, "gi");
    const highlightedHtml = html.replace(regex, `<mark>${keyword}</mark>`);

    if (word === "content") {
      const highlightedHtmlWithP = highlightedHtml.replace(
        /<li>/gi,
        '<p class="counter-add">'
      );
      const highlightedHtmlWithP2 = highlightedHtmlWithP.replace(
        /<\/li>/gi,
        "</p>"
      );

      // create a new div element
      const div = document.createElement("div");
      div.innerHTML = highlightedHtmlWithP2;
      const pTagsol = div.querySelectorAll("ol p.counter-add");
      const pTagsul = div.querySelectorAll("ul p.counter-add");

      if (pTagsol.length > 0) {
        pTagsol.forEach((p, index) => {
          p.innerHTML = `<span class="counter">${index + 1}. </span>${
            p.innerHTML
          }`;
        });
      }

      if (pTagsul.length > 0) {
        pTagsul.forEach((p, index) => {
          p.innerHTML = `<span class="counter">• </span>${p.innerHTML}`;
        });
      }

      return div.innerHTML;
    }

    return highlightedHtml;
  };

  const downloadFile = async (key, path) => {
    if (key.startsWith("http")) {
      window.open(key, "_blank");
      return;
    }

    if (!key) return;

    let fileurl = `${config.documentDownloadURL}/?key=${key}`;
    if (path) {
      fileurl += `&folder=${path}`;
    }
    window.open(fileurl, "_blank");
  };

  const convertHtmltoText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.body;
    const text = body.textContent || "";
    return text;
  };

  const doGlobalSearch = async () => {
    if (searchValue === "") {
      setSearchResult([]);
      return;
    }

    if (searchValue.length < 3) return;

    const country =
      filters.country !== "all"
        ? filters.country.map((e) => e.value).join(",")
        : "all";
    const state =
      filters.state !== "all"
        ? filters.state.map((e) => e.value).join(",")
        : "all";
    const court =
      filters.court !== "all"
        ? filters.court.map((e) => e.value).join(",")
        : "all";
    const type =
      filters.type !== "all"
        ? filters.type.map((e) => e.value).join(",")
        : "all";

    const _res = await axios.get(
      `/search?searchWord=${searchValue.trim()}&type=${type}&country=${country}&state=${state}&court=${court}`
    );

    if (_res.data.ok) {
      setSearchResult(_res.data.data.slice(0, 5));
      console.log(_res.data.data);
    } else setSearchResult([]);
  };

  const isPdf = (url) => {
    return url.split(".").pop() === "pdf";
  };

  useEffect(() => {
    const id = location.pathname.split("/")[3];
    const type = location.pathname.split("/")[4];
    const word = location.pathname.split("/")[5];

    // sanitize the word
    const regex = new RegExp("%20", "gi");

    setSearchTerm(word.replace(regex, " "));

    const fetchData = async () => {
      if (!id || !type || !word) return;
      const _res = await axios.get(
        `/search/find?id=${id}&type=${type}&word=${word}`
      );

      if (_res.data.ok) {
        setLoading(false);
        const data = _res.data.data;
        console.log(data);
        setEditorText(
          data.docContent
            ? highlightKeyword(data.docContent, word, "content")
            : ""
        );
        data.name = highlightKeyword(data.name, word, "name");
        data.description = highlightKeyword(data.description, word, "desc");

        data.description = data.description.replace(/\./g, ".<br/>");

        setSearchResult(data);
      } else {
        setLoading(true);
        _res.data.message &&
          toast({
            title: _res.data.message,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        setSearchResult({});
        setTimeout(() => {
          window.location.href = "/search";
        }, 2100);
      }
    };

    fetchData();

    const copyToClipboard = (e) => {
      if (e.ctrlKey && e.key === "c") {
        const selection = window.getSelection();
        const text = convertHtmltoText(selection);
        let newText = "";
        // remove all the extra new lines
        text.split("\n").forEach((line) => {
          if (line.trim() !== "") {
            newText += line.trim() + "\n";
          }
        });

        navigator.clipboard.writeText(convertHtmltoText(newText));
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    fetchCountries().then((res) => {
      setCountries(res);
    });

    document.addEventListener("keydown", copyToClipboard);
    return () => {
      document.removeEventListener("keydown", copyToClipboard);
    };
  }, []);

  if (loading)
    return (
      <Box id="loading" className="loader hidden">
        <HashLoader className="loading-bar" color="#36d7b7" />
      </Box>
    );

  return (
    !loading && (
      <Layout title={"Search Results"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={"center"}
          px={4}
          maxW={"7xl"}
          mx={"auto"}
        >
          <Box
            as="aside"
            width={{
              base: "100%",
              md: "35%",
            }}
          >
            <Flex
              direction={"column"}
              justifyContent={"center"}
              mt={10}
              mb={4}
              rounded={"md"}
              boxShadow={"md"}
              borderColor={"gray.200"}
              borderWidth={2}
            >
              <Box
                as="button"
                bgColor={"#002855"}
                width={"100%"}
                color={"#fff"}
                py={3}
                borderRadius={"5px 5px 0 0"}
              >
                <Text fontSize={"2xl"}>Filter & Search</Text>
              </Box>
              <Box p={"2.5"}>
                <Box
                  mb={2}
                  bgColor={"#f5f5f5"}
                  borderRadius={"5px"}
                  width={"50%"}
                  p={2}
                  whiteSpace={"nowrap"}
                >
                  <Text fontSize={"sm"} fontWeight={"normal"}>
                    Searched term :{" "}
                    <Text as="span" fontWeight={"bold"}>
                      {searchTerm}
                    </Text>
                  </Text>
                </Box>
                <Box>
                  {countries && countries.length > 0 && (
                    <Select
                      placeholder="Select country"
                      onChange={(e) => {
                        console.log(e.target.value);
                        if (!e.target.value) return setStates([]);
                        fetchStatesByCountry(e.target.value).then((res) => {
                          setStates(res);
                        });
                      }}
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </Box>
                <Box mt={4}>
                  <Select placeholder="Select type">
                    {types.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box mt={4}>
                  <Select
                    placeholder="Select State"
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                  >
                    {states &&
                      states.length > 0 &&
                      states.map((state, index) => (
                        <option key={index} value={state._id}>
                          {state.name}
                        </option>
                      ))}
                  </Select>
                </Box>
                <Box mt={4}>
                  <Input
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </Box>
                <Button
                  mt={4}
                  bgColor={"#002855"}
                  width={"100%"}
                  color={"#fff"}
                  py={3}
                  borderRadius={"5px"}
                  onClick={() => {
                    doGlobalSearch();
                  }}
                >
                  <Text fontSize={"2xl"}>Search</Text>
                </Button>
              </Box>
            </Flex>

            <Flex
              direction={"column"}
              justifyContent={"center"}
              mt={10}
              mb={4}
              rounded={"md"}
              boxShadow={"md"}
            >
              <Box
                as="button"
                bgColor={"#002855"}
                width={"100%"}
                color={"#fff"}
                py={3}
                borderRadius={"5px 5px 0 0"}
              >
                <Text fontSize={"2xl"}>Related</Text>
              </Box>
              <Box p={"2.5"}>
                <Box>
                  {searchResult.similar &&
                    searchResult.similar.length > 0 &&
                    searchResult.similar.map((similar, index) => (
                      <Link to={`/cac/${similar.type}/${similar._id}`}>
                        {similar.name}
                      </Link>
                    ))}
                </Box>
              </Box>
            </Flex>
          </Box>

          <Flex
            direction={"column"}
            justifyContent={"center"}
            width={{
              base: "100%",
              md: "65%",
            }}
            height={"100%"}
            mt={10}
            ml={{
              base: 0,
              md: 4,
            }}
            mb={4}
            padding={8}
            rounded={"lg"}
            boxShadow={"md"}
            borderColor={"gray.200"}
            borderWidth={2}
          >
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text fontSize={"2xl"}>
                {
                  <span
                    dangerouslySetInnerHTML={{ __html: searchResult.name }}
                  />
                }
              </Text>{" "}
              <Text fontSize={"lg"} color={"gray.500"}>
                {searchResult.type}
              </Text>
            </Flex>
            <Text fontSize={"lg"} color={"black.500"}>
              <span
                dangerouslySetInnerHTML={{
                  __html: searchResult.description,
                }}
              />
            </Text>{" "}
            <br />
            <Box
              color={"black.500"}
              bg={"gray.200"}
              maxH={"90vh"}
              overflow={"auto"}
              className=""
            >
              <span
                className="doc-content"
                dangerouslySetInnerHTML={{ __html: editorText }}
              />
              {!editorText && searchResult.doc && isPdf(searchResult.doc) ? (
                <PDFViewer
                  url={`${config.documentDownloadURL}/?key=${searchResult.doc}&folder=${searchResult.doc_path}`}
                  searchText={searchTerm}
                />
              ) : null}
            </Box>{" "}
            <Flex justifyContent={"space-between"} alignItems={"end"}>
              <Button
                type="button"
                colorScheme={"facebook"}
                width={"100px"}
                mt={4}
                onClick={() =>
                  downloadFile(
                    searchResult.doc
                      ? searchResult.doc
                      : searchResult.document_name,
                    searchResult.doc_path
                  )
                }
              >
                Download
              </Button>

              <Tooltip
                label={
                  addedtoFav ? "Remove from favorites" : "Add to favorites"
                }
                hasArrow={"true"}
              >
                <Tag
                  colorScheme={"linkedin"}
                  size={"md"}
                  cursor={"pointer"}
                  _hover={{
                    color: "red",
                  }}
                >
                  {addedtoFav ? (
                    <AiFillStar onClick={() => setAddedtoFav(false)} />
                  ) : (
                    <AiOutlineStar onClick={() => setAddedtoFav(true)} />
                  )}
                </Tag>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      </Layout>
    )
  );
};

export default GlobalSearchResults;
