import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { countriesData } from "../../../data/courts-by-country";

const HighCourtsList = () => {
  const navigate = useNavigate();
  const currCountry = sessionStorage.getItem("country") || "Saudi Arabia";
  const curr_country = countriesData.find(
    (c) => c.name.toLowerCase() === currCountry.toLowerCase()
  );

  const redirect = (link) => {
    navigate(link);
  };

  return (
    <div>
      <Box>
        <Text
          fontWeight="bold"
          my={4}
          fontSize={{
            base: "2xl",
            md: "3xl",
          }}
          fontFamily={"heading"}
          className="text-blue-500"
        >
          High Courts List
        </Text>
      </Box>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 list-none">
        {curr_country.courts.map((court, index) => (
          <li
            key={index}
            className="bg-gray-200 rounded-lg p-4 hover:bg-blue-200 hover:text-blue-900 hover:shadow-md transition duration-300 cursor-pointer"
            onClick={() =>
              navigate(
                "/courts-by-country/info/" + currCountry + "/" + court.url
              )
            }
          >
            <Text
              key={index}
              className="text-lg font-semibold mb-2 text-gray-800"
            >
              {court.name}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HighCourtsList;
