import SignIn from "../components/Signin";
import SignUp from "../components/Signup";
import { useLocation } from "react-router";

export default function AuthComponent() {
  const location = useLocation();

  if (location.pathname === "/auth/user/signin")
    return <SignIn role={"user"} />;
  if (location.pathname === "/auth/user/signup")
    return <SignUp role={"user"} />;
}
