export const countriesData = [
  {
    name: "India",
    key: "india",
    courts: [
      {
        url: "allahabad-high-court",
        name: "Allahabad High Court",
        links: [
          {
            name: "",
            links: [{}],
          },
        ],
      },
      {
        url: "andhra-pradesh-high-court",
        name: "Andhra Pradesh High Court",
        primary: true,
        links: [
          {
            name: "General",
            links: [
              {
                name: "e-committee",
                url: "https://ecommitteesci.gov.in/",
              },
              {
                name: "NJDG",
                url: "https://njdg.ecourts.gov.in/njdgnew/index.php",
              },
              {
                name: "eSCR",
                url: "https://judgments.ecourts.gov.in/pdfsearch/?app_token=",
              },
              {
                name: "NJDG",
                url: "https://njdg.ecourts.gov.in/njdgnew/index.php",
              },
              {
                name: "Neutral Citation",
                url: "http://117.200.63.135/citation/",
              },
              {
                name: "Recruitment",
                url: "https://aphc.gov.in/recruitment.html",
              },
              ,
              {
                name: "E Pass",
                url: "http://117.200.63.133/visitorpass/",
              },
            ],
          },
          {
            name: "Important Links",
            links: [
              {
                name: "Live Streaming",
                url: "https://www.youtube.com/@highcourtofandhrapradesh5296",
              },
              {
                name: "RIT Portal",
                url: "https://aphc.gov.in/rti_act.html",
              },
              {
                name: "Calendars",
                url: "https://aphc.gov.in/calendars.html",
              },
              {
                name: "Tenders",
                url: "https://aphc.gov.in/tenders.html",
              },
            ],
          },
        ],
      },
      {
        url: "bombay-high-court",
        name: "Bombay High Court",
        links: [
          {
            name: "General",
            links: [
              {
                name: "Present Judges",
                url: "https://bombayhighcourt.nic.in/jshow.php",
              },
              {
                name: "Commercial Courts",
                url: "https://bombayhighcourt.nic.in/bhc_commercialcourts/index.php",
              },
              {
                name: "Recent Judgments",
                url: "https://bombayhighcourt.nic.in/recentorderjudgment.php",
              },
              {
                name: "Latest Update",
                url: "https://bombayhighcourt.nic.in/latestupdate.php",
              },
            ],
          },
          {
            name: "Case Status",
            links: [
              {
                name: "Case Number Wise",
                url: "https://bombayhighcourt.nic.in/case_query.php",
              },
              {
                name: "Advocate Name Wise",
                url: "https://bombayhighcourt.nic.in/adv_query.php",
              },
              {
                name: "Party Wise",
                url: "https://bombayhighcourt.nic.in/party_query.php",
              },
              {
                name: "CNR/CIN No. Wise",
                url: "https://bombayhighcourt.nic.in/cin_query.php",
              },
              ,
              {
                name: "eFilling No. Wise",
                url: "https://bombayhighcourt.nic.in/efile_query.php",
              },
              {
                name: "Neutral Citation Wise",
                url: "https://bombayhighcourt.nic.in/nc_query.php",
              },
              {
                name: "Know CIN/CNR",
                url: "https://bombayhighcourt.nic.in/cin.php",
              },
            ],
          },
        ],
      },
      {
        url: "calcutta-high-court",
        name: "Calcutta High Court",
        links: [
          {
            name: "General",
            links: [
              {
                name: "WB e-Filling Link",
                url: "https://efiling.ecourts.gov.in/wb/",
              },
              {
                name: "Rules for e-Filling",
                url: "https://www.calcuttahighcourt.gov.in/Notice-Files/general-notice/5351",
              },
              {
                name: "e-Filling Help",
                url: "https://efiling.ecourts.gov.in/help",
              },
              {
                name: "e-Filling Notification",
                url: "https://www.calcuttahighcourt.gov.in/Notice-Files/general-notice/5355",
              },
              {
                name: "e Gate-Pass",
                url: "https://www.calcuttahighcourt.gov.in/E-Gate-Pass-Application",
              },
              {
                name: "V-Court / e-Mentioning",
                url: "https://www.calcuttahighcourt.gov.in/V-Court-Advocate-Registration",
              },
            ],
          },
          {
            name: "Cause Lists",
            links: [
              {
                name: "Cause Lists",
                url: "https://www.calcuttahighcourt.gov.in/Cause-Lists",
              },

              {
                name: "Original Side (From CIS)",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/cases/highcourt_causelist.php?state_cd=16&dist_cd=1&court_code=1&stateNm=Calcutta",
              },
              {
                name: "Appellate Side (From CIS)",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/cases/highcourt_causelist.php?state_cd=16&dist_cd=1&court_code=3&stateNm=Calcutta",
              },
            ],
          },
          {
            name: "Case Status",
            links: [
              {
                name: "Original Side (From CIS)",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=16&dist_cd=1&stateNm=Calcutta",
              },
              {
                name: "Appellate Side (From CIS)",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=16&dist_cd=1&court_code=3&stateNm=Calcutta",
              },
              {
                name: "Circuit bench at Port Blair",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=16&dist_cd=1&court_code=4&stateNm=Calcutta",
              },
            ],
          },
          {
            name: "Orders & Judgments",
            links: [
              {
                name: "Original Side/Appellate Side ",
                url: "https://www.calcuttahighcourt.gov.in/Order-Judgments",
              },
              {
                name: "Original Side (From CIS) ",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=16&dist_cd=1&stateNm=Calcutta",
              },
              {
                name: "Appellate Side (From CIS) ",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=16&dist_cd=1&court_code=3&stateNm=Calcutta",
              },
            ],
          },
        ],
      },
      {
        url: "chhattisgarh-high-court",
        name: "Chhattisgarh High Court",
        links: [
          {
            name: "General",
            links: [
              {
                name: "Whats New ?",
                url: "https://highcourt.cg.gov.in/new.html",
              },
              {
                name: "virtual Court",
                url: "https://highcourt.cg.gov.in/vclink.html",
              },
              {
                name: "E-Gate pass",
                url: "https://egatepass.cghc.nic.in/",
              },
              {
                name: "Display Board",
                url: "https://highcourt.cg.gov.in/hcbspcourtview/query/court1.php",
              },
              {
                name: "Case Status",
                url: "https://highcourt.cg.gov.in/case_status.html",
              },
              {
                name: "Order / Judgement ",
                url: "https://highcourt.cg.gov.in/hcbspjudgement/query/jud_status_oj_new.php?chopt=O",
              },

              {
                name: "A F R",
                url: "https://highcourt.cg.gov.in/afr.html",
              },
              {
                name: "Cause List",
                url: "https://highcourt.cg.gov.in/casue_list.html",
              },
              {
                name: "e Filling",
                url: "https://filing.ecourts.gov.in/pdedev/",
              },
              {
                name: "eSCR All High Court",
                url: "https://filing.ecourts.gov.in/pdedev/",
              },
              {
                name: "Roster",
                url: "https://highcourt.cg.gov.in/roster.html",
              },
            ],
          },
        ],
      },
      {
        url: "delhi-high-court",
        name: "Delhi High Court",
        links: [
          {
            name: "General",
            links: [
              {
                url: "http://164.100.69.66/jsearch/",
                name: "PDF judgment",
              },
              {
                url: "https://dhcappl.nic.in/FreeText/",
                name: "Free Text Search",
              },
              {
                name: "Yearwise Judgment OF DELHI HIGH COURT",
                url: "https://dhcappl.nic.in/FreeText/launchbrowsejud.do",
              },
            ],
          },
          {
            name: "E-Filling",
            links: [
              {
                url: "https://delhihighcourt.nic.in/uploads/pdf/statistics.pdf",
                name: "e-Filling Statistics",
              },
              {
                name: "Guidelines for e-File",
                url: "https://delhihighcourt.nic.in/guidelines_efile",
              },
              {
                name: "Online e-Filing Web Portal",
                url: "https://dhcefiling.nic.in/eFiling/",
              },
              {
                name: "RULES FOR ON-LINE ELECTRONIC FILING (E-FILING)",
                url: "https://delhihighcourt.nic.in/uploads/pdf/NotificationFile_X74GTJ7YAKY.PDF",
              },
              {
                name: "Use of A4 size paper uniformly in all jurisdictions in High Court and Delhi District Courts",
                url: "https://delhihighcourt.nic.in/uploads/pdf/PRACTICE_DIRECTIONS.pdf",
              },
              {
                name: "FAQ's in respect of Online e-Filing Rules",
                url: "https://delhihighcourt.nic.in/uploads/pdf/efilingfaq.pdf",
              },
              {
                name: "FAQ's in respect of Video Conference Hearings.",
                url: "https://delhihighcourt.nic.in/uploads/pdf/FAQVideoConfrencing.pdf",
              },
            ],
          },
        ],
      },
      {
        url: "gauhati-high-court",
        name: "Gauhati High Court",
        links: [
          {
            name: "Judicial Links",
            links: [
              {
                name: "Live Streaming",
                url: "https://ghconline.gov.in/index.php/live-streaming-of-court-proceedings-1/",
              },
              {
                name: "Cause List",
                url: "https://ghconline.gov.in/index.php/consolidated-cause-list/",
              },
              {
                name: "Display Board",
                url: "http://15.206.164.232/ghccms/case_display",
              },
            ],
          },
          {
            name: "Judgments & Orders",
            links: [
              {
                name: "udgments & Orders (New)",
                url: "https://ghconline.gov.in/index.php/orders-and-judgements/",
              },
              {
                name: "Judgements (Up to 2017)",
                url: "https://ghconline.gov.in/index.php/judgments-up-to-2017/",
              },
            ],
          },
          {
            name: "Case Status",
            links: [
              {
                name: "Case Status",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=6&dist_cd=1&stateNm=Assam",
              },
            ],
          },
          {
            name: "E Court Services",
            links: [
              {
                name: "National eCourt Services",
                url: "https://ecourts.gov.in/ecourts_home/",
              },
              {
                name: "eCourt Services (Assam)",
                url: "https://ecourtsghc.in/",
              },
            ],
          },
        ],
      },
      {
        url: "gujarat-high-court",
        name: "Gujarat High Court",
        links: [
          {
            name: "Judicial Services",
            links: [
              {
                name: "Judicial Officers' Portal Login",
                url: "https://gujcourts.guj.nic.in/",
              },
              {
                name: "Gujarat High Court Legal Services Committee",
                url: "https://gujarathighcourt.nic.in/ghclsc_committee",
              },
              {
                name: "Gujarat State Legal Service Authority (GSLSA)",
                url: "https://gslsa.gujarat.gov.in/",
              },
              {
                name: "Mediation Centre",
                url: "https://gujarathighcourt.nic.in/mc",
              },
              {
                name: "Judicial Academy",
                url: "https://gsja.nic.in/",
              },
              {
                name: "Judges Library",
                url: "https://gujarathighcourt.nic.in/library",
              },
            ],
          },
          {
            name: "e-Services",
            links: [
              {
                name: "Neutral Citation",
                url: "https://gujarathc-casestatus.nic.in/gujarathc/NCSearch.jsp",
              },
              {
                name: "RTI Portal",
                url: "https://gujarathc-casestatus.nic.in/rtiportal/",
              },
              {
                name: "Hybrid Hearing",
                url: "https://gujarathc-casestatus.nic.in/gujarathc/HybridHearing.jsp",
              },
              {
                name: "Virtual Traffic Court",
                url: "/virtualtrafficcourt",
              },
              {
                name: "Hon'ble Supreme Court Orders/Judgments in Gujarati",
                url: "/scgujaratijudgments",
              },
              {
                name: "e-SCR",
                url: "https://judgments.ecourts.gov.in/",
              },
              {
                name: "District Judiciary",
                url: "/districtjudiciaryweb",
              },
              {
                name: "Virtual Justice Clock",
                url: "https://gujcourts.guj.nic.in/jc_slider/hcweb_led_screen2.php",
              },
              {
                name: "Virtual Justice Clock - Districts",
                url: "/virtualjusticeclockdistricts",
              },
              {
                name: "HC Live Streaming Board",
                url: "https://gujarathighcourt.nic.in/streamingboard/",
              },
              {
                name: "District Live Streaming",
                url: "https://gujarathighcourt.nic.in/dclive/",
              },
              {
                name: "Youtube Channel",
                url: "https://www.youtube.com/c/GujaratHighCourtLive",
              },
              {
                name: "Telegram Channel",
                url: "https://gujarathighcourt.nic.in/hccms/sites/default/files/miscnotifications/Press%20Release%20-%20Telegram%20Channel%20of%20High%20Court%20of%20Gujarat.pdf",
              },
              {
                name: "eCourts Services",
                url: "https://services.ecourts.gov.in",
              },
              {
                name: "e-Pay",
                url: "https://pay.ecourts.gov.in/epay/",
              },
              {
                name: "e-Filing for High Court",
                url: "https://efiling.ecourts.gov.in/gj/",
              },
              {
                name: "Help Documentation and Videos for eFiling",
                url: "https://efiling.ecourts.gov.in/help",
              },
              {
                name: "eCertified Copy of HC Orders/ Judgments",
                url: "https://gujarathc-casestatus.nic.in/gujarathc/MyPortfolio.jsp",
              },
              {
                name: "eGatepass",
                url: "https://gujcourts.guj.nic.in/eGatePass/",
              },
              {
                name: "HC Case Status / Cause List / Orders / Judgment",
                url: "https://gujarathc-casestatus.nic.in/gujarathc/",
              },
              {
                name: "Email My Case Status",
                url: "https://gujarathighcourt.nic.in/emcs",
              },
              {
                name: "ઇમેઇલ માય કેસ સ્ટેટસ",
                url: "https://gujarathighcourt.nic.in/emcs/emcs",
              },
              {
                name: "Display Board",
                url: "https://gujarathighcourt.nic.in/boarddisplay",
              },
              {
                name: "Advocate eBook Causelist",
                url: "https://gujarathighcourt.nic.in/causelist",
              },
              {
                name: "Daily Notices",
                url: "https://gujarathighcourt.nic.in/dailynotices",
              },
              {
                name: "Hon'ble High Court Orders/Judgments in Gujarati [ગુજરાતી]",
                url: "https://gujarathighcourt.nic.in/gujaratijudgments",
              },
              {
                name: "National Judicial Data Grid (NJDG)",
                url: "https://njdg.ecourts.gov.in/",
              },
            ],
          },
        ],
      },
      {
        url: "himachal-pradesh-high-court",
        name: "Himachal Pradesh High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Gazette Notifications",
                url: "http://rajpatrahimachal.nic.in/",
              },
              {
                name: " Grievances/Suggestions",
                url: "https://highcourt.hp.gov.in/gp/home.php",
              },
              {
                name: "Online e-Gate Pass",
                url: "https://highcourt.hp.gov.in/e_visitor",
              },
              {
                url: "https://www.hphcrecruitment.in/login",
                name: "Recruitment Portal Login",
              },
            ],
          },
          {
            name: "Cause List",
            links: [
              {
                name: "High Court Causelist",
                url: "https://highcourt.hp.gov.in/causelist/netbd.php",
              },
            ],
          },
          {
            name: "Certified Copy",
            links: [
              {
                name: "Apply",
                url: "https://highcourt.hp.gov.in/copying",
              },
              {
                name: "Modify",
                url: "https://highcourt.hp.gov.in/copying",
              },
            ],
          },
          {
            name: "E-Filling",
            links: [
              {
                name: "e-filling",
                url: "https://efiling.ecourts.gov.in/hp/",
              },
            ],
          },

          {
            name: "Digital Display",
            links: [
              {
                name: "High Court Display",
                url: "https://highcourt.hp.gov.in/websitedisplay/hcscreen.php",
              },
            ],
          },
          {
            name: "Online e-court Fee",
            links: [
              {
                name: "Online e-court Fee",
                url: "https://www.shcilestamp.com/estamp_CFS_home.html",
              },
            ],
          },
        ],
      },
      {
        url: "jammu-&-kashmir-high-court",
        name: "Jammu & Kashmir High Court",
        links: [
          {
            name: "General",
            links: [
              {
                name: "Judgement Search Portal",
                url: "https://judgments.ecourts.gov.in/pdfsearch/index.php",
              },
              {
                name: "High Court Library",
                url: "https://jkhighcourt.nic.in/library.php",
              },
            ],
          },
          {
            name: "Administration",
            links: [
              {
                name: "High Court Registry",
                url: "https://jkhighcourt.nic.in/hcregistry.php",
              },
              {
                name: "Sections",
                url: "https://jkhighcourt.nic.in/sections.php",
              },
            ],
          },
          {
            name: "Orders and Circulars",
            links: [
              {
                name: "Orders",
                url: "https://jkhighcourt.nic.in/orders.php",
              },
              {
                name: "Circulars",
                url: "https://jkhighcourt.nic.in/cir.php",
              },
              {
                name: "Notifications",
                url: "https://jkhighcourt.nic.in/notice.php",
              },
            ],
          },
          {
            name: "District Judiciary",
            links: [
              {
                name: "Courts",
                url: "https://jkhighcourt.nic.in/subordinate.php",
              },
              {
                name: "Judicial Officers",
                url: "https://jkhighcourt.nic.in/biodatajo.php",
              },
              {
                name: "Organizational Chart of Sub. ordinate Judiciary",
                url: "https://jkhighcourt.nic.in/doc/upload/organization/dc.pdf",
              },
              {
                name: "Status of Infrastructure in High Court & Subordinate Courts",
                url: "https://jkhighcourt.nic.in/infra.php",
              },
            ],
          },
        ],
      },
      {
        url: "jharkhand-high-court",
        name: "Jharkhand High Court",
        links: [
          {
            name: "Site Link highlight",
            links: [
              {
                name: "Latest Activities",
                url: "https://jharkhandhighcourt.nic.in/latest-activities",
              },
              {
                name: "LIST OF DEFECTIVE NEW FILING",
                url: "https://jharkhandhighcourt.nic.in/list-defective-new-filing",
              },
            ],
          },
          {
            name: "District Judiciary",
            links: [
              {
                name: "Nomination Training",
                url: "https://jharkhandhighcourt.nic.in/nomination-training-0",
              },
              {
                name: "Transfer, Posting and promotion",
                url: "https://jharkhandhighcourt.nic.in/transfer-%20posting-promotions",
              },
              {
                name: "Notifications",
                url: "https://jharkhandhighcourt.nic.in/notifications",
              },
            ],
          },
          {
            name: "General",
            links: [
              {
                name: "ENTIRE CAUSELIST",
                url: "http://jharkhandhighcourt.nic.in/entire-causelist-0",
              },
              {
                name: "Cause List",
                url: "https://hcservices.ecourts.gov.in/hcservices/main.php",
              },
              {
                name: "Constitution of Bench",
                url: "http://jharkhandhighcourt.nic.in/constitution-of-bench",
              },
              {
                name: "Case Status",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=7&dist_cd=1&stateNm=Jharkhand",
              },
              {
                name: "Orders & Judgement",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=7&dist_cd=1&stateNm=Jharkhand",
              },
              {
                name: "e-Courts",
                url: "http://www.ecourts.gov.in/ecourts_home/",
              },
              {
                name: "TENDER",
                url: "http://jharkhandhighcourt.nic.in/tender",
              },
              {
                name: "Annual Reports",
                url: "http://jharkhandhighcourt.nic.in/annual-reports",
              },
              {
                name: "e-Filing",
                url: "https://filing.ecourts.gov.in/pdedev/",
                title: "New",
              },
              {
                name: "Display Board",
                url: "http://jharkhandhighcourt.nic.in/dpboard.php",
              },
              {
                name: "CASES DURING PANDEMIC",
                url: "http://jharkhandhighcourt.nic.in/cases-taken-during-pandemic-period",
              },
              {
                name: "Online e-court Fee",
                url: "https://www.shcilestamp.com/estamp_CFS_home.html",
              },
              { name: "office orders", url: "/office-orders" },
              {
                name: "Online Scrutiny/Stamp Reporting of Filing",
                url: "https://jharkhandhighcourt.nic.in/def/defects/",
              },
              {
                name: "Online Certified Copy",
                url: "https://jharkhandhighcourt.nic.in/occ/public/index.php",
              },
              {
                name: "REPORTS",
                url: "http://jharkhandhighcourt.nic.in/online-certified-copy-weekly-report",
              },
              {
                name: "| Vidhik Seva Portal",
                url: "https://jhclsc.jharkhand.gov.in/",
              },
              {
                name: "| Vidhi Sahitya Prakashan",
                url: "http://legislative.gov.in/vidhi-sahitya",
              },
              {
                name: "| e-Pass for Advocates/Litigants",
                url: "https://jharkhandhighcourt.nic.in/epass/index.php",
              },
              {
                name: "| LIVE STREAMING",
                url: "https://www.youtube.com/channel/UC43OwYFDEuS8OrK_PSIabSg/videos?view=57",
              },
              {
                name: "|Virtual Justice Clock",
                url: "https://jharkhandhighcourt.nic.in/justice_clock/",
              },
            ],
          },
        ],
      },
      {
        url: "karnataka-high-court",
        name: "Karnataka High Court",
        links: [
          {
            name: "General",
            links: [
              {
                name: "Judge's Profile",
                url: "https://karnatakajudiciary.kar.nic.in/profiles.php",
              },
              {
                name: "Assets of Sitting Judges",
                url: "https://karnatakajudiciary.kar.nic.in/judgesAssets&Liabilities.php",
              },
              {
                name: "Sitting List",
                url: "https://karnatakajudiciary.kar.nic.in/sitting_list.php",
              },
              {
                name: "Calendar",
                url: "https://karnatakajudiciary.kar.nic.in/calendar.php",
              },
              {
                name: "Case Types",
                url: "https://karnatakajudiciary.kar.nic.in/noticeBoard/casetypes.pdf",
                target: "_blank",
              },
              {
                name: "Subject Categories",
                url: "https://karnatakajudiciary.kar.nic.in/noticeBoard/subjectcat.pdf",
                target: "_blank",
              },
              {
                name: "Virtual Courts",
                url: "http://vcourts.gov.in/virtualcourt/",
              },
              {
                name: "Online e-Filing",
                url: "https://efiling.ecourts.gov.in/kar/",
              },
              {
                name: "Online Copy Application & e-Payment of Court Fee",
                url: "https://karnatakajudiciary.kar.nic.in/cconline/public/",
              },
              {
                name: "High Court Library",
                url: "https://karnatakajudiciary.kar.nic.in/hcklibrary/index.php",
                target: "_blank",
              },
              {
                name: "Registry Officer's",
                url: "https://karnatakajudiciary.kar.nic.in/hck-registry.php",
              },
              {
                name: "Virtual Courts Gallery",
                url: "https://karnatakajudiciary.kar.nic.in/gallery.php",
              },
              {
                name: "Photo/Video Gallery",
                url: "https://karnatakajudiciary.kar.nic.in/videogallery.php",
              },
              {
                name: "RTI Rules 2005 & Notifications",
                url: "https://karnatakajudiciary.kar.nic.in/RTI-Notfn.php",
              },
              {
                name: "Lok Adalath-Statistics",
                url: "https://karnatakajudiciary.kar.nic.in/lokadalathStats.php",
              },
              {
                name: "Committees",
                url: "https://karnatakajudiciary.kar.nic.in/committees.php",
              },
              {
                name: "District Courts",
                url: "https://districts.ecourts.gov.in/karnataka",
                target: "_blank",
              },
              {
                name: "Commercial Courts",
                url: "http://karnatakajudiciary.kar.nic.in/commercialCourts/ccourts/",
              },
              {
                name: "Contact Us",
                url: "https://karnatakajudiciary.kar.nic.in/contactus.php",
              },
              {
                name: "Online Services",
                url: "https://karnatakajudiciary.kar.nic.in/cconline/public/",
                target: "_blank",
              },
              {
                name: "Causelist through SMS",
                url: "https://karnatakajudiciary.kar.nic.in/causelist_through_sms.php",
              },
              {
                name: "Registration for causelist through SMS",
                url: "https://karnatakajudiciary.kar.nic.in/services/registration-through-sms.pdf",
                target: "_blank",
              },
              {
                name: "SMS Facilities and KIOSKS",
                url: "https://karnatakajudiciary.kar.nic.in/services/pressnotesms_final dedication.pdf",
                target: "_blank",
              },
              {
                name: "Advocates Registration",
                url: "https://karnatakajudiciary.kar.nic.in/advreg/",
                target: "_blank",
              },
              { name: "e-Seva Kendra", url: "eseva.php", target: "_blank" },
              {
                name: "Panel Advocates for Municipal Admin",
                url: "http://www.municipaladmn.gov.in/kn/citizen-charter",
                target: "_blank",
              },
              {
                name: "Cause Lists",
                url: "https://karnatakajudiciary.kar.nic.in/cause_list.php",
              },
              {
                name: "Case Status",
                url: "https://karnatakajudiciary.kar.nic.in/case_status.php",
              },
              {
                name: "Principal Bench",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=3&dist_cd=1&stateNm=Karnataka",
                target: "_blank",
              },
              {
                name: "Dharwad Bench",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=3&dist_cd=1&court_code=2&stateNm=Karnataka",
                target: "_blank",
              },
              {
                name: "Kalaburagi Bench",
                url: "https://hcservices.ecourts.gov.in/ecourtindiaHC/index_highcourt.php?state_cd=3&dist_cd=1&court_code=3&stateNm=Karnataka",
                target: "_blank",
              },
              {
                name: "Cert. Copy Status",
                url: "https://karnatakajudiciary.kar.nic.in/certified_copy_status.php",
              },
              {
                name: "Daily Orders",
                url: "https://karnatakajudiciary.kar.nic.in/repository/daily_order.php",
                target: "_blank",
              },
              { name: "Judgments", url: "#", style: "padding-right: 23px;" },
              {
                name: "Judgments of HCK",
                url: "https://karnatakajudiciary.kar.nic.in/judgment_search.php",
              },
              {
                name: "Judgments of SCI translated in Kannada",
                url: "https://karnatakajudiciary.kar.nic.in/sci_judgments.php",
              },
              {
                name: "Judgments of HCK translated in Kannada",
                url: "https://karnatakajudiciary.kar.nic.in/hck_judgments.php",
              },
              {
                name: "Notifications",
                url: "https://karnatakajudiciary.kar.nic.in/notification.php",
              },
              {
                name: "General",
                url: "https://karnatakajudiciary.kar.nic.in/generalNotific.php",
              },
              {
                name: "Daily",
                url: "https://karnatakajudiciary.kar.nic.in/dailyNotification.php",
              },
              {
                name: "Arbitration",
                url: "https://karnatakajudiciary.kar.nic.in/arbitrationNotfn.php",
              },
              {
                name: "Government Notifications",
                url: "https://karnatakajudiciary.kar.nic.in/govtNotification.php",
              },
              {
                name: "Transfers",
                url: "https://karnatakajudiciary.kar.nic.in/transfers.php",
              },
              {
                name: "Recruitments",
                url: "https://karnatakajudiciary.kar.nic.in/recruitment.php",
              },
              {
                name: "District-Recruitment",
                url: "https://karnatakajudiciary.kar.nic.in/districtrecruitment.php",
              },
              {
                name: "Tenders",
                url: "https://karnatakajudiciary.kar.nic.in/tenderNotification.php",
              },
              {
                name: "Circulars",
                url: "https://karnatakajudiciary.kar.nic.in/Circulars.php",
              },
              {
                name: "Notification of showing list of unclaimed deposits",
                url: "https://karnatakajudiciary.kar.nic.in/generalNotification/acc2.pdf",
                target: "_blank",
              },
              {
                name: "Seniority List",
                url: "https://karnatakajudiciary.kar.nic.in/seniority-lists.php",
              },
              {
                name: "Supreme Court of India",
                url: "http://supremecourtofindia.nic.in",
                target: "_blank",
              },
            ],
          },
        ],
      },
      {
        url: "kerala-high-court",
        name: "Kerala High Court",
        links: [
          {
            name: "General",
            links: [
              { name: "Acts/Rules", url: "https://hckerala.gov.in/acts.php" },
              { name: "Tenders", url: "https://hckerala.gov.in/tender.php" },
              { name: "Downloads", url: "https://hckerala.gov.in/forms.php" },
              {
                name: "Newsletter",
                url: "https://hckerala.gov.in/ecommitee_newsletter.php",
              },
              {
                name: "Telephone Directory",
                url: "https://hckerala.gov.in/telephone_directory.php",
              },
              { name: "Library", url: "https://hckerala.gov.in/library.php" },
              { name: "FAQs", url: "https://hckerala.gov.in/faq.php" },
              {
                name: "ILR",
                url: "https://hckerala.gov.in/indian_law_reports.php",
              },
              { name: "Gallery", url: "https://hckerala.gov.in/gallery.php" },
              {
                name: "High Court Communications",
                url: "https://hckerala.gov.in/hc_communications.php",
              },
              {
                name: "Administration Reports",
                url: "https://hckerala.gov.in/administrative_reports.php",
              },
              {
                name: "Case Clearance Rate",
                url: "https://hckerala.gov.in/case_clear_rate.php",
              },
              { name: "Speech", url: "https://hckerala.gov.in/speech.php" },
              {
                name: "e-Committee Newsletter",
                url: "https://hckerala.gov.in/ecommitee_newsletter.php",
              },
              {
                name: "Juvenile Justice Committee Newsletter",
                url: "https://hckerala.gov.in/ecommitee_newsletter.php",
              },
              {
                name: "Government Orders",
                url: "https://hckerala.gov.in/government_orders.php",
              },
              { name: "RTI", url: "https://hckerala.gov.in/rti.php" },
              { name: "Links", url: "https://hckerala.gov.in/links.php" },
              {
                name: "Calendar",
                url: "https://hckerala.gov.in/web_highcourt_calendar.php",
              },
              {
                name: "Vidhi Sahitya Prakashan",
                url: "https://legislative.gov.in/vidhi-sahitya-prakashan/",
              },
              { name: "Sitemap", url: "https://hckerala.gov.in/sitemap.php" },
              {
                name: "Disclaimer",
                url: "https://hckerala.gov.in/disclaimer.php",
              },
            ],
          },
          {
            name: "Frequently Visited Links",
            links: [
              {
                name: "Judges",
                url: "https://hckerala.gov.in/judges.php",
              },
              {
                name: "Judges - Virtual Office",
                url: "https://ecourts.kerala.gov.in/digicourt/",
              },
              {
                name: "Judgements / Orders",
                url: "https://hckinfo.kerala.gov.in/digicourt/Casedetailssearch",
              },
              {
                name: "Cause List",
                url: "https://hckinfo.kerala.gov.in/digicourt/Casedetailssearch/viewCauselist",
              },
              {
                name: "Judgements of Public Importance",
                url: "https://hckinfo.kerala.gov.in/digicourt/Casedetailssearch/Publicmatter",
              },
              {
                name: "Case",
                url: "https://hckerala.gov.in/",
              },
              {
                name: "e-Filling",
                url: "https://hckerala.gov.in/",
              },
            ],
          },
        ],
      },
      { url: "madhya-pradesh-high-court", name: "Madhya Pradesh High Court" },
      {
        url: "madras-high-court",
        name: "Madras High Court",
        links: [
          {
            name: "General",
            links: [
              {
                name: "Rules",
                url: "https://www.hcmadras.tn.nic.in/rules.html",
              },
              {
                name: "Administration",
                url: "https://www.hcmadras.tn.nic.in/registrars.html",
              },
              {
                name: "Telephone Directory",
                url: "https://www.hcmadras.tn.nic.in/telephone.html",
              },
              {
                name: "Security System",
                url: "https://www.hcmadras.tn.nic.in/css.pdf",
              },
              {
                name: "Judicial Service",
                url: "https://www.hcmadras.tn.nic.in/judicialservice.html",
              },
              {
                name: "Transfer & Postings",
                url: "https://www.hcmadras.tn.nic.in/whatnew.html",
              },
              {
                name: "High Court Calendar",
                url: "https://www.hcmadras.tn.nic.in/cale.html",
              },
              {
                name: "Right to Information",
                url: "https://www.hcmadras.tn.nic.in/hcrules.html",
              },
              {
                name: "Citizen Charter",
                url: "https://www.hcmadras.tn.nic.in/citizen.html",
              },
              {
                name: "Former Judges",
                url: "https://www.hcmadras.tn.nic.in/pusinejudges.html",
              },
              {
                name: "Full Bench and Larger Bench Judgements",
                url: "https://www.hcmadras.tn.nic.in/fullbench.html",
              },
              {
                name: "Judges Library",
                url: "https://www.hcmadras.tn.nic.in/mhclibrary.html",
              },
              {
                name: "Commercial Court Cases",
                url: "https://www.hcmadras.tn.nic.in/Commercial_Court_Cases.html",
              },
              {
                name: "Case Clearance Rate (CCR)",
                url: "https://www.hcmadras.tn.nic.in/Case_Clearance_Rate.html",
              },
              {
                name: "e-Visitor Pass",
                url: "https://www.mhc.tn.gov.in/eservices/hcvcpass/",
              },
              {
                name: "e-SCR",
                url: "https://judgments.ecourts.gov.in/pdfsearch/?p=pdf_search/index&amp;escr_flag=Y",
              },
            ],
          },
          {
            name: "Services",
            links: [
              {
                name: "Cause List",
                url: "https://www.hcmadras.tn.nic.in/casestatus.html#cause",
              },
              {
                name: "Judgments",
                url: "https://www.hcmadras.tn.nic.in/casestatus.html#judg",
              },
              {
                name: "Status of Cases",
                url: "https://www.hcmadras.tn.nic.in/casestatus.html#casest",
              },
              {
                name: "Daily Orders",
                url: "https://www.hcmadras.tn.nic.in/casestatus.html#daily",
              },
              {
                name: "Display Boards",
                url: "https://www.hcmadras.tn.nic.in/casestatus.html#displayboard",
              },
              {
                name: "e - Filing",
                url: "https://www.hcmadras.tn.nic.in/efiling.html",
              },
            ],
          },
          {
            name: "Hon'ble Judges",
            links: [
              {
                name: "Chief Justice",
                url: "https://www.hcmadras.tn.nic.in/cjhc.html",
              },
              {
                name: "Present Judges",
                url: "https://www.hcmadras.tn.nic.in/prejudge.html",
              },
              {
                name: "Assets of Judges",
                url: "https://www.hcmadras.tn.nic.in/assetsofjudges.html",
              },
              {
                name: "Speeches by Judges",
                url: "https://www.hcmadras.tn.nic.in/speeches.html",
              },
              {
                name: "Former Chief Justices",
                url: "https://www.hcmadras.tn.nic.in/cjlist.html",
              },
            ],
          },
        ],
      },
      {
        url: "manipur-high-court",
        name: "Manipur High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "E-Courts",
                url: "http://www.ecourts.gov.in/",
              },
              {
                name: "NJDG",
                url: "https://njdg.ecourts.gov.in/",
              },
              {
                name: "MA SL SA",
                url: "https://maslsa.nic.in/",
              },
              {
                name: "HCL SC",
                url: "https://hclscmanipur.in/",
              },
              {
                name: "Gauhati High Court",
                url: "https://ghconline.nic.in/",
              },
              {
                name: "Web Portal Of India",
                url: "https://india.gov.in/",
              },
              {
                name: "Official Website Of Manipur",
                url: "https://manipur.gov.in/",
              },
            ],
          },
        ],
      },
      {
        url: "meghalaya-high-court",
        name: "Meghalaya High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Of India",
                url: "http://sci.gov.in/",
              },
              {
                name: "Government Of India",
                url: "http://india.gov.in/",
              },
              {
                name: "e-SCR, Judgement & Orders",
                url: "https://judgments.ecourts.gov.in/pdfsearch/index.php",
              },
              {
                name: "e-Court Services, High Courts Of India",
                url: "https://hcservices.ecourts.gov.in/hcservices/",
              },
              {
                name: "e-Court Services, District Courts Of India",
                url: "https://services.ecourts.gov.in/ecourtindia_v6/",
              },
              {
                name: "NJDG High Courts",
                url: "https://njdg.ecourts.gov.in/hcnjdgnew/",
              },
              {
                name: "NJDG District Courts",
                url: "https://njdg.ecourts.gov.in/njdgnew/index.php",
              },
              {
                name: "Meghalaya State Legal Services Authority",
                url: "http://mslsa.gov.in/",
              },
              {
                name: "Meghalaya State Judicial Academy",
                url: "http://meghsja.gov.in/",
              },
            ],
          },
        ],
      },
      {
        url: "high-court-of-orissa",
        name: "High Court of Orissa",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Ministry Of Law And Justice",
                url: "http://lawmin.gov.in/",
              },
              {
                name: "Supreme Court Of India",
                url: "http://sci.gov.in/",
              },
              {
                name: "Department Of Justice",
                url: "https://doj.gov.in/",
              },
              {
                name: "indiancourts.nic.in",
                url: "http://indiancourts.nic.in/",
              },
              {
                name: "Odisha Gazette",
                url: "https://www.egazetteodisha.nic.in/",
              },
              {
                name: "Tenders Orissa",
                url: "https://tendersodisha.gov.in/nicgep/app",
              },
              {
                name: "indiankanoon.org",
                url: "https://indiankanoon.org/",
              },
              {
                name: "The Gazette Of India",
                url: "https://egazette.nic.in/(S(0dqbf4qksdqiyg1tlsvrvxsv))/default.aspx",
              },
              {
                name: "india.govt.in",
                url: "https://www.india.gov.in/",
              },
              {
                name: "odisha.gov.in",
                url: "https://www.odisha.gov.in/",
              },
              {
                name: "Odisha State Legal Services Authority",
                url: "https://oslsa.nic.in/",
              },
              {
                name: "Law Comission Of India",
                url: "http://lawcommissionofindia.nic.in/",
              },
              {
                name: "The High Court Bar Association, Odisha",
                url: "http://www.highcourtbar.org/",
              },
            ],
          },
        ],
      },
      {
        url: "patna-high-court",
        name: "Patna High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Of India",
                url: "http://sci.gov.in/",
              },
              {
                name: "National Judicial Datagrid",
                url: "http://ecourts.gov.in/",
              },
              {
                name: "Websites Of District Courts",
                url: "https://districts.ecourts.gov.in/bihar",
              },
              {
                name: "Patna High Court Legal Services Committee, Patna",
                url: "https://patnahighcourt.gov.in/hclsc/default.aspx",
              },
              {
                name: "Bihar State Legal Services Authority",
                url: "http://patnahighcourt.gov.in/bslsa/",
              },
              {
                name: "Bihar Judicial Academy",
                url: "http://patnahighcourt.gov.in/bja/",
              },
              {
                name: "Patna High Court Juvenile Justice Monitoring Committee",
                url: "http://patnahighcourt.gov.in/jjs/",
              },
              {
                name: "FIRs Tracking System, State Crime Records Bureau, Bihar",
                url: "http://scrb.bihar.gov.in/View_FIR.aspx",
              },
              {
                name: "Prevention Of Sexual Harassment Committee",
                url: "http://patnahighcourt.gov.in/POSH/",
              },
              {
                name: "e-SCR, Judgements Search",
                url: "https://judgments.ecourts.gov.in/",
              },
              {
                name: "Online Demo Link Of e-SCR",
                url: "https://www.youtube.com/watch?v=zWfvIxkwxRI",
              },
            ],
          },
        ],
      },
      {
        url: "punjab-&-haryana-high-court",
        name: "Punjab & Haryana High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Of India",
                url: "https://sci.gov.in/",
              },
              {
                name: "Supreme Court Legal Services Committee",
                url: "https://www.sclsc.gov.in/",
              },
              {
                name: "High Courts Of India",
                url: "https://ecourts.gov.in/ecourts_home/static/highcourts.php",
              },
              {
                name: "District Courts Chandigarh",
                url: "https://chandigarh.dcourts.gov.in/",
              },
              {
                name: "Chandigarh Judicial Academy",
                url: "https://www.cja.gov.in/",
              },
              {
                name: "State Legal Services Authority",
                url: "http://chdslsa.gov.in/index.php",
              },
              {
                name: "Punjab State Legal Services",
                url: "https://pulsa.punjab.gov.in/",
              },
            ],
          },
        ],
      },
      {
        url: "rajasthan-high-court",
        name: "Rajasthan High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "President Of India",
                url: "http://presidentofindia.nic.in/",
              },
              {
                name: "Prime Minister Of India",
                url: "http://www.pmindia.gov.in/en/",
              },
              {
                name: "Supreme Court Of India",
                url: "http://sci.gov.in/",
              },
              {
                name: "e-Committee, Supreme Court Of India",
                url: "https://ecommitteesci.gov.in/",
              },
              {
                name: "Indian Parliament",
                url: "http://parliamentofindia.nic.in/",
              },
              {
                name: "Central Acts and Rules",
                url: "http://indiacode.nic.in/",
              },
              {
                name: "Indian Courts",
                url: "http://ecourts.gov.in/",
              },
              {
                name: "Government Of India",
                url: "http://india.gov.in/",
              },
              {
                name: "Government Of Rajasthan",
                url: "http://www.rajasthan.gov.in/Pages/default.aspx",
              },
              {
                name: "Rajasthan Legislative Assembly",
                url: "http://rajasthanassembly.nic.in/",
              },
              {
                name: "National Legal Services Authority",
                url: "https://nalsa.gov.in/",
              },
              {
                name: "Rajasthan State Legal Services Authority",
                url: "http://www.rlsa.gov.in/",
              },
              {
                name: "Supreme Court Legal Services Committee",
                url: "http://www.sclsc.nic.in/",
              },
              {
                name: "National Judicial Academy",
                url: "http://www.nja.nic.in/",
              },
              {
                name: "State Judicial Academy",
                url: "http://rajasthanjudicialacademy.nic.in/",
              },
              {
                name: "District Courts",
                url: "https://districts.ecourts.gov.in/",
              },
              {
                name: "Screen Reader Access",
                url: "https://hcraj.nic.in/hcraj/screen_reader_access.php",
              },
            ],
          },
        ],
      },
      {
        url: "Sikkim High Court",
        name: "Sikkim High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "e-SCR,Judgements And Orders",
                url: "https://judgments.ecourts.gov.in/pdfsearch/index.php",
              },
              {
                name: "Sikkim Law Reports",
                url: "https://hcs.gov.in/hcs/slr",
              },
              {
                name: "Certified Copy",
                url: "https://crshcs.nic.in/login",
              },
              {
                name: "Free Text Search",
                url: "https://hcs.gov.in/hcs/hcourt/hg_free_search",
              },
              {
                name: "Chief Justice's Secretariat",
                url: "https://hcs.gov.in/hcs/chiefsecretariat",
              },
              {
                name: "Virtual Justice Clock",
                url: "https://hcs.gov.in/virtualjusticeclock/clock.php",
              },
              {
                name: "Display Board",
                url: "https://hcs.gov.in/hcs/hcourt/displayboard",
              },
            ],
          },
        ],
      },
      {
        url: "Telangana High Court",
        name: "Telangana High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Legal Services Committee",
                url: "http://www.sclsc.nic.in/",
              },
              {
                name: "High Court Legal Services Committee",
                url: "https://tshc.gov.in/getLSNotifications",
              },
              {
                name: "Telangana State Legal Services Authority",
                url: "http://tslsa.telangana.gov.in/",
              },
              {
                name: "Bar Council of Telangana",
                url: "http://www.telanganabarcouncil.org/",
              },
              {
                name: "Telangana High Court Advocates Association",
                url: "http://www.thcaa.co.in/",
              },
              {
                name: "National Consumer Disputes Redressal Commission",
                url: "http://ncdrc.nic.in/",
              },
              {
                name: "Armed Forces Tribunal",
                url: "http://www.aftdelhi.nic.in/",
              },
            ],
          },
        ],
      },
      {
        url: "Tripura High Court",
        name: "Tripura High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Of India",
                url: "https://sci.gov.in/",
              },
              {
                name: "e-Committee,Supreme Court Of India",
                url: "https://ecommitteesci.gov.in/",
              },
              {
                name: "Ministry Of Law & Justice",
                url: "http://lawmin.gov.in/",
              },
              {
                name: "Law Commission of India",
                url: "http://lawcommissionofindia.nic.in/",
              },
              {
                name: "Ministry Of Home Affairs",
                url: "https://mha.gov.in/",
              },
              {
                name: "National Judicial Academy",
                url: "http://www.nja.nic.in/",
              },
              {
                name: "e-Courts Of India",
                url: "https://ecourts.gov.in/",
              },
              {
                name: "High Court Legal Services Committee",
                url: "https://hctlsc.tripura.gov.in/",
              },
              {
                name: "Tripura State Legal Services Authority",
                url: "https://slsa.tripura.gov.in/",
              },
              {
                name: "Tripura Judicial Academy",
                url: "https://tja.tripura.gov.in/",
              },
              {
                name: "Law And Parliamentary Affairs Department",
                url: "https://law.tripura.gov.in/",
              },
              {
                name: "Bar Council Of Tripura",
                url: "https://barcounciltripura.org.in/",
              },
            ],
          },
        ],
      },
      {
        url: "Uttarakhand High Court",
        name: "Uttarakhand High Court",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Legal Services Committee",
                url: "http://www.sclsc.nic.in/",
              },
              {
                name: "e-Courts Services",
                url: "https://ecourts.gov.in/ecourts_home/",
              },
              {
                name: "Bar Council of India",
                url: "http://www.barcouncilofindia.org/",
              },
              {
                name: "Law Commission of India",
                url: "https://lawcommissionofindia.nic.in/",
              },
              {
                name: "Uttarakhand Govt. Portal",
                url: "https://uk.gov.in/",
              },
              {
                name: "Uttarakhand Judicial And Legal Academy",
                url: "https://ujala.uk.gov.in/",
              },
              {
                name: "Uttarakhand State Legal Services Authority",
                url: "http://slsa.uk.gov.in/",
              },
              {
                name: "Ministry Of Law & Justice",
                url: "http://lawmin.gov.in/",
              },
              {
                name: "Bar Council Of Uttarakhand",
                url: "http://barcouncilofuttarakhand.org/",
              },
              {
                name: "High Court BAR Association Nainital",
                url: "http://highcourtbarassociationnainital.org/",
              },
              {
                name: "Legislative Department",
                url: "https://legislative.gov.in/",
              },
              {
                name: "Supreme Court Of India Reportable Judgements (eSCR)",
                url: "https://judgments.ecourts.gov.in/pdfsearch/?p=pdf_search/index&escr_flag=Y&app_token=",
              },
            ],
          },
        ],
      },
      // Add more courts for India if needed
    ],
  },
  {
    name: "Saudi Arabia",
    key: "saudi-arabia",
    courts: [
      {
        url: "General Courts",
        name: "General Courts",
        links: [
          {
            name: "Important Links",
            links: [
              {
                name: "Supreme Court Of UAE",
                url: "http://sci.gov.in/",
              },
              {
                name: "Government Of UAE",
                url: "http://india.gov.in/",
              },
            ],
          },
        ],
      },
      {
        url: "General Courts 2",
        name: "General Courts2",
        links: [
          {
            name: "Important Links2",
            links: [
              {
                name: "Supreme Court Of UAE",
                url: "http://sci.gov.in/",
              },
              {
                name: "Government Of UAE",
                url: "http://india.gov.in/",
              },
            ],
          },
        ],
      },
    ],
  },
  // Add more country data objects as required
];
