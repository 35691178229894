import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Button,
  Input,
  VStack,
  Container,
} from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/card";
import { GiCheckMark } from "react-icons/gi";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { countriesData } from "../../../data/courts-by-country";
import { BsCheckCircle } from "react-icons/bs";

const NewsandLegalUpdates = () => {
  const url = "https://main.sci.gov.in/";

  const navigate = useNavigate();
  const currCountry = sessionStorage.getItem("country") || "Saudi Arabia";

  const curr_country = countriesData.find(
    (c) => c.name.toLowerCase() === currCountry.toLowerCase()
  );

  const primaryCourt = curr_country.courts.find((court) => court.primary);

  const redirect = (link) => {
    navigate(link);
  };

  const floatingQuickLinks = [
    {
      title: "Lawyers Online",
      url: "/members/lawyers",
    },
    {
      title: "Law Firms",
      url: "/members/lawfirms",
    },
    {
      title: "Auditors",
      url: "/members/auditors",
    },
    {
      title: "Buisness Consultants",
      url: "/members/buisnessconsultants",
    },
    {
      title: "Legal Software",
      url: "/legalsoftware",
    },
    {
      title: "Subscriptions",
      url: `/subscriptions/buy`,
    },
    {
      title: "Important Links",
      url: `/importantLinks`,
    },
  ];

  return (
    // new style
    <Flex flexDir={{ base: "column", lg: "row" }} width="100%" py={10}>
      {/* Section 1: Side Navigation*/}

      <Container mx={{ base: "0", lg: "-85px" }}>
        <Box
          pos={{
            lg: "fixed",
          }}
          width={{
            base: "100%",
            lg: "14%",
          }}
          display={"flex"}
          flexDirection={{
            base: "column",
            md: "row",
            lg: "column",
          }}
          justifyContent={{
            md: "center",
          }}
          gap={"0.80rem"}
          left={"4"}
          rounded={"none"}
          height={{
            base: "auto",
            lg: "auto !important",
          }}
          overflowX={{
            base: "auto",
            md: "hidden",
            lg: "clip",
          }}
          overflowY={{
            base: "hidden",
          }}
          marginTop={{
            base: "1rem",
            lg: "1rem",
          }}
          zIndex={1}
          className="scroll-hide"
        >
          {/* Wrap links in multiple containers */}
          {Array.from({ length: Math.ceil(floatingQuickLinks.length / 4) }).map(
            (_, containerIndex) => (
              <Flex
                key={containerIndex}
                gap="0.80rem"
                flexWrap="wrap"
                justifyContent="center"
              >
                {floatingQuickLinks
                  .slice(containerIndex * 4, (containerIndex + 1) * 4)
                  .map((item, index) => (
                    <Card
                      key={index}
                      color="mainBlue"
                      borderColor="mainBlue"
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      transition="all 0.2s ease-in-out"
                      height="40px"
                      fontSize="sm"
                      width={{ base: "80%", lg: "100%", xl: "100%" }}
                      onClick={() => navigate(item.url ? item.url : "#")}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      _hover={{
                        bg: "gray.100",
                        color: "white",
                        backgroundColor: "mainBlue",
                        shadow: "lg",
                        fontSize: { md: "xl" },
                        transition: "all 0.2s ease-in-out",
                      }}
                      minWidth={{
                        base: "100px !important",
                        md: "100px",
                      }}
                    >
                      <CardBody>
                        <Text textAlign="center" fontSize="sm">
                          {item.title}
                        </Text>
                      </CardBody>
                    </Card>
                  ))}
              </Flex>
            )
          )}
        </Box>
      </Container>

      <Box width={"73%"}>
        {/* Section 2: News and legal updates */}

        <Flex
          flexDir={{ base: "column", lg: "row" }}
          alignItems={"center"}
          mb={6}
          ml={{ base: "80px", lg: "0" }}
          mt={{ base: 10, lg: 0 }}
        >
          <div style={{ flex: 1 }}>
            <Text
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
            >
              {primaryCourt && (
                <Text style={{ marginBottom: "20px" }}>
                  {primaryCourt.name}
                </Text>
              )}
            </Text>

            {/* Render links conditionally */}
            {primaryCourt && primaryCourt.links && (
              <ul>
                {primaryCourt.links.map((linkGroup, index) => {
                  if (linkGroup.name === "General" && linkGroup.links) {
                    return (
                      <li key={index}>
                        <ul>
                          {linkGroup.links.map((link, subIndex) => (
                            <li key={subIndex}>
                              <a
                                href={link.url}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  textDecoration: "none",
                                  color: "black",
                                  marginBottom: "8px",
                                }}
                              >
                                <BsCheckCircle style={{ marginRight: "8px" }} />
                                {link.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            )}
          </div>
          {primaryCourt && (
            <div style={{ flex: 1 }}>
              <img
                src="assets/homepage/footer2.jpg"
                alt="Footer"
                style={{
                  maxWidth: "100%",
                  height: "280px",
                  width: "93%",
                  marginLeft: "-15px",
                }}
              />
            </div>
          )}
        </Flex>

        {/* Box with 4 cards */}
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          mt={6}
          ml={{ base: "45px", lg: "-50px" }}
        >
          {/* Card 1 */}
          <Box
            width={{ base: "80%", lg: "45%" }}
            p={4}
            boxShadow="md"
            borderColor="mainBlue"
            borderWidth="1px"
            bg="white"
            m={2}
          >
            <Text
              fontWeight="normal"
              mb={2}
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
            >
              High Courts
            </Text>
            <ul>
              {curr_country.courts.slice(0, 5).map((court, index) => (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() =>
                    navigate(
                      "courts-by-country/info/" + currCountry + "/" + court.url
                    )
                  }
                >
                  <GiCheckMark style={{ marginRight: "8px" }} />
                  {court.name}
                </li>
              ))}
            </ul>
            <Button
              backgroundColor="mainBlue"
              color="white"
              mt={{ base: "10px", lg: "15px" }}
              px={{ base: "6px", lg: "15px" }}
              onClick={() => redirect("/highCourts")}
            >
              View more
            </Button>
          </Box>

          {/* Card 2 */}
          <Box
            width={{ base: "80%", lg: "45%" }}
            p={4}
            boxShadow="md"
            borderColor="mainBlue"
            borderWidth="1px"
            bg="white"
            m={2}
          >
            <Text
              fontWeight="normal"
              mb={2}
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
            >
              High Courts
            </Text>
            <ul>
              {curr_country.courts.slice(5, 10).map((court, index) => (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() =>
                    navigate(
                      "courts-by-country/info/" + currCountry + "/" + court.url
                    )
                  }
                >
                  <GiCheckMark style={{ marginRight: "8px" }} />
                  {court.name}
                </li>
              ))}
            </ul>
            <Button
              backgroundColor="mainBlue"
              color="white"
              mt={{ base: "10px", lg: "15px" }}
              px={{ base: "6px", lg: "15px" }}
              onClick={() => redirect("/highCourts")}
            >
              View more
            </Button>
          </Box>

          {/* Card 3 */}
          <Box
            width={{ base: "80%", lg: "45%" }}
            p={4}
            boxShadow="md"
            borderColor="mainBlue"
            borderWidth="1px"
            bg="white"
            m={2}
          >
            <Text
              fontWeight="normal"
              mb={2}
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
            >
              Linkinlegal Premium
            </Text>
            <p>Card 1 Paragraph</p>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
            </ul>
            <Button
              backgroundColor="mainBlue"
              color="white"
              mt={{ base: "10px", lg: "15px" }}
              px={{ base: "6px", lg: "15px" }}
            >
              View more
            </Button>
          </Box>

          {/* Card 4 */}
          <Box
            width={{ base: "80%", lg: "45%" }}
            p={4}
            boxShadow="md"
            borderColor="mainBlue"
            borderWidth="1px"
            bg="white"
            m={2}
          >
            <Text
              fontWeight="medium"
              mb={2}
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
            >
              Basic Access
            </Text>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat,
              voluptatem!
            </p>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
            </ul>
            <Button
              backgroundColor="mainBlue"
              color="white"
              mt={{ base: "10px", lg: "15px" }}
              px={{ base: "6px", lg: "15px" }}
            >
              View more
            </Button>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default NewsandLegalUpdates;
