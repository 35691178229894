import Layout from "../../commonmodule/layouts/home";
import { Box, Button, Container, Text, useToast } from "@chakra-ui/react";
import useFetch from "../../../hooks/useFetch";
import { config } from "../../../utils/config";
import { useEffect } from "react";

const SingleLawandRegPage = () => {
  const id = window.location.pathname.split("/")[3];
  const { data, loading } = useFetch("/lawsandreg/getbyID/" + id);

  const toast = useToast();

  const convertHtmltoText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.body;
    const text = body.textContent || "";
    return text;
  };

  useEffect(() => {
    const copyToClipboard = (e) => {
      if (e.ctrlKey && e.key === "c") {
        const selection = window.getSelection();
        const text = convertHtmltoText(selection);
        let newText = "";
        // remove all the extra new lines
        text.split("\n").forEach((line) => {
          if (line.trim() !== "") {
            newText += line.trim() + "\n";
          }
        });

        navigator.clipboard.writeText(convertHtmltoText(newText));
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    };
    document.addEventListener("keydown", copyToClipboard);
    return () => {
      document.removeEventListener("keydown", copyToClipboard);
    };
  }, []);

  return (
    <Layout>
      <Container maxW={"70%"} centerContent>
        {!loading && data.data && data.data ? (
          <Box
            display={"flex"}
            flexDir={"column"}
            mt={8}
            gap={4}
            borderRadius={"lg"}
            padding={"4%"}
            textAlign={"justify"}
          >
            <Box as={"span"} display={"flex"} alignItems={"center"} gap={4}>
              <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
                {data.data.name}
              </Text>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              width={"70%"}
            >
              <Text>{data.data.description}</Text>
            </Box>
            <Box color={"black.500"} bg={"gray.200"} padding={"8"}>
              <span
                className="doc-content"
                dangerouslySetInnerHTML={{ __html: data.data.docContent }}
              />
            </Box>{" "}
            <Box display={"flex"} gap={4}>
              <Button
                onClick={() => {
                  window.open(
                    config.baseURL + `/CaC/download/${data.data.document_name}`
                  );
                }}
                color={"white"}
                bgColor={"mainBlue"}
                boxShadow={"dark-sm"}
                rounded={"lg"}
                _hover={{
                  bg: "lightBlue",
                  color: "mainBlue",
                  boxShadow: "dark-md",
                  borderColor: "mainBlue",
                  borderWidth: "2px",
                }}
              >
                Download
              </Button>

              <Button
                onClick={() => window.history.back()}
                colorScheme={"red"}
                color={"white"}
                boxShadow={"dark-sm"}
                rounded={"lg"}
                _hover={{
                  bg: "lightBlue",
                  color: "mainBlue",
                  boxShadow: "dark-md",
                  borderColor: "mainBlue",
                  borderWidth: "2px",
                }}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        ) : (
          <Text>No data</Text>
        )}
      </Container>
    </Layout>
  );
};

export default SingleLawandRegPage;
