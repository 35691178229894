import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
  FormLabel,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";

import { axiosInstance as axios } from "../../../lib/axios";
import { AiOutlineKey } from "react-icons/ai";

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const toast = useToast();

  const checkEmail = (email) => {
    return new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    ).test(email);
  };

  return (
    <Flex
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      mt={16}
      gap={6}
    >
      <Stack
        spacing={8}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        p={6}
        my={12}
      >
        <Flex justifyContent={"center"} width={"100%"}>
          <Icon as={AiOutlineKey} w={12} h={12} color={"purple.500"} />
        </Flex>

        <Heading
          lineHeight={1.1}
          fontFamily={"base"}
          fontSize={{ base: "2xl", md: "3xl" }}
        >
          Forgot your password?
        </Heading>
        <Text fontFamily={"inherit"} fontSize={"sm"} color={"gray.500"}>
          Dont worry, we got you. Enter your email address and we&apos;ll send
          you a link to reset your password.
        </Text>
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            placeholder="your-email@example.com"
            _placeholder={{ color: "gray.500" }}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            color={"white"}
            colorScheme={"purple"}
            _hover={{
              bg: "purple.500",
            }}
            onClick={async () => {
              if (email === "") {
                toast({
                  title: "Email is required",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
                return;
              } else if (!checkEmail(email)) {
                toast({
                  title: "Invalid Email",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
                return;
              } else {
                let res = await axios.post("/client/user/forgotpassword", {
                  email,
                });

                const { ok, message } = res.data;

                if (ok) {
                  toast({
                    title: "Email sent",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: message ? message : "Something went wrong",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }
            }}
          >
            Request Reset
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
