import Layout from "../../commonmodule/layouts/home";
import {
  Button,
  Image,
  SimpleGrid,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

import { motion } from "framer-motion";
import { Card, CardBody, CardFooter } from "@chakra-ui/card";
import { axiosInstance as axios } from "../../../lib/axios";
import { FiSearch } from "react-icons/fi";
import { config } from "../../../utils/config";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Paginate } from "react-paginate-chakra-ui";
import { MultiSelect } from "primereact/multiselect";
import useFetch from "../../../hooks/useFetch";

const fetchStates = async (id) => {
  const res = await axios.get(`/states/findbycountryid?country=${id}`);
  if (res.data.ok) {
    return res.data.data;
  } else {
    return [];
  }
};

const fetchDistricts = async (id) => {
  const res = await axios.get(`/district/findbystate?state=${id}`);
  if (res.data.ok) {
    return res.data.data;
  } else {
    return [];
  }
};

const MembersOnlinePage = () => {
  const [lawyers, setLawyers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  let { data: countries } = useFetch("/countries/", {});
  const [filter, setFilter] = useState({
    country: [],
    state: [],
    district: [],
  });
  // pagination states
  const pageSize = 5;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const availableParams = {
    lawyers: "lawyer",
    lawfirms: "lawfirm",
    auditors: "auditor",
    buisnessconsultants: "buisnessconsultant",
  };

  const navigate = useNavigate();
  const { member } = useParams();

  const fetchMembers = async (page) => {
    setLoading(true);
    const countryIds = filter.country.map((country) => country._id)?.join(",");
    const stateIds = filter.state.map((state) => state._id)?.join(",");
    const districtIds = filter.district
      .map((district) => district._id)
      ?.join(",");
    const res = await axios.get(
      `/memberlisting/paginate?type=${availableParams[member]}&country=${countryIds}&state=${stateIds}&district=${districtIds}&page=${page}&limit=${pageSize}`
    );
    setData(res.data);
    setLawyers(res.data.data);
    setTotal(res.data.total);
    setCurrentTotal(res.data.total);
    setLoading(false);
  };

  const handlePageChange = async (newPageSize, page) => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');
    const countryIds = filter.country.map((country) => country._id)?.join(",");
    const stateIds = filter.state.map((state) => state._id)?.join(",");
    const districtIds = filter.district
      .map((district) => district._id)
      ?.join(",");

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }

    if (searchActive) {
      setLoading(true);
      const res = await axios.get(
        `/memberlisting/search/${search}?type=${availableParams[member]}&country=${countryIds}&state=${stateIds}&district=${districtIds}&page=${page}&limit=${newPageSize}`
      );
      setLawyers(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/memberlisting/paginate?type=${availableParams[member]}&country=${countryIds}&state=${stateIds}&district=${districtIds}&page=${page}&limit=${newPageSize}`
      );
      setData(res.data);
      setLawyers(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (page === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
    } else if (page === Math.ceil(total / pageSize)) {
      nextButton.disabled = true;
      prevButton.disabled = false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
    if (total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    }
    fetchMembers(1);
  }, []);

  useEffect(() => {
    const countryIds = filter.country.map((country) => country._id);
    if (countryIds.length === 0) return;
    fetchStates(countryIds).then((states) => {
      setStates(states);
    });
  }, [filter.country]);

  useEffect(() => {
    const stateIds = filter.state.map((state) => state._id);
    if (stateIds.length === 0) return;
    fetchDistricts(stateIds).then((districts) => {
      setDistricts(districts);
    });
  }, [filter.state]);

  const handleSearch = async (e) => {
    if (e.target.value === "") {
      setSearchActive(false);
      setLawyers(data.data);
      setTotal(currentTotal);
      return;
    }
    console.log(e.target.value);

    setPage(1);
    setSearchActive(true);
    setSearch(e.target.value);
    setLoading(true);

    const countryIds = filter.country.map((country) => country._id).join(",");
    const stateIds = filter.state.map((state) => state._id).join(",");
    const districtIds = filter.district
      .map((district) => district._id)
      .join(",");

    const res = await axios.get(
      `/memberlisting/search/${e.target.value}?type=${availableParams[member]}&country=${countryIds}&state=${stateIds}&district=${districtIds}&page=${page}&limit=${pageSize}`
    );

    setLawyers(res.data.data);
    setTotal(res.data.total);
    setLoading(false);

    const prevButton = document.querySelector('[aria-label="previous"]');
    const nextButton = document.querySelector('[aria-label="next"]');

    if (res.data.total <= pageSize) {
      prevButton.disabled = true;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = true;
      nextButton.disabled = false;
    }
  };

  const handleClick = (url) => {
    navigate(url);
  };

  const handleFilterClick = async () => {
    const countryIds = filter.country.map((country) => country._id).join(",");
    const stateIds = filter.state.map((state) => state._id).join(",");
    const districtIds = filter.district
      .map((district) => district._id)
      .join(",");

    setLoading(true);
    const res = await axios.get(
      `/memberlisting/paginate?country=${countryIds}&state=${stateIds}&district=${districtIds}&type=${availableParams[member]}&page=${page}&limit=${pageSize}`
    );
    setLawyers(res.data.data);
    setTotal(res.data.total);
    setLoading(false);
  };

  return (
    <Layout>
      <Box display={"flex"} width={"100%"} justifyContent={"flex-end"} p={4}>
        <Button
          colorScheme="telegram"
          onClick={() => {
            window.location.href = "/members/register/new";
          }}
        >
          Become a Member!
        </Button>
      </Box>

      <Box width={"100%"} display={"flex"}>
        <Box
          width={{
            base: "100%",
            md: "100%",
            lg: "20%",
          }}
          paddingInline={"10px"}
        >
          <Box
            padding={"3"}
            bg={"gray.200"}
            margin={"auto"}
            display={"flex"}
            flexDir={"column"}
            gap={"4"}
          >
            <Text as={"h1"} fontFamily={"heading"} fontSize={"2xl"}>
              Filters
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch />} />
              <Input
                placeholder="Search"
                onChange={handleSearch}
                borderColor={"gray.500"}
              />
            </InputGroup>
            <MultiSelect
              value={filter.country}
              onChange={(e) => {
                setFilter({ ...filter, country: e.value });
              }}
              options={countries && countries.data}
              optionLabel="name"
              placeholder="Select Countries"
              maxSelectedLabels={3}
            />
            <MultiSelect
              value={filter.state}
              onChange={(e) => {
                setFilter({ ...filter, state: e.value });
              }}
              options={states && states}
              optionLabel="name"
              placeholder="Select States"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />
            <MultiSelect
              value={filter.district}
              onChange={(e) => {
                setFilter({ ...filter, district: e.value });
              }}
              options={districts && districts}
              optionLabel="name"
              placeholder="Select Districts"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />
            <Button colorScheme="blue" onClick={handleFilterClick}>
              Filter
            </Button>
          </Box>
        </Box>
        <Box
          width={{
            base: "100%",
            md: "100%",
            lg: "80%",
          }}
        >
          <div>
            <Table
              variant="striped"
              colorScheme="gray"
              width={"80%"}
              margin={"auto"}
            >
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Type</Th>
                  <Th>Short Description</Th>
                  <Th>Image</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!loading && lawyers && lawyers.length > 0 ? (
                  lawyers.map((item, index) => (
                    <Tr key={index}>
                      <Td fontWeight={"bold"}>{item.professionalname}</Td>
                      <Td>
                        {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                      </Td>
                      {/* color={"gray.500"} fontSize={"xs"} */}
                      <Td>{item.shortDescription}</Td>
                      <Td>
                        <img
                          src={config.baseURL + `/CaC/download/${item.image}`}
                          alt={item.professionalname}
                          style={{ maxHeight: "100px", maxWidth: "100px" }}
                        />
                      </Td>
                      <Td>
                        <Button
                          bgColor={"mainBlue"}
                          color={"white"}
                          _hover={{
                            bg: "lightBlue",
                            color: "mainBlue",
                            boxShadow: "dark-md",
                            border: "2px solid",
                            borderColor: "mainBlue",
                          }}
                          onClick={() => {
                            handleClick(`/member/${item._id}`);
                          }}
                        >
                          <Text>Visit</Text>
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Text>No drafting found</Text>
                )}
              </Tbody>
            </Table>
          </div>
          <Box
            width={"80%"}
            margin={"auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Paginate
              page={page - 1}
              count={total}
              pageSize={pageSize}
              colorScheme="blue"
              onPageChange={(_page) => {
                if (page === _page + 1) {
                  return;
                }
                setPage(_page + 1);
                handlePageChange(pageSize, _page + 1);
              }}
              shadow="lg"
              fontWeight="blue"
              variant="outline"
              border="2px solid"
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default MembersOnlinePage;
