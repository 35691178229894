import {
  Flex,
  Text,
  Button,
  Input,
  Box,
  Select,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Radio,
  RadioGroup,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { axiosInstance as axios } from "../../lib/axios";
import Layout from "../commonmodule/layouts/home";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useState } from "react";

const ContractSchema = Yup.object().shape({
  type: Yup.string().required("Type must Required"),
  title: Yup.string().required("Title must Required"),
  description: Yup.string().required("Description must Required"),
  requesterName: Yup.string(),
  requesterDepartment: Yup.string(),
  counterPartyName: Yup.string().required("Counter Party Name must Required"),
  counterPartyDepartment: Yup.string().required(
    "Counter Party Department must Required"
  ),
  contractTerm: Yup.string().required("Contract Term must Required"),
  contractEffectiveDate: Yup.string().required(
    "Contract Execution Date must Required"
  ),
  contractEndDate: Yup.string(),
  terminationNoticePeriod: Yup.string(),
  amounttoReceive: Yup.string().required("Amount to Receive must Required"),
  tax: Yup.string().required("Tax must Required"),
});

function NewContract() {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});
  const toast = useToast();

  function handleClick(url) {
    navigate(url);
  }

  function handleSubmit(formState) {
    console.log(formState);
  }

  return (
    <Layout>
      <Flex
        w="100%"
        bg={"mainBlue"}
        justifyContent={"space-around"}
        alignItems={"center"}
        height={"16"}
      >
        <Text fontSize={"xl"} color={"white"}>
          New Contract
        </Text>

        <Flex alignItems={"center"} gap={4}>
          <Button
            variant={"outline"}
            colorScheme={"red"}
            onClick={() => handleClick("/")}
          >
            Cancel
          </Button>
          <Button onClick={() => handleClick("/newcontract")}>
            Save & Continue
          </Button>
        </Flex>
      </Flex>
      <Box
        as={"section"}
        w="60%"
        mx="auto"
        padding={"14"}
        boxShadow={"2xl"}
        border={"2px solid"}
        borderColor={"gray.200"}
        my={4}
        rounded={"lg"}
      >
        <Flex flexDir={"column"}>
          <Text>Basic Details</Text>

          <Box mt={4} width={"100%"}>
            <Formik
              initialValues={{
                type: "",
                title: "",
                description: "",
                requesterName: "",
                requesterDepartment: "",
                counterPartyName: "",
                counterPartyDepartment: "",
                contractTerm: "",
                contractEffectiveDate: "",
                contractEndDate: "",
                terminationNoticePeriod: "",
                amounttoReceive: "",
                tax: "",
              }}
              onSubmit={async (values) => {
                try {
                  const res = await axios.post("/userContract/create", {
                    ...values,
                  });

                  const { ok } = res.data;

                  if (ok) {
                    toast({
                      description: "New Contract Added",
                      status: "success",
                      position: "top-right",
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
              validationSchema={ContractSchema}
            >
              {({ errors, touched, setValues, values }) => (
                <Form>
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                  <Stack spacing={4}>
                    <FormControl
                      isInvalid={errors.type && touched.type}
                      id="type"
                      w={"40%"}
                    >
                      <FormLabel>Contract Type</FormLabel>
                      <Field
                        as={Select}
                        placeholder={"Select Type"}
                        name="type"
                      >
                        <option value="e-commerce-vendor-aggrement">
                          E-commerce Vendor Aggrement
                        </option>
                        <option value="non-disclosure-aggrement">
                          Non Disclosure Aggrement
                        </option>
                      </Field>
                    </FormControl>

                    <FormControl
                      id="title"
                      isInvalid={touched.title && errors.title}
                      w={"40%"}
                    >
                      <FormLabel>Title</FormLabel>
                      <Field as={Input} type="text" name="title" />
                      {errors.title && (
                        <FormErrorMessage>{errors.title}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="description"
                      name="description"
                      isInvalid={touched.description && errors.description}
                      w={"40%"}
                    >
                      <FormLabel>
                        Description{" "}
                        <Box as="span" color={"gray.400"} fontSize={"md"}>
                          (optional)
                        </Box>
                      </FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="description"
                        value={values.description}
                      />
                      {errors.description && (
                        <FormErrorMessage>
                          {errors.description}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <Stack direction={"row"} spacing={4}>
                      <FormControl
                        id="requesterName"
                        isInvalid={
                          touched.requesterName && errors.requesterName
                        }
                        w={"40%"}
                      >
                        <FormLabel>
                          Requester Name{" "}
                          <Box as="span" color={"gray.400"} fontSize={"md"}>
                            (optional)
                          </Box>
                        </FormLabel>
                        <Field as={Input} type="text" name="requesterName" />
                        {errors.requesterName && (
                          <FormErrorMessage>
                            {errors.requesterName}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        id="requesterDepartment"
                        isInvalid={
                          touched.requesterDepartment &&
                          errors.requesterDepartment
                        }
                        w={"40%"}
                      >
                        <FormLabel>
                          Requester Department{" "}
                          <Box as="span" color={"gray.400"} fontSize={"md"}>
                            (optional)
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          type="text"
                          name="requesterDepartment"
                        />
                        {errors.requesterDepartment && (
                          <FormErrorMessage>
                            {errors.requesterDepartment}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Stack>

                    <Stack direction={"row"} spacing={4}>
                      <FormControl
                        id="counterPartyName"
                        isInvalid={
                          touched.counterPartyName && errors.counterPartyName
                        }
                        w={"40%"}
                      >
                        <FormLabel>Counterparty Name </FormLabel>
                        <Field as={Input} type="text" name="counterPartyName" />
                        {errors.counterPartyName && (
                          <FormErrorMessage>
                            {errors.counterPartyName}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        id="counterPartyDepartment"
                        isInvalid={
                          touched.counterPartyDepartment &&
                          errors.counterPartyDepartment
                        }
                        w={"40%"}
                      >
                        <FormLabel>
                          Counterparty Department{" "}
                          <Box as="span" color={"gray.400"} fontSize={"md"}>
                            (optional)
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          type="text"
                          name="counterPartyDepartment"
                        />
                        {errors.counterPartyDepartment && (
                          <FormErrorMessage>
                            {errors.counterPartyDepartment}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Stack>

                    <FormControl
                      id="contractTerm"
                      isInvalid={touched.contractTerm && errors.contractTerm}
                    >
                      <FormLabel>Contract Term</FormLabel>
                      <RadioGroup
                        name="contractTerm"
                        onChange={(v) => {
                          setValues({
                            ...values,
                            contractTerm: v,
                          });
                        }}
                        value={values.contractTerm}
                      >
                        <Stack direction={"row"} spacing={4}>
                          <Radio value="definite">Definite</Radio>
                          <Radio value="indefinite">Indefinite</Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    <Stack direction={"row"} spacing={4}>
                      <FormControl
                        id="contractEffectiveDate"
                        isInvalid={
                          touched.contractEffectiveDate &&
                          errors.contractEffectiveDate
                        }
                        w={"40%"}
                      >
                        <FormLabel>Contract Effective Date </FormLabel>
                        <Field
                          as={Input}
                          type="text"
                          name="contractEffectiveDate"
                        />
                        {errors.contractEffectiveDate && (
                          <FormErrorMessage>
                            {errors.contractEffectiveDate}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        id="contractEndDate"
                        isInvalid={
                          touched.contractEndDate && errors.contractEndDate
                        }
                        w={"40%"}
                      >
                        <FormLabel>
                          Counterparty Department{" "}
                          <Box as="span" color={"gray.400"} fontSize={"md"}>
                            (optional)
                          </Box>
                        </FormLabel>
                        <Field as={Input} type="text" name="contractEndDate" />
                        {errors.contractEndDate && (
                          <FormErrorMessage>
                            {errors.contractEndDate}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Stack>

                    <FormControl
                      id="terminationNoticePeriod"
                      isInvalid={
                        touched.terminationNoticePeriod &&
                        errors.terminationNoticePeriod
                      }
                      w={"40%"}
                    >
                      <FormLabel>
                        Termination Notice Period{" "}
                        <Box as="span" color={"gray.400"} fontSize={"md"}>
                          (optional)
                        </Box>
                      </FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          type="number"
                          name="terminationNoticePeriod"
                        />
                        <InputRightElement>
                          <Button color={"gray.400"} fontSize={"md"}>
                            days
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {errors.terminationNoticePeriod && (
                        <FormErrorMessage>
                          {errors.terminationNoticePeriod}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <Box
                      borderBottom={"2px solid"}
                      borderBottomColor={"gray.200"}
                      pb={3}
                    >
                      Financial Details
                      <Box color={"gray.400"} fontSize={"sm"}>
                        (optional)
                      </Box>
                    </Box>

                    <Stack direction={"row"} spacing={4}>
                      <FormControl
                        id="amounttoReceive"
                        isInvalid={
                          touched.amounttoReceive && errors.amounttoReceive
                        }
                        w={"40%"}
                      >
                        <FormLabel>Amount to Receive</FormLabel>

                        <InputGroup>
                          <InputLeftElement>
                            <Button color={"gray.400"} fontSize={"md"}>
                              USD
                            </Button>
                          </InputLeftElement>
                          <Field
                            as={Input}
                            type="number"
                            name="amounttoReceive"
                            ml={"8"}
                          />
                        </InputGroup>

                        {errors.amounttoReceive && (
                          <FormErrorMessage>
                            {errors.amounttoReceive}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        isInvalid={errors.tax && touched.tax}
                        id="tax"
                        w={"40%"}
                      >
                        <FormLabel>Tax</FormLabel>
                        <Field
                          as={Select}
                          placeholder={"Select tax"}
                          name="tax"
                        >
                          <option value="all-taxes-included">
                            All Taxes Included
                          </option>
                          <option value="included">Included</option>
                        </Field>
                      </FormControl>
                    </Stack>
                  </Stack>
                  <Flex
                    height={"100px"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Button
                      backgroundColor={"mainBlue"}
                      color={"white"}
                      type="submit"
                    >
                      Save and Continue
                    </Button>

                    <Button colorScheme="red">Cancel</Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
}

export default NewContract;
