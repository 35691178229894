import Layout from "../../../commonmodule/layouts/home";
import { useNavigate, useParams } from "react-router-dom";
import { countriesData } from "../../../../data/courts-by-country";

export default function CourtsbyCountry() {
  const navigate = useNavigate();
  const { country } = useParams();

  const _currCountry = countriesData.find(
    (c) => c.key.toLowerCase() === country.toLowerCase()
  );

  if (!_currCountry) {
    navigate("/");
    return null;
  }

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center w-[100%] p-[2%] px-[2%] mb-2">
        <div className="flex flex-col justify-center gap-6 w-[80%]">
          {/* Loop through countries */}
          {_currCountry && (
            <div key={_currCountry.name}>
              <h2 className="mb-2 font-bold text-red-600 text-3xl md:text-4xl m-4 p-2">
                {_currCountry.name} High Courts
              </h2>
              <section className="py-2">
                <div className="container">
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
                    {/* Loop through courts within the _currCountry */}
                    {_currCountry.courts.map((court) => (
                      <div
                        key={court._id}
                        style={{
                          transition: "all 0.3s ease-in-out",
                        }}
                        className="p-1 bg-white shadow-sm rounded-2xl border-l-2 border-b-2 text-center border-l-gray-300 border-b-gray-400 cursor-pointer text-mainBlue hover:transform:scale-105 hover:shadow-lg hover:rounded-md flex justify-center items-center"
                        onClick={() =>
                          navigate(
                            `/courts-by-country/info/${country}/${court.url}`
                          )
                        }
                      >
                        <h3 className="text-base px-2 font-semibold mb-2">
                          {court.name}
                        </h3>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
