import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { GoThumbsup } from "react-icons/go";
import { useParams } from "react-router";
import * as Yup from "yup";
import { axiosInstance as axios } from "../../../lib/axios";
export default function ResetPassword() {
  const toast = useToast();
  const [checkToken, setCheckToken] = useState(false);
  const [loading, setLoading] = useState(true);

  const { token } = useParams();

  const fetch = useCallback(async () => {
    setLoading(true);
    const res = await axios.post("/client/user/checkresetlink", { token });

    const { ok } = res.data;

    setCheckToken(ok);
    setLoading(false);
  }, [token]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return (
      <Flex width={"100vw"} height={"100vh"} flexDirection={"column"}>
        <Heading>Loading...</Heading>
      </Flex>
    );
  }

  if (!checkToken) {
    setTimeout(() => {
      window.location.href = "/auth/user/signin";
    }, 3000);
    return (
      <Flex
        width={"100vw"}
        height={"100vh"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Heading>Invalid Link...</Heading>
        <Text>Redirecting to Signin Page...</Text>
      </Flex>
    );
  }

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      flexDirection={"column"}
      justify={"center"}
      bg={"gray.50"}
    >
      <Heading
        lineHeight={1.1}
        fontFamily={"base"}
        fontSize={{ base: "2xl", md: "3xl" }}
      >
        Reset Password <Icon as={GoThumbsup} color="green.500" />
      </Heading>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={"white"}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values) => {
            try {
              const formData = new FormData();

              formData.append("password", values.password);
              formData.append("confirmPassword", values.confirmPassword);
              formData.append("token", token);

              const res = await axios.post(
                "/client/user/reset/resetforgetpassword/",
                formData
              );

              const { ok, message } = res.data;

              if (ok) {
                toast({
                  description: "Password reset successfully",
                  status: "success",
                  position: "bottom",
                });

                setTimeout(() => {
                  window.location.href = "/auth/user/signin";
                }, 3000);
              } else {
                console.log(message);
                toast({
                  description: message ? message : "Something went wrong",
                  status: "error",
                  position: "bottom",
                });
              }
            } catch (e) {
              console.log(e);
            }
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Password is required")
              .min(6)
              .max(20)
              .matches(/(?=.*[0-9])/, "Password must contain a number."),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "Passwords must match"
            ),
          })}
        >
          {({ errors, touched, setValues }) => (
            <Form>
              <Stack spacing={4}>
                <FormControl
                  id="password"
                  isInvalid={touched.password && errors.password}
                >
                  <FormLabel>New Password</FormLabel>
                  <Field as={Input} type="password" name="password" />
                  {errors.password && (
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  id="confirmPassword"
                  isInvalid={touched.confirmPassword && errors.confirmPassword}
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <Field as={Input} type="password" name="confirmPassword" />
                  {errors.confirmPassword && (
                    <FormErrorMessage>
                      {errors.confirmPassword}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Flex
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Button
                    width={"20%"}
                    color={"white"}
                    colorScheme={"linkedin"}
                    type={"submit"}
                    justifySelf={"center"}
                  >
                    Update
                  </Button>
                </Flex>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
}
