import { SimpleGrid, Text, Flex, Button } from "@chakra-ui/react";
import Layout from "../../commonmodule/layouts/home";
import { useNavigate } from "react-router-dom";

function ServicesPage() {
  const navigate = useNavigate();

  function handleClick(url) {
    navigate(url);
  }

  return (
    <Layout>
      <Text
        fontSize={{
          base: "4xl",
          md: "5xl",
        }}
        color={"mainBlue"}
        fontFamily={"heading"}
        textAlign={"center"}
        mt={"5%"}
      >
        Our Services
      </Text>
      <Flex px={{ base: 4, md: 8 }} justifyContent={"center"}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          width={"80%"}
          margin={"auto"}
          spacing={8}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}
            boxShadow={"md"}
            border={"2px solid"}
            borderColor={"gray.200"}
            borderRadius={"2xl"}
            padding={4}
            _hover={{
              boxShadow: "xl",
            }}
          >
            <Text
              fontSize={{
                base: "3xl",
                md: "4xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
              mb={2}
              textAlign={"center"}
            >
              Contract Management
            </Text>

            <Text
              fontSize={{
                base: "sm",
                md: "md",
              }}
              color={"gray.500"}
              width={{
                base: "100%",
                md: "60%",
              }}
              textAlign={"center"}
              margin={"auto"}
              padding={2}
              mb={2}
            >
              Streamline your contract management process with our user-friendly
              system. Create, store, and organize all your contracts in one
              place, with powerful search and reporting features. Try it now.
            </Text>

            <Button
              bg="mainBlue"
              color={"white"}
              _hover={{
                bg: "white",
                color: "mainBlue",
                borderColor: "mainBlue",
                border: "2px solid",
              }}
              onClick={() => handleClick("/contract-management")}
            >
              Get Started...
            </Button>
          </Flex>

          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}
            boxShadow={"md"}
            border={"2px solid"}
            borderColor={"gray.200"}
            borderRadius={"2xl"}
            padding={4}
            _hover={{
              boxShadow: "xl",
            }}
          >
            <Text
              fontSize={{
                base: "3xl",
                md: "4xl",
              }}
              color={"mainBlue"}
              fontFamily={"heading"}
              mb={2}
              textAlign={"center"}
            >
              The Other Service...
            </Text>

            <Text
              fontSize={{
                base: "sm",
                md: "md",
              }}
              color={"gray.500"}
              width={{
                base: "100%",
                md: "60%",
              }}
              textAlign={"center"}
              margin={"auto"}
              padding={2}
              mb={2}
            >
              Streamline your contract management process with our user-friendly
              system. Create, store, and organize all your contracts in one
              place, with powerful search and reporting features. Try it now.
            </Text>

            <Button
              bg="mainBlue"
              color={"white"}
              _hover={{
                bg: "white",
                color: "mainBlue",
                borderColor: "mainBlue",
                border: "2px solid",
              }}
            >
              Get Started...
            </Button>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Layout>
  );
}

export default ServicesPage;
