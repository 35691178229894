import { Box, Container, Stack, Text } from "@chakra-ui/react";
import GlobalSearch from "../../searchmodule/search/GlobalSearch";
import { useInView } from "framer-motion";
import { useState, useEffect } from "react";

import "./styles/common.scss";

export default function HeroSection() {
  // eslint-disable-next-line no-unused-vars
  const [_, setShowScrollDownicon] = useState(false);

  const { inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setShowScrollDownicon(true);
    }
  }, [inView]);

  return (
    <Container maxW={"100%"} px={0}>
      <Box
        style={{
          background: `linear-gradient(to top left, #4079C6, #002855  65%)`,
        }}
        p={2}
      >
        <Stack
          textAlign={"center"}
          position={"relative"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: "18" }}
          rounded={"md"}
        >
          <Text
            fontWeight={200}
            fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
            color={"white"}
            width={{
              base: "100%",
            }}
            minWidth={{
              base: "100%",
            }}
            fontFamily={"heading"}
          >
            Search millions of contracts with Linkinlegal
          </Text>

          <Box width={"80%"} mt={0}>
            <GlobalSearch />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}
